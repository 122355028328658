import {axios} from '@/utils/request'


export const getList = (msg, sucFn, errorFn) => {
    axios({
        method: 'get',
        url: '/api/servers/cidrs/',
        params: msg
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })

}
export const handle = (msg, sucFn, errorFn) => {
    axios({
        method: 'put',
        url: '/api/servers/cidrs/'+ msg.id +'/',
        data: {exclude_acl: msg.exclude_acl}
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })

}
// export const handle = (method, msg, sucFn, errorFn) => {
//     axios({
//         method,
//         url: `/api/servers/acl-groups/${msg.id}/`,
//         data: msg
//     })
//         .then(function (res) {
//             if (res.code === 2000) {
//                 typeof sucFn === 'function' && sucFn(res)
//             } else {
//                 typeof errorFn === 'function' && errorFn(res)
//             }
//         })
//         .catch(function (code, error) {
//             typeof errorFn === 'function' && errorFn(code, error)
//         })

// }
export const add = (msg, sucFn, errorFn) => {
    axios({
        method: "post",
        url: `/api/servers/acl-groups/`,
        data: msg
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })

}