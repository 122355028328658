import {axios} from '@/utils/request'

// 用户列表
export const getInvite = (sucFn, errorFn) => {
    axios({
        method: 'get',
        url: '/api/users/invite_code',
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })

}
export const getInviteData = (params, sucFn, errorFn) => {
    axios({
        method: 'get',
        url: '/api/users/invite_log',
        params,
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })

}
