<template>
    <div>
        <el-card class="box-card" shadow="never" v-loading="loading">
            <el-tabs v-model="activeName">
                <el-tab-pane label="Discord" name="first">
                    <el-form ref="form" :model="form" label-width="250px">
                        <el-form-item label="Discord Client Id">
                            <el-input v-model="form.discord_client_id"></el-input>
                        </el-form-item>
                        <el-form-item label="Discord Secret">
                            <el-input v-model="form.discord_client_secret"></el-input>
                        </el-form-item>
                        <el-form-item label="Discord Login callback address">
                            <el-input v-model="form.discord_redirect_uri"></el-input>
                        </el-form-item>
                        <el-form-item label="Discord Bind callback address">
                            <el-input v-model="form.discord_bind_redirect_uri"></el-input>
                        </el-form-item>
                        <el-form-item label="Discord Bot Token">
                            <el-input v-model="form.discord_bot_token"></el-input>
                        </el-form-item>
                        <el-form-item label="Discord Bot Channels">
                            <el-input v-model="form.discord_bot_channels"></el-input>
                        </el-form-item>
                        <el-form-item label="Discord Bot Per Message Point">
                            <el-input v-model="form.discord_bot_points_per_message"></el-input>
                            <el-tooltip content="每次发消息获得活跃积分" placement="right">
                                <i class="el-icon-info"></i>
                            </el-tooltip>
                        </el-form-item>
                        <el-form-item label="Discord Bot Point Limit">
                            <el-input v-model="form.discord_bot_max_points_per_day"></el-input>
                            <el-tooltip content="每日discord活跃积分上限">
                                <i class="el-icon-info"></i>
                            </el-tooltip>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
                <el-tab-pane label="Shopify" name="second">
                    <el-form ref="form" :model="form" label-width="150px">
                        <el-form-item label="Api Key">
                            <el-input v-model="form.shopify_api_key"></el-input>
                        </el-form-item>
                        <el-form-item label="Api Scert">
                            <el-input v-model="form.shopify_api_secret"></el-input>
                        </el-form-item>
                        <el-form-item label="App Password">
                            <el-input v-model="form.shopify_app_key"></el-input>
                        </el-form-item>
                        <el-form-item label="Shop Url">
                            <el-input v-model="form.shopify_shop_url"></el-input>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
                <el-tab-pane label="Email" name="third">
                    <el-form ref="form" :model="form" label-width="250px">
                        <el-form-item label="Email Method">
                            <el-input v-model="form.email_method"></el-input>
                        </el-form-item>
                        <el-form-item label="Email Code Expire">
                            <el-input v-model="form.email_code_expire"></el-input>
                        </el-form-item>
                        <el-form-item label="Sendgrid Api Key">
                            <el-input v-model="form.sendgrid_api_key"></el-input>
                        </el-form-item>
                        <el-form-item label="Mailgun Api Key">
                            <el-input v-model="form.mailgun_api_key"></el-input>
                        </el-form-item>
                        <el-form-item label="Mailgun Sender Domain">
                            <el-input v-model="form.mailgun_sender_domain"></el-input>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
                <el-tab-pane label="System" name="fourth">
                    <el-form ref="form" :model="form" label-width="250px">
                        <el-form-item label="Twitter">
                            <el-input v-model="form.support_twitter"></el-input>
                        </el-form-item>
                        <el-form-item label="Discord">
                            <el-input v-model="form.support_discord"></el-input>
                        </el-form-item>
                        <el-form-item label="Invite Level Points Per User">
                            <el-input v-model="form.invite_level_points_per_user"></el-input>
                            <el-tooltip content="邀请一个用户获得积分数" placement="right">
                                <i class="el-icon-info"></i>
                            </el-tooltip>
                        </el-form-item>
                        <el-form-item label="Level Points Decay Rate">
                            <el-input v-model="form.level_points_decay_rate"></el-input>
                            <el-tooltip content="用户积分衰减比例(a=a(1-x))" placement="right">
                                <i class="el-icon-info"></i>
                            </el-tooltip>
                        </el-form-item>
                        <el-form-item label="Level Points Decay Day">
                            <el-input v-model="form.level_points_decay_day"></el-input>
                            <el-tooltip content="用户积分衰减日期" placement="right">
                                <i class="el-icon-info"></i>
                            </el-tooltip>
                        </el-form-item>
                        <el-form-item label="Billing Point Rate">
                            <el-input v-model="form.billing_rate"></el-input>
                            <el-tooltip content="用户下单获得积分比例(a=a*x)" placement="right">
                                <i class="el-icon-info"></i>
                            </el-tooltip>
                        </el-form-item>
                        <el-form-item label="Invite Coin Rate">
                            <el-input v-model="form.invite_rebate_rate"></el-input>
                            <el-tooltip content="被邀请人消费，邀请人获得金币比例(a=a*x)" placement="right">
                                <i class="el-icon-info"></i>
                            </el-tooltip>
                        </el-form-item>

                    </el-form>
                </el-tab-pane>
                <el-tab-pane label="geofeed" name="fifth">
                    <el-form ref="form" :model="form">
                        <el-form-item>
                            <el-input v-model="form.geofeed" type="textarea"
                                      :autosize="{ minRows: 5, maxRows: 15 }"></el-input>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
                <el-tab-pane label="running logs" name="logs">
                    <h1 class="center" style="font-size: 20px; align-content: center">server log</h1>
                    <el-input v-model="server_logs" type="textarea" :autosize="{ minRows: 5, maxRows: 15 }"></el-input>

                    <el-divider></el-divider>
                    <h1 class="center" style="font-size: 20px;">task log</h1>

                    <el-input v-model="task_logs" type="textarea" :autosize="{ minRows: 5, maxRows: 15 }"></el-input>
                </el-tab-pane>
            </el-tabs>
            <div style="display: flex; justify-content: center;">
                <el-button type="primary" @click="saveSettings" :loading="submitLoading">Save</el-button>
            </div>
        </el-card>
    </div>
</template>

<script>
import {getLogsMethod, getSettingMethod, setSettings} from "@/api/dashboard/websiteSettings"

export default {
    data() {
        return {
            activeName: 'first',
            form: {
                name: '',
                discord_client_id: "",
                discord_client_secret: "",
                discord_redirect_uri: "",
                discord_bind_redirect_uri: "",
                discord_bot_token: "",
                discord_bot_channels: "",
                discord_bot_points_per_message: "",
                discord_bot_max_points_per_day: "",
                shopify_api_key: "",
                shopify_api_secret: "",
                shopify_app_key: "",
                shopify_shop_url: "",
                email_method: "",
                email_code_expire: "",
                sendgrid_api_key: "",
                mailgun_api_key: "",
                mailgun_sender_domain: "",
                support_twitter: "",
                support_discord: "",
                invite_level_points_per_user: "",
                billing_rate: "",
                level_points_decay_rate: "",
                level_points_decay_day: "",
                invite_rebate_rate: "",
                geofeed: ''
            },
            server_logs: '',
            task_logs: '',
            loading: false,
            submitLoading: false,
        }
    },
    mounted() {
        new this.$wow.WOW().init()
        this.getSettings()
        this.getLogs()
    },
    methods: {
        getSettings() {
            this.loading = true
            getSettingMethod((res) => {
                this.loading = false
                this.$set(this, "form", res.data)
            }, (res) => {
                this.$message.error(res.msg)
                this.loading = false
            })
        },
        saveSettings() {
            this.submitLoading = true
            setSettings(this.form, (res) => {
                this.$message.success("Successful operation")

                this.submitLoading = false

            }, (res) => {
                this.submitLoading = false
                this.$message.error(res.msg)
            })
        },
        getLogs() {
            this.loading = true
            getLogsMethod((res) => {
                this.loading = false
                this.$set(this, "server_logs", res.data.server_logs)
                this.$set(this, "task_logs", res.data.task_logs)
            }, (res) => {
                this.$message.error(res.msg)
                this.loading = false
            })
        },

    }
}
</script>

<style scoped lang="scss"></style>