<template>
    <div>
        <div class="fmentos ftobor wow fadeInUp Couple_bg" data-wow-delay="0.3s" data-wow-duration="1s">
            <h2>Mentos Couple</h2>
        </div>
        <div style="position: relative;">
            <div v-show="userInfo.level_points"
                 style="width: 250px;position: absolute;left: 70px;top: 215px;font-family: 'pang';font-size: 16px;">You
                can earn
                level points by spending money and inviting new users, the higher the points the more discounts
                you get.
            </div>
            <div v-if="currentLevel === 0" class="fyouruo ftobor wow fadeInUp points points-1" data-wow-delay="0.3s"
                 data-wow-duration="1s">
                <!-- <img src="@/assets/images/fimg6.jpg" alt=""> -->

                <div class="num">
                    {{ userInfo.level_points }}
                </div>
            </div>
            <div v-else-if="currentLevel === 1" class="fyouruo ftobor wow fadeInUp points points-1"
                 data-wow-delay="0.3s"
                 data-wow-duration="1s">
                <!-- <img src="@/assets/images/fimg6.jpg" alt=""> -->
                <div class="num">
                    {{ userInfo.level_points }}
                </div>
            </div>
            <div v-else-if="currentLevel === 2" class="fyouruo ftobor wow fadeInUp points points-2"
                 data-wow-delay="0.3s"
                 data-wow-duration="1s">
                <!-- <img src="@/assets/images/fimg6.jpg" alt=""> -->
                <div class="num">
                    {{ userInfo.level_points }}
                </div>
            </div>

            <div v-else-if="currentLevel === 3" class="fyouruo ftobor wow fadeInUp points points-3"
                 data-wow-delay="0.3s"
                 data-wow-duration="1s">
                <!-- <img src="@/assets/images/fimg6.jpg" alt=""> -->
                <div class="num">
                    {{ userInfo.level_points }}
                </div>
            </div>

            <div v-else-if="currentLevel === 4" class="fyouruo ftobor wow fadeInUp points points-4"
                 data-wow-delay="0.3s"
                 data-wow-duration="1s">
                <!-- <img src="@/assets/images/fimg6.jpg" alt=""> -->
                <div class="num">
                    {{ userInfo.level_points }}
                </div>
            </div>

            <div v-else-if="currentLevel === 5" class="fyouruo ftobor wow fadeInUp points points-5"
                 data-wow-delay="0.3s"
                 data-wow-duration="1s">
                <!-- <img src="@/assets/images/fimg6.jpg" alt=""> -->
                <div class="num">
                    {{ userInfo.level_points }}
                </div>
            </div>
        </div>

        <div class="fkeepitup ftobor wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1s">
            <div class="tl" v-if="levelNum != 100">
                Keep it up! Earn <span>{{ levelNum }}</span> more points earn
                <span v-if="currentLevel == 1">Silver</span>
                <span v-else-if="currentLevel == 2">Gold</span>
                <span v-else-if="currentLevel == 3">Platinum</span>
                <span v-else-if="currentLevel == 4">Diamond</span>
                <span v-else-if="currentLevel == 5">SSSSSVIP</span>
            </div>
            <div class="tl" v-else>
                You are already at the highest level!
            </div>
            <div class="list">
                <ul>
                    <div class="jdtiao">
                        <div class="jd on"></div>
                        <div :class="currentLevel > 1 ? 'jd on' : 'jd'"></div>
                        <div :class="currentLevel > 2 ? 'jd on' : 'jd'"></div>
                        <div :class="currentLevel > 3 ? 'jd on' : 'jd'"></div>
                        <div :class="currentLevel > 4 ? 'jd on' : 'jd'"></div>
                    </div>
                    <li class="on">
                        <div class="icon"><img src="@/assets/images/fp1.png" alt=""/></div>
                        <div class="text">
                            <div class="ic">
                                <img src="@/assets/images/fn15.png" alt=""/>
                                <img src="@/assets/images/fn15-1.png" alt=""/>
                            </div>
                            <h4>Menber:0</h4>
                        </div>
                    </li>
                    <li class="on">
                        <div class="icon"><img src="@/assets/images/fp2.png" alt=""/></div>
                        <div class="text">
                            <div class="ic">
                                <img src="@/assets/images/fn15.png" alt=""/>
                                <img src="@/assets/images/fn15-1.png" alt=""/>
                            </div>
                            <h4 v-if="pointersList.length > 0">
                                Silver:{{ pointersList[1].point }}
                            </h4>
                        </div>
                    </li>
                    <li>
                        <div class="icon"><img src="@/assets/images/fp3.png" alt=""/></div>
                        <div class="text">
                            <div class="ic">
                                <img src="@/assets/images/fn15.png" alt=""/>
                                <img src="@/assets/images/fn15-1.png" alt=""/>
                            </div>
                            <h4 v-if="pointersList.length > 1">
                                Gold:{{ pointersList[2].point }}
                            </h4>
                        </div>
                    </li>
                    <li>
                        <div class="icon"><img src="@/assets/images/fp4.png" alt=""/></div>
                        <div class="text">
                            <div class="ic">
                                <img src="@/assets/images/fn15.png" alt=""/>
                                <img src="@/assets/images/fn15-1.png" alt=""/>
                            </div>
                            <h4 v-if="pointersList.length > 2">
                                Platinum:{{ pointersList[3].point }}
                            </h4>
                        </div>
                    </li>
                    <li>
                        <div class="icon"><img src="@/assets/images/fp5.png" alt=""/></div>
                        <div class="text">
                            <div class="ic">
                                <img src="@/assets/images/fn15.png" alt=""/>
                                <img src="@/assets/images/fn15-1.png" alt=""/>
                            </div>
                            <h4 v-if="pointersList.length > 3">
                                Diamond:{{ pointersList[4].point }}
                            </h4>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="frecent ftobor wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1s">
            <div class="ftite">
                <h2>Recent Point Activity:</h2>
            </div>
            <el-table :data="pointsChangeList" style="width: 100%" header-cell-class-name="table_header"
                      v-loading="tableLoading">
                <el-table-column prop="created_at" label="Date" align="center">
                </el-table-column>
                <el-table-column prop="reason" label="Points Earned" align="center">
                </el-table-column>
                <el-table-column prop="point" label="Points Earned" align="center">
                </el-table-column>
            </el-table>
            <el-pagination background layout="->,prev, pager, next" :total="total" :page-size="4"
                           style="margin-top: 20px;"
                           :current-page.sync="currentPage" @current-change="handleCurrentChange">
            </el-pagination>
        </div>
        <div class="ftobor fexchange wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1s">
            <div class="ftite">
                <h2>Exchange List：</h2>
                <h3>Your Coins: <i>{{ userInfo.reward_points }}</i></h3>
                <!-- <h3>Your Points: <i v-if="userInfo.reward_points">{{ userInfo.reward_points }}</i></h3> -->
            </div>
            <div class="list">
                <ul>
                    <li v-for="(item, index) in rewardsList" :key="index" @click="exchange(item)">
                        <h4>${{ item.mount }}</h4>
                        <!-- <h4>Deduction amount of ${{ item.mount }}</h4> -->
                        <div class="img">
                            <img src="@/assets/images/fimg1.jpg" alt=""/>
                        </div>
                        <h4>Consume {{ item.point }} Coins</h4>
                    </li>
                </ul>
            </div>
        </div>
        <!-- <div
          class="fpagels clearfix wow fadeInUp"
          data-wow-delay="0.3s"
          data-wow-duration="1s"
        >
          <ul>
            <li><div class="prod"></div></li>
            <li class="on"><a href="#">1</a></li>
            <li><a href="#">2</a></li>
            <li><div class="next"></div></li>
          </ul>
        </div> -->
    </div>
</template>

<script>
import {userPointers, pointersLevel, pointsChangeDatas, rewardData, exchangeGift} from "@/api/dashboard/giftUser"

export default {
    name: "proxies",
    components: {},
    data() {
        return {
            loading: true,
            userInfo: {},
            tableLoading: false,
            levelNum: 0, // 当前积分在第几等级  还差多少到下一等级
            pointersList: [],
            currentPage: 0,
            total: 0,
            currentLevel: 0,
            pointsChangeList: [],
            rewardsList: []
        }
    },
    created() {
        this.getPointersLevel()
        this.getPointsChangeDatas()
        this.getRewardData()
    },
    updated() {
    },
    mounted() {
        new this.$wow.WOW().init()
    },
    methods: {
        getRewardData() {
            rewardData(
                (res) => {
                    this.rewardsList = res.data
                },
                (err) => {
                    // this.$message.warning(err.msg);
                }
            )
        },
        handleCurrentChange(val) {
            this.getPointsChangeDatas()
        },
        getPointsChangeDatas() {
            this.tableLoading = true
            let param = {limit: 4, offset: this.currentPage}
            pointsChangeDatas(param,
                (res) => {
                    this.pointsChangeList = res.data.data
                    this.total = res.data.total
                    this.tableLoading = false
                },
                (err) => {
                    // this.$message.warning(err.msg);
                }
            )
        },
        sortArr(attr) {
            return function (a, b) {
                return a[attr] - b[attr]
            }
        },
        getPointers() {
            userPointers(
                (res) => {
                    this.userInfo = res.data
                    if (this.userInfo.level_points >= this.pointersList[4].point) {
                        this.currentLevel = 5
                        this.levelNum = 99999
                    } else if (this.userInfo.level_points >= this.pointersList[3].point) {
                        this.currentLevel = 4
                        this.levelNum = this.pointersList[4].point - this.userInfo.level_points
                    } else if (this.userInfo.level_points >= this.pointersList[2].point) {
                        this.currentLevel = 3
                        this.levelNum = this.pointersList[3].point - this.userInfo.level_points
                    } else if (this.userInfo.level_points >= this.pointersList[1].point) {
                        this.currentLevel = 2
                        this.levelNum = this.pointersList[2].point - this.userInfo.level_points
                    } else if (this.userInfo.level_points >= this.pointersList[0].point) {
                        this.currentLevel = 1
                        this.levelNum = this.pointersList[1].point - this.userInfo.level_points
                    }
                    this.loading = false
                },
                (err) => {
                    // this.$message.warning(err.msg);
                }
            )
        },
        getPointersLevel() {
            pointersLevel(
                (res) => {
                    this.pointersList = res.data.data.sort(this.sortArr("point"))
                    this.getPointers()
                },
                (err) => {
                    // this.$message.warning(err.msg);
                }
            )
        },
        exchange(item) {
            if (this.userInfo.reward_points < item.point) {
                this.$message.warning("Insufficient Coins")
                return
            } else {
                this.$confirm("Are you sure to exchange?", "Tips", {
                    confirmButtonText: "Confirm",
                    cancelButtonText: "Cancel",
                    type: "warning",
                })
                    .then(() => {
                        let data = {mount: item.mount}
                        exchangeGift(data,
                            (res) => {
                                this.$message.success("Exchange Success")
                                this.getRewardData()
                                this.getPointers()
                                this.getPointsChangeDatas()
                            },
                            (err) => {
                                this.$message.warning(err.msg);
                            }
                        )
                    })
                    .catch(() => {
                    })
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.points {
  height: 400px;
  background-image: url("../../assets/images/fimg6.jpg");
  background-size: cover;

  .num {
    margin-top: 156px;
    margin-left: 582px;
    width: 268px;
    text-align: center;
    font-weight: 600;
    font-size: 60px;
    color: rgb(242, 121, 50);
    text-shadow: -4px -4px 0 #fff, 4px -4px 0 #fff, -4px 4px 0 #fff, 4px 4px 0 #fff;

  }
}

.points-1 {
  background-image: url("../../assets/images/Member.png");
}

.points-2 {
  background-image: url("../../assets/images/Silverz.png");
}

.points-3 {
  background-image: url("../../assets/images/Gold.png");
}

.points-4 {
  background-image: url("../../assets/images/platinum.png");
}

.points-5 {
  background-image: url("../../assets/images/Diamond.png");
}

.fexchange .list ul {
  width: 100%;
  overflow: hidden !important;
  overflow-x: auto !important;
  white-space: nowrap !important;
}
</style>