<template>
    <div>

        <div class="ftoken ftobor wow fadeInUp code_title_container" data-wow-delay="0.3s" data-wow-duration="1s">
            <div class="code_title">
                <span>List of promo codes:</span>
                <span>I'm guessing you're looking for a discount code?</span>
            </div>
            <div class="code_box" v-loading="loading">
                <div v-if="rewardsList.length">
                    <div class="code_item" v-for="(item, index) in rewardsList" :key="index">
                        <img v-if="!item.is_used" src="@/assets/images/code_1.png" alt="" class="bg">
                        <img v-else src="@/assets/images/code_2.png" alt="" class="bg">
                        <div class="code_text">
                            <span>{{ item.discount }}</span>
                            <span>Type:{{ codeTypeMap[item.code_type] }}</span>
                            <span>State:{{ item.is_used ? 'Used' : 'Unused' }}</span>
                        </div>
                        <div class="ticket_box">
                            <div class="ticket_box_btn_1">
                                <span>{{ item.code }}</span>
                                <img src="@/assets/images/copy.png" alt="" class="copy_button"
                                     @click="copyCode(item.code)"/>
                            </div>
                            <div class="ticket_box_btn_2">
                                <span>Use Now</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="no-deta" v-else>
                    No data
                </div>
            </div>
        </div>

        <!-- Copy confirmation tip -->
        <div class="copy-tip" v-if="showCopyConfirmationTip">
            <div class="copy-tip-content">
                <p>Code copied to clipboard!</p>
            </div>
        </div>
    </div>
</template>


<script>
import {rewardPromo} from "@/api/dashboard/giftUser"

export default {
    name: "proxies",
    components: {},
    data() {
        return {
            rewardsList: [],
            loading: false,
            showCopyConfirmationTip: false
        }
    },
    created() {
    },
    mounted() {
        this.getRewardPromo()
        new this.$wow.WOW().init()
    }, computed: {
        codeTypeMap() {
            return {
                1: 'Discount',
                2: 'Gift'
            }
        }
    },
    methods: {
        getRewardPromo() {
            this.loading = true
            rewardPromo(
                (res) => {
                    this.rewardsList = res.data.data
                    this.loading = false
                },
                (err) => {
                    this.loading = false

                    // this.$message.warning(err.msg);
                }
            )
        },
        copyCode(code) {
            const el = document.createElement('textarea')
            el.value = code
            document.body.appendChild(el)
            el.select()
            document.execCommand('copy')
            document.body.removeChild(el)
            this.showCopyConfirmationTip = true
            setTimeout(() => {
                this.showCopyConfirmationTip = false
            }, 2000)
        }
    },
}
</script>
<style lang="scss" scoped>
.code_title {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid rgba(233, 236, 240, 1);
  padding-top: 24px;
  padding-bottom: 14px;
  align-items: center;
}

.code_title_container {
  padding: 0 24px;
}

.code_title span:nth-child(1) {
  font-size: 24px;
  font-family: "pang";
  font-weight: 400;
  color: #404040;
}

.code_title span:nth-child(2) {
  font-size: 12px;
  font-family: "pang";
  font-weight: 400;
  color: #e15186;
  cursor: pointer;
  text-decoration: underline;
}

.code_item {
  position: relative;
  display: flex;
  width: 285px;
  margin-bottom: 20px;
}

.code_item .bg {
  width: 100%;
}

.code_box {
  padding: 24px 0;
  width: 100%;
  overflow: hidden;
  overflow-x: auto;
  white-space: nowrap;

  .code_item {
    display: inline-block;
    margin: 0 10px;
  }
}

.code_text {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  right: 16px;
  top: 0;
}

.no-deta {
  text-align: center;
  font-size: 24px;
  line-height: 200px;
  font-weight: 600;
  height: 200px;
}

.code_text_disable span {
  color: rgba(112, 112, 112, 1) !important;
}

.code_text span:nth-child(1) {
  font-size: 48px;
  font-family: "pang";
  font-weight: 400;
  color: #ffffff;
  padding-top: 16px;
}

.code_text span:nth-child(2) {
  font-size: 14px;
  font-family: "pang";
  font-weight: 400;
  color: #ffffff;
}

.code_text span:nth-child(3) {
  font-size: 14px;
  font-family: "pang";
  font-weight: 400;
  color: #ffffff;
  padding-top: 10px;
}

.ticket_box {
  display: flex;
  width: 285px;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 50px;
  align-items: center;
  justify-content: center;
}

.ticket_box_btn_1 {
  width: 150px;
  height: 32px;
  background: #e15186;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

.ticket_box_btn_1 span {
  font-size: 16px;
  font-family: "pang";
  font-weight: 400;
  color: #ffffff;
}

.ticket_box_btn_1 img {
  width: 18px;
  margin-left: 16px;
}

.ticket_box_btn_2 {
  width: 80px;
  height: 32px;
  background: #e15186;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  line-height: 32px;
  text-align: center;
}

.ticket_box_btn_2 span {
  font-size: 12px;
  font-family: "pang";
  font-weight: 400;
  color: #ffffff;
}

.disable_btn {
  background: #707070 !important;
}

.disable_btn span {
  color: #d3d3d3 !important;
}

.copy_button:hover {
  cursor: pointer;
  opacity: 0.8;
}

.copy-tip {
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #e15186;
  color: #000;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.copy-tip-enter-active,
.copy-tip-leave-active {
  transition: opacity 0.5s, transform 0.5s;
}

.copy-tip-enter,
.copy-tip-leave-to {
  opacity: 0;
  transform: translateY(20px);
}
</style>