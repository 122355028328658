<template>
    <div>

        <div class="ftoken ftobor wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1s">
            <form action="" method="post">
                <table border="0" cellspacing="1" cellpadding="1" width="100%">
                    <tr>
                        <th>Token</th>
                        <th>Product</th>
                        <th>Quantity</th>
                        <th>Order Date</th>
                        <th>Days Remaining</th>
                        <th>Expiry Date</th>
                        <th>Total</th>
                        <th>Auth Type</th>
                        <th>Auto Renew</th>
                    </tr>

                    <tr v-for="(item, index) in orderListData" :key="index">
                        <td>Token</td>
                        <td>Coke</td>
                        <td>1</td>
                        <td>{{ item.createTime }}</td>
                        <td>9</td>
                        <td>Exing</td>
                        <td>{{ item.totalMoney }}</td>
                        <td>Auth Type</td>
                        <td>
                            <div class="sub">
                                <div class="che"><img src="@/assets/images/fn11.png" alt=""></div>
                                <div class="che"><img src="@/assets/images/fn12.png" alt=""></div>
                            </div>
                        </td>
                    </tr>

                </table>
            </form>
        </div>
        <div class="fpagels clearfix wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1s">
            <ul>
                <li>
                    <div class="prod"></div>
                </li>
                <li class="on"><a href="#">1</a></li>
                <li><a href="#">2</a></li>
                <li>
                    <div class="next"></div>
                </li>
            </ul>
        </div>
        <div class="fproxen ftobor wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1s">
            <div class="clearfix ftoen">
                <h2>Proxies</h2>
                <a href="#" class="fcopy">Copy</a>
            </div>
            <div class="ftoken">
                <form action="" method="post">
                    <table border="0" cellspacing="1" cellpadding="1" width="100%">
                        <tr>
                            <td>Token</td>
                            <td>Coke</td>
                            <td>12</td>
                            <td>07/05/2022</td>
                            <td>Days</td>
                            <td>Remaining</td>
                            <td>Exing</td>
                            <td>Total</td>
                            <td>Auth Type</td>
                            <td>Auto Renews</td>
                        </tr>
                    </table>
                </form>
            </div>
        </div>

    </div>
</template>

<script>
import {orderList} from '@/api/index'

export default {
    name: 'proxies',
    components: {},
    data() {
        return {
            orderListData: []
        }
    },
    updated() {
    },
    mounted() {
        new this.$wow.WOW().init()
        this.getOrderList()
    },
    methods: {
        // 订单列表
        getOrderList() {
            var that = this
            orderList({}, (res) => {
                this.orderListData = res.result
            }, (err) => {
                console.log(err)
            })
        },
    }
}
</script>
<style scoped>

</style>