var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-login"},[_c('div',{staticClass:"layout"},[_c('header-com',{attrs:{"index":-1,"scrollTop":_vm.scrollTop}}),_c('div',{staticClass:"flogin"},[_c('div',{staticClass:"wrap"},[_vm._m(0),_c('div',{staticClass:"floginvia wow fadeInUp",attrs:{"data-wow-delay":"0.3s","data-wow-duration":"1s"}},[_vm._m(1),_c('div',{staticClass:"femail"},[_c('div',{staticClass:"form-box"},[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"label-width":"160px"}},[_c('el-form-item',{staticStyle:{"padding-top":"20px","padding-bottom":"20px"},attrs:{"label":"Username","prop":"username","rules":[
            { required: true, message: 'Please input username', trigger: 'blur' },
            { min: 4, max: 10, message: 'Length should be 4 to 10', trigger: 'blur' },
            { pattern: /^[a-zA-Z0-9]{4,16}$/, message: 'Can only contain letters and numbers', trigger: 'blur' }
        ],"required":"","label-tooltip":"Can only contain letters and numbers"}},[_c('el-input',{attrs:{"placeholder":"username"},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}})],1),_c('el-form-item',{attrs:{"label":"Email","prop":"email","rules":[
            { required: true, message: 'Please input email', trigger: 'blur' },
            { type: 'email', message: 'Please input correct email format', trigger: 'blur' }
        ],"required":""}},[_c('el-input',{attrs:{"placeholder":"email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1),_c('el-form-item',{attrs:{"label":"Code","prop":"email_code","rules":[
            { required: true, message: 'Please input code', trigger: 'blur' },
        ],"required":""}},[_c('el-input',{attrs:{"placeholder":"Verification code"},model:{value:(_vm.form.email_code),callback:function ($$v) {_vm.$set(_vm.form, "email_code", $$v)},expression:"form.email_code"}})],1),_c('el-form-item',{staticStyle:{"margin-bottom":"50px"},attrs:{"label":"Password","prop":"password","rules":[
            { required: true, message: 'Enter a password', trigger: 'blur' },
            { pattern: /(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^a-zA-Z0-9]).{8,20}/, message: 'Password must contain at least one lowercase letter, one uppercase letter, one number, one special character and length should be 8 to 20', trigger: 'blur' }],"required":""}},[_c('el-input',{attrs:{"show-password":""},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1),_c('el-form-item',{attrs:{"label":"Confirm Password","prop":"repassword","rules":[
            { required: true, message: 'Please input re-password', trigger: 'blur' },
            { validator: _vm.checkPassword, trigger: 'blur' }
        ]}},[_c('el-input',{attrs:{"show-password":""},model:{value:(_vm.form.repassword),callback:function ($$v) {_vm.$set(_vm.form, "repassword", $$v)},expression:"form.repassword"}})],1),_c('el-form-item',{attrs:{"label":"Invite code"}},[_c('el-input',{model:{value:(_vm.form.invite_code),callback:function ($$v) {_vm.$set(_vm.form, "invite_code", $$v)},expression:"form.invite_code"}})],1)],1),(!_vm.countDown)?_c('div',{staticClass:"send-code",on:{"click":_vm.getCode}},[_vm._v(" send code ")]):_c('div',{staticClass:"send-code"},[_vm._v(" "+_vm._s(_vm.countDown)+"s ")])],1),_c('div',{staticClass:"operate"},[_c('div',{staticClass:"sign_up_btn",on:{"click":_vm.onSubmit}},[_vm._v("sign up")])]),_c('div',{staticClass:"femil"},[_c('form',{attrs:{"action":"","method":"post"}},[_vm._m(2),_c('input',{staticClass:"sub",attrs:{"type":"submit","value":"login"},on:{"click":function($event){return _vm.submit()}}})])])])])])])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fpitl flog wow fadeInUp",attrs:{"data-wow-delay":"0.2s","data-wow-duration":"1s"}},[_c('h3',[_vm._v("Log in！")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ficon"},[_c('img',{attrs:{"src":require("@/assets/images/fico19.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fimgs"},[_c('img',{attrs:{"src":require("@/assets/images/fico20.png"),"alt":""}})])
}]

export { render, staticRenderFns }