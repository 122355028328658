<template>
    <div>
        <el-card class="box-card" shadow="never">
            <el-card style="background-color: #febac3;margin-bottom: 20px;" shadow="never">
                <el-form :inline="true" :model="formInline" class="demo-form-inline">
                    <el-form-item>
                        <el-input v-model="formInline.search" @change="onSubmit"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button @click="onSubmit">search</el-button>
                    </el-form-item>
                </el-form>
            </el-card>
            <el-table :data="tableData" style="width: 100%" header-cell-class-name="table_header"
                      v-loading="tableLoading">
                <el-table-column prop="id" label="id" align="center">
                </el-table-column>
                <el-table-column prop="email" label="Email" align="center">
                </el-table-column>
                <el-table-column prop="username" label="Name" align="center">
                </el-table-column>
                <el-table-column prop="phone" label="Phone" align="center">
                </el-table-column>
                <el-table-column prop="message" label="Message" align="center" width="300">
                    <template v-slot="{ row }">
                        <span class="textHidden">{{ row.message }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="Action" align="center" width="130">
                    <template v-slot="{ row, $index }">
                        <el-button type="text" @click="handleView(row)">View</el-button>
                        <el-button type="text" @click="handleDelete(row, $index)">Delete</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination background layout="->,prev, pager, next" :total="total" style="margin-top: 20px;"
                           :current-page.sync="currentPage" @current-change="handleCurrentChange">
            </el-pagination>
        </el-card>

        <el-dialog :visible.sync="dialogFormVisible" center width="45%">
            <div style="width: 75%;margin: 0 auto;">
                <el-descriptions class="margin-top" :column="2" border colon :labelStyle="{ width: '125px' }">
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-user"></i>
                            userName
                        </template>
                        {{ viewInfo.username }}
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-message"></i>
                            Email
                        </template>
                        {{ viewInfo.email }}
                    </el-descriptions-item>

                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-chat-square"></i>
                            message
                        </template>
                        {{ viewInfo.message }}
                    </el-descriptions-item>
                </el-descriptions>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">Close</el-button>

            </div>
        </el-dialog>
    </div>
</template>

<script>
import {getList, deleteTick} from "@/api/dashboard/tickets"

export default {
    data() {
        return {
            msg: "userList",
            formInline: {
                search: '',
            },
            tableData: [],
            currentPage: 1,
            total: 0,
            tableLoading: false,
            dialogFormVisible: false,
            viewInfo: {}
        }
    },
    mounted() {
        new this.$wow.WOW().init()
        this.getTableData()
    },
    methods: {
        handlePassword() {
            if (this.password == "qazmkoiuytr") this.password = ""
        },

        onSubmit() {
            this.currentPage = 1
            this.getTableData()
        },
        handleCurrentChange(val) {

            this.getTableData()
        },
        getTableData() {
            const offset = (this.currentPage - 1) * 10
            const param = {search: this.formInline.search, limit: 10, offset}
            this.tableLoading = true
            getList(param, (res) => {

                this.tableData = res.data.data
                this.total = res.data.total
                this.tableLoading = false
            })
        },
        handleView(row) {
            this.dialogFormVisible = true
            this.viewInfo = row
        },
        handleDelete(row, index) {
            this.$confirm('Confirm deletion', `Delete`, {
                confirmButtonText: 'YES',
                cancelButtonText: 'NO',
                type: 'warning'
            }).then(() => {
                deleteTick({id: row.id}, (res) => {
                    this.currentPage = this.currentPage === 1 ? this.currentPage : (this.tableData.length == 1 && index === 0) ? this.currentPage - 1 : this.currentPage
                    this.getTableData()
                })
            }).catch(() => {

            })
        }

    }
}
</script>

<style scoped lang="scss">
.box-card {
  :deep(.el-card) {
    border-radius: 18px;
  }

  :deep(.el-form) {
    font-family: "Pang";
  }

  :deep(thead) {
    font-family: "Pang";
  }

  :deep(.el-form-item__label) {
    font-size: 20px;
    color: #fff;
  }

  :deep(.el-input__inner) {
    background-color: transparent;
    border: 3px solid #fff;
    color: #fff;
  }

  :deep(.el-form-item) {
    margin-bottom: 0;
  }


  .demo-form-inline {
    :deep(.el-button) {
      font-family: "Pang";
      background-color: #d6227a;
      color: #fff;
      border-radius: 10px;
    }
  }

  :deep(.table_header) {
    background-color: #f1408c !important;
    color: #fff;
  }

  :deep(.table_header:first-child) {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  :deep(.table_header:nth-child(6)) {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  :deep(.el-pagination.is-background .el-pager li:not(.disabled).active) {
    background-color: #f1408c;
  }


  .textHidden {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

  }
}
</style>