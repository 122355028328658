<template>
    <div>
        <el-card class="box-card" shadow="never">
            <el-card style="background-color: #febac3;margin-bottom: 20px;" shadow="never">
                <el-form :inline="true" :model="formInline" class="demo-form-inline">
                    <el-form-item>
                        <el-input v-model="formInline.search" @change="onSubmit"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button @click="onSubmit">search</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button @click="handleAdd">Add</el-button>
                    </el-form-item>
                </el-form>
            </el-card>
            <el-table :data="tableData" style="width: 100%" header-cell-class-name="table_header"
                      v-loading="tableLoading">
                <el-table-column prop="id" label="id" align="center" width="50">
                </el-table-column>
                <el-table-column prop="name" label="name" align="center" width="80">
                </el-table-column>
                <el-table-column prop="price" label="price" align="center" width="60">
                </el-table-column>
                <el-table-column prop="shopify_variant_id" label="Shopify Variant Id" align="center" width="140">
                </el-table-column>
                <el-table-column prop="description" label="description" align="center" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="acl_value" label="Rule" align="center" width="230">
                    <template v-slot="{ row }">
                        <div style="display: flex;flex-wrap: wrap;width: 100%;justify-content: flex-start;align-items: center; overflow: auto; max-height: 100px;">
                            <el-tag v-for="item in row.aclList" :key="item"
                                    style="margin: 0;margin-right: 5px;color: #000; background-color: #f5f5f5; border-color: #e5e5e5;">
                                {{ item }}
                            </el-tag>
                        </div>
                    </template>
                </el-table-column>


                <el-table-column prop="created_at" label="Create Time" align="center" width="112">
                    <template v-slot="{ row }">
                        <el-tooltip class="item" effect="dark" :content="row.created_at" placement="top-start"
                                    :content-style="{ 'max-height': '60px' }">
                            <div class="ellipsis">{{ row.created_at.substr(0, 10) }}</div>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column label="Action" align="center" width="130">
                    <template v-slot="{ row, $index }">
                        <el-button type="text" @click="handleEdit(row)">Edit</el-button>
                        <el-button type="text" @click="handleDelete(row, $index)">Delete</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination background layout="->,prev, pager, next" :total="total" style="margin-top: 20px;"
                           :current-page.sync="currentPage" @current-change="handleCurrentChange">
            </el-pagination>
        </el-card>

        <el-dialog :visible.sync="dialogFormVisible" center width="35%">
            <div style="width: 75%;margin: 0 auto;">
                <el-form :model="form" label-width="150px" :rules="rules" ref="ruleForm">
                    <el-form-item label="name" prop="name">
                        <el-input v-model="form.name"></el-input>
                    </el-form-item>
                    
                    <el-form-item label="price" prop="price">
                        <el-input v-model="form.price"></el-input>
                    </el-form-item>
                    <el-form-item label="Shopify Variant Id" prop="shopify_variant_id">
                        <el-input v-model="form.shopify_variant_id"></el-input>
                    </el-form-item>
                    <el-form-item label="description" prop="description">
                        <el-input type="textarea" v-model="form.description" autosize></el-input>
                    </el-form-item>
                    <el-form-item label="Rules" prop="acl_value">
                        <el-input type="textarea" v-model="form.acl_value" autosize></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="handleSubmit" type="primary" :loading="EditLoading">Save</el-button>
                <el-button @click="dialogFormVisible = false" type="danger" :disabled="EditLoading">Cancel</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {getList, handleAcl, addAcl} from "@/api/dashboard/acl"

export default {
    data() {
        return {
            allRules: '',
            showAll: false,
            showDialog: false,
            formInline: {
                search: '',
            },
            tableData: [],
            currentPage: 1,
            total: 0,
            tableLoading: false,
            dialogFormVisible: false,
            EditLoading: false,
            // viewInfo: {}
            form: {
                name: '',
                description: "",
                acl_value: "",
                shopify_variant_id: "",
                price: ""
            },
            rules: {
                name: [
                    {required: true, message: 'Please enter name', trigger: 'blur'},
                ],
                price: [
                    {required: true, message: 'Please enter price', trigger: 'blur'},
                ],
                shopify_variant_id: [
                    {required: true, message: 'Please enter Shopify Variant Id', trigger: 'blur'},
                ],
                acl_value: [
                    {required: true, message: 'Please enter rule', trigger: 'blur'},
                ],
                description: [
                    {required: true, message: 'Please enter description', trigger: 'blur'},
                ],
            },
            handleType: ""
        }
    },
    mounted() {
        new this.$wow.WOW().init()
        this.getTableData()
    },
    methods: {
        showRules() {
            this.showDialog = true;
            this.allRules = this.row.aclList.join('\n');
        },
        handlePassword() {
            if (this.password == "qazmkoiuytr") this.password = ""
        },

        onSubmit() {
            this.currentPage = 1
            this.getTableData()
        },
        handleCurrentChange(val) {
            this.getTableData()
        },
        getTableData() {
            const offset = (this.currentPage - 1) * 10
            const param = {search: this.formInline.search, limit: 10, offset}
            this.tableLoading = true
            getList(param, (res) => {

                this.tableData = res.data.data.map(item => {
                    item.aclList = item.acl_value.split('\n')
                    return item
                })
                this.total = res.data.total
                this.tableLoading = false
            })
        },
        handleEdit(row) {
            this.handleType = "edit"
            this.dialogFormVisible = true
            this.$nextTick(() => {
                // this.$refs["ruleForm"].resetFields()
                this.form = row
            })
        },
        handleAdd() {
            this.handleType = "add"
            this.form = {
                name: '',
                price: "",
                shopify_variant_id: "",
                description: "",
                acl_value: ""
            }
            this.dialogFormVisible = true
            this.$nextTick(() => {
                this.$refs["ruleForm"].resetFields()
            })
        },
        handleSubmit() {
            this.$refs["ruleForm"].validate((valid) => {
                if (valid) {
                    this.EditLoading = true
                    if (this.handleType == "edit") {
                        handleAcl("put", this.form, (res) => {
                            this.dialogFormVisible = false
                            this.EditLoading = false
                            this.$message.success("successfully")
                            this.getTableData()
                        }, (res) => {
                            this.$message.warning(res.msg)
                            this.EditLoading = false
                        })
                    } else {
                        //
                        addAcl(this.form, (res) => {
                            this.dialogFormVisible = false
                            this.EditLoading = false
                            this.$message.success("successfully")
                            this.getTableData()
                        }, (res) => {
                            this.$message.warning(res.msg)
                            this.EditLoading = false
                        })
                    }
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        handleDelete(row, index) {
            this.$confirm(`Confirm deletion`, 'Delete ?', {
                confirmButtonText: 'YES',
                cancelButtonText: 'NO',
                type: 'warning'
            }).then(() => {
                handleAcl(
                    "delete",
                    {id: row.id},
                    (res) => {
                        this.currentPage = this.currentPage === 1 ? this.currentPage : (this.tableData.length == 1 && index === 0) ? this.currentPage - 1 : this.currentPage
                        this.$message.success("successfully")
                        this.getTableData()
                    },
                )
            }).catch(() => {

            })
        }


    }
}
</script>

<style scoped lang="scss">
.box-card {
  :deep(.el-card) {
    border-radius: 18px;
  }

  :deep(.el-form),
  :deep(thead) {
    font-family: "Pang";
  }

  :deep(.el-form-item__label) {
    font-size: 20px;
    color: #fff;
  }

  :deep(.el-input__inner) {
    background-color: transparent;
    border: 3px solid #fff;
    color: #fff;
  }

  :deep(.el-form-item) {
    margin-bottom: 0;
  }

  .demo-form-inline {
    :deep(.el-button) {
      font-family: "Pang";
      background-color: #d6227a;
      color: #fff;
      border-radius: 10px;
    }
  }

  :deep(.table_header) {
    background-color: #f1408c;
    color: #fff;

    &:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    &:nth-child(6) {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }

  :deep(.el-pagination.is-background .el-pager li:not(.disabled).active) {
    background-color: #f1408c;
  }

}
</style>