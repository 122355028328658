<template>
    <div>
        <el-card class="box-card" shadow="never" v-if="!variantCardShow">
            <el-card style="background-color: #febac3;margin-bottom: 20px;" shadow="never">
                <el-form :inline="true" :model="formInline" class="demo-form-inline">
                    <el-form-item>
                        <el-input v-model="formInline.search" @change="onSubmit"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button @click="onSubmit">search</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button @click="handleAdd">Add</el-button>
                    </el-form-item>
                </el-form>
            </el-card>
            <el-table :data="tableData" style="width: 100%" header-cell-class-name="table_header"
                v-loading="tableLoading" :row-style="{ height: '100px' }" :row-class-name="tableRowClassName">
                <el-table-column prop="id" label="id" align="center" width="50px" sortable>
                </el-table-column>
                
                <el-table-column prop="active" label="Active" align="center" sortable>
                    <template v-slot="{ row }">
                        <el-switch v-model="row.active" disabled></el-switch>
                    </template>
                </el-table-column>
                <el-table-column prop="valid" label="Valid" align="center" sortable>
                    <template v-slot="{ row }">
                        <el-switch v-model="row.valid" disabled></el-switch>
                    </template>
                </el-table-column>

                <el-table-column prop="product_name" label="product" align="center" width="250px" sortable>
                    <template v-slot="{ row }">
                        <el-tooltip class="item" effect="dark" :content="row.product_name" placement="top">
                            <el-tag disable-transitions type="success">
                                {{ row.product_name }}
                            </el-tag>
                        </el-tooltip>

                    </template>

                </el-table-column>

                <el-table-column label="Prod. Type" align="center" width="200px" sortable>
                    <template v-slot="{ row }">
                        <el-tag v-for="collection in row.product_collections" :key="collection.id" disable-transitions>
                            {{ collection.collection_name }}
                        </el-tag>
                    </template>
                </el-table-column>

                <el-table-column prop="point" label="Prod. Tag" align="center" width="200px">
                    <template v-slot="{ row }">
                        <el-tag v-for="tag in row.product_tags" :key="tag.id" disable-transitions effect="plain"
                            type="dark">
                            {{ tag.tag_name }}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="code" label="Option" align="center">
                    <template v-slot="{ row }">
                        <span>{{ row.variant_options.map(item => item.option_name).join(' ; ') }}</span>
                    </template>
                </el-table-column>
                

                <el-table-column label="Stocks" align="center">
                    <template v-slot="{ row }">
                        <span>{{ row.variants.map(item => item.variant_stock).join(' ; ') }}</span>
                    </template>
                </el-table-column>

                <el-table-column label="Server group" align="center">
                    <template v-slot="{ row }">
                        <span>{{ [...new Set(row.variants.map(item => item.server_group.name))].join(' ; ') }}</span>
                    </template>
                </el-table-column>

                <!-- <el-table-column label="Acl group" align="center">
              <template v-slot="{ row }">
                  <span>{{ [...new Set(row.variants.map(item => item.acl_group.name))].join(' ; ') }}</span>
              </template>
          </el-table-column> -->


                <el-table-column prop="created_at" label="Create Time" align="center" width="120">
                    <template v-slot="{ row }">
                        <el-tooltip class="item" effect="dark" :content="row.created_at" placement="top-start"
                            :content-style="{ 'max-height': '60px' }">
                            <div class="ellipsis">{{ row.created_at.substr(0, 10) }}</div>
                        </el-tooltip>
                    </template>
                </el-table-column>

                <el-table-column label="Action" align="center" width="130" fixed>
                    <template v-slot="{ row, $index }">
                        <el-button type="text" @click="handleProductEdit(row, $index)">Edit</el-button>
                        <el-button type="text" @click="handleDelete(row, $index)">Delete</el-button>
                    </template>
                </el-table-column>
                <el-table-column prop="old_flag" label="Old" align="center" sortable>
                    <template v-slot="{ row }">
                        <el-switch v-model="row.old_flag" disabled></el-switch>
                    </template>
                </el-table-column>

            </el-table>
            <el-pagination background layout="->,prev, pager, next" :total="total" style="margin-top: 20px;"
                :page-size="10" :current-page.sync="currentPage" @current-change="handleCurrentChange">
            </el-pagination>
        </el-card>
        <el-card v-else v-loading="loading">
            <el-page-header @back="goBack">
            </el-page-header>
            Edit Product
            <div style="margin-top: 20px;">
                <div v-if="!isEdit">
                    <el-form label-position="top" label-width="80px" inline>
                        <div style="font-size: 15px; margin-bottom: 15px; display: flex; flex-wrap: wrap;">
                            <el-form-item label="Select the Shopify product">
                                <el-select v-model="shopifyValue" @change="handleSelectChange"
                                    style="width: 400px; font-size: 16px; border-radius: 4px; border: 1px solid #dcdfe6;">
                                    <el-option v-for="item in optionsList" :key="item.shopify_product_id"
                                        :value="item.shopify_product_id" :label="item.product_name"
                                        style="font-size: 14px;"></el-option>
                                </el-select>
                            </el-form-item>
                            <div style="margin-right: 20px; display: flex; align-items: center;">
                                <el-form-item label="Name">
                                    <el-input v-model="product_name" autosize></el-input>
                                </el-form-item>
                            </div>
                            <div style="margin-right: 20px; display: flex; align-items: center;">
                                <el-form-item label="Group">
                                    <div style="display: flex; flex-wrap: wrap;">
                                        <div style="margin-right: 10px;" v-for="collection in product_collections">
                                            <el-tag size="medium" type="primary"
                                                style="margin-bottom: 5px; height: 32px;">
                                                {{ collection.collection_name }}
                                            </el-tag>
                                        </div>
                                    </div>
                                </el-form-item>
                            </div>
                            <div style="display: flex; align-items: center;">
                                <el-form-item label="Tag">
                                    <div style="display: flex; flex-wrap: wrap;">
                                        <div style="margin-right: 10px;" v-for="tag in product_tags">
                                            <el-tag size="medium" type="info" style="margin-bottom: 5px; height: 32px;">
                                                {{ tag.tag_name }}
                                            </el-tag>
                                        </div>
                                    </div>
                                </el-form-item>
                            </div>
                        </div>
                        <div style="width: 600px;">
                            <el-form-item label="Product description" style="width: 100%;">
                                <el-card shadow="always" style="height: 200px; overflow: auto;">
                                    <div ref="div" style="white-space: pre-wrap;"></div>
                                </el-card>
                            </el-form-item>
                        </div>
                    </el-form>
                </div>

                <div>
                    <div style="display: flex; flex-direction: column;">

                        <div>
                            <el-table :data="variants" style="width: 100%" height="500" border>
                                <el-table-column label="Variant Id" prop="id" align="center">
                                </el-table-column>
                                <el-table-column label="Options Name" prop="variant_name" align="center">
                                </el-table-column>
                                <el-table-column label="Price" prop="variant_price" align="center">
                                </el-table-column>
                                <el-table-column label="Stock" prop="variant_stock" align="center">
                                </el-table-column>
                                <el-table-column label="Server Group" prop="variant_stock" align="center">
                                    <template v-slot="{ row }">
                                        <span>{{ row.server_group.name }}</span>
                                    </template>
                                </el-table-column>
                                <!-- <el-table-column label="Acl Group" prop="variant_stock" align="center">
                      <template v-slot="{ row }">
                          <span>{{ row.acl_group.name }}</span>
                      </template>
                  </el-table-column> -->
                                <el-table-column label="Location" prop="variant_desc" align="center">
                                </el-table-column>
                                <el-table-column label="Cart Step" prop="cart_step" align="center">
                                </el-table-column>
                                <el-table-column label="Proxy time" prop="proxy_time" align="center">
                                </el-table-column>
                                <el-table-column prop="variant_stock" label="Active" align="center">
                                    <template v-slot="{ row }">
                                        <el-tag type="success" v-if="row.is_active">Yes</el-tag>
                                        <el-tag type="danger" v-else>No</el-tag>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Actions" align="center">
                                    <template v-slot="{ row, $index }">
                                        <el-button type="text" @click="hanldeEdit(row, $index)">Edit</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                </div>
            </div>
            <div style='display: flex; width: 100%; justify-content: center;'>
                <el-button type="danger" :disabled="isButtonDisabled" @click="handleSubmitAdd" :loading="formLoading">
                    Submit
                </el-button>
            </div>
        </el-card>
        <el-dialog title="Modify product" :visible.sync="dialogFormVisible" :close-on-click-modal="false">
            <el-form :model="form" v-loading="formLoading" ref="formform">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="options" :label-width="formLabelWidth">
                            {{ form.variant_name }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="Product group" :label-width="formLabelWidth">
                            {{ form.product_collections }}
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="price" :label-width="formLabelWidth">
                            <div v-if="!isEdit">{{ form.variant_price }}</div>
                            <el-input v-model="form.variant_price" v-else></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="Product Tag" :label-width="formLabelWidth">
                            {{ form.product_tags }}
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item label="serve group" :label-width="formLabelWidth">
                    <el-select v-model="form.serveId">
                        <el-option label="区域一" v-for="item_s in serverList" :label="item_s.name" :value="item_s.id"
                            :key="`serve-${item_s.id}`"></el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label="acl group" :label-width="formLabelWidth">
              <el-select v-model="form.aclId">
                  <el-option v-for="item in aclList" :label="item.name" :value="item.id"
                             :key="`acl-${item.id}`"></el-option>
              </el-select>
          </el-form-item> -->
                <el-form-item label="active" :label-width="formLabelWidth">
                    <el-select v-model="form.is_active">
                        <el-option label="Yes" :value="true"></el-option>
                        <el-option label="No" :value="false"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="cart step" :label-width="formLabelWidth">
                    <el-select v-model="form.cart_step" :disabled="isEdit">
                        <!-- 1 8 16 32 64 128 256 -->
                        <el-option label="1" :value="1"></el-option>
                        <el-option label="8" :value="8"></el-option>
                        <el-option label="16" :value="16"></el-option>
                        <el-option label="32" :value="32"></el-option>
                        <el-option label="64" :value="64"></el-option>
                        <el-option label="128" :value="128"></el-option>
                        <el-option label="255" :value="256"></el-option>
                        <el-option label="256" :value="256"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="Proxy Time" :label-width="formLabelWidth">
                    <el-input-number v-model="form.proxy_time" :min="1"></el-input-number>
                </el-form-item>
                <el-form-item label="Stock" :label-width="formLabelWidth">
                    <el-input v-model="form.variant_stock" autocomplete="off" disabled></el-input>
                </el-form-item>
                <el-form-item label="Location" :label-width="formLabelWidth">
                    <el-input v-model="form.variant_desc" autocomplete="off" type="textarea"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">cancel</el-button>
                <el-button type="primary" @click="handleSubmit" :disabled="formLoading">confirm</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { getList, handle, add, get_product_from_shopify, edit } from "@/api/dashboard/productManager"
// import { getList as aclGetList } from "@/api/dashboard/acl"
import { getList as serversGetList } from "@/api/dashboard/servergroup"
import _ from "lodash"

export default {
    data() {
        return {
            formInline: {
                search: '',
            },
            tableData: [],
            currentPage: 1,
            total: 0,
            tableLoading: false,
            dialogFormVisible: false,
            EditLoading: false,
            variantCardShow: false,
            shopifyValue: '',
            loading: false,
            optionsList: [],
            desc: "",
            product_collections: [],
            product_name: '',
            product_tags: [],
            variant_options: [],
            variants: [],
            isLoading: false,
            form: {
                variant_name: '',
                variant_price: '',
                product_collections: '',
                product_tags: '',
                serveId: "",
                aclId: "",
                is_active: '',
                cart_step: '',
                variant_stock: '',
                variant_desc: '',
                proxy_time: 1,
            },
            formLabelWidth: '120px',
            serverList: [],
            formLoading: false,
            isEdit: false,
            isButtonDisabled: false,
        }
    },
    mounted() {
        new this.$wow.WOW().init()
        this.getTableData()
        this.isButtonDisabled = false
    },
    methods: {
        tableRowClassName({ row }) {
            console.log('Row active status:', row.active);
            return row.active ? '' : 'row-red';
        },
        handlePassword() {
            if (this.password == "qazmkoiuytr") this.password = ""
        },

        onSubmit() {
            this.currentPage = 1
            this.getTableData()
        },
        handleCurrentChange(val) {
            this.getTableData()
        },
        getTableData() {
            const offset = (this.currentPage - 1) * 10
            const param = { search: this.formInline.search, limit: 10, offset }
            this.tableLoading = true
            getList(param, (res) => {


                this.tableData = res.data.data
                // this.tableData = aa.data.data
                this.total = res.data.total
                // this.total = aa.data.total
                this.tableLoading = false
            })
            serversGetList({ limit: 10000, offset: 0 }, res => {
                this.serverList = res.data.data
            }, res => {
                this.$message.error(res.data.msg)
            })
        },
        handleDelete(row, index) {
            this.$confirm(`Confirm deletion`, 'Delete ?', {
                confirmButtonText: 'YES',
                cancelButtonText: 'NO',
                type: 'warning'
            }).then(() => {
                handle(
                    "delete",
                    { id: row.id },
                    (res) => {
                        this.currentPage = this.currentPage === 1 ? this.currentPage : (this.tableData.length == 1 && index === 0) ? this.currentPage - 1 : this.currentPage
                        this.$message.success("successfully")
                        this.getTableData()
                    },
                )
            }).catch(() => {

            })
        },

        async handleAdd() {
            this.variantCardShow = true
            this.isEdit = false
            this.loading = true
            const p1 = new Promise((resolve, reject) => {
                get_product_from_shopify((res) => {
                    this.optionsList = res.data
                    resolve()
                }, res => {
                    reject()

                })
            })
            const p2 = new Promise((resolve, reject) => {
                serversGetList({ limit: 10000, offset: 0 }, res => {
                    this.serverList = res.data.data
                    resolve()
                }, res => {
                    reject()
                })
            })
            const result = await Promise.allSettled([p1, p2])

            const flag = result.every(item => item.status == "fulfilled")
            if (flag) {
                this.loading = false
            } else {
                this.$message.warning("Failed to obtain data, please try again")
                this.goBack()
            }
        },
        goBack() {
            this.variantCardShow = false
            this.form = {
                variant_name: '',
                variant_price: '',
                product_collections: '',
                product_tags: '',
                serveId: "",
                aclId: "",
                is_active: '',
                cart_step: '',
                variant_stock: '',
                variant_desc: '',
                proxy_time: 1,
            }
            this.variants = []
            this.product_tags = []
            this.product_collections = []
            this.shopifyValue = ""

            this.getTableData()
            this.isButtonDisabled = false
        },
        handleSelectChange(val, abc) {
            const data = this.optionsList.find(item => item.shopify_product_id == val)
            this.$refs.div.innerHTML = data.product_desc ? data.product_desc.replaceAll('\n', '') : "<div>There is no Product description</div>"
            this.product_collections = data.product_collections
            this.product_name = data.product_name
            this.product_desc = data.product_desc
            this.product_tags = data.product_tags
            this.variant_options = data.variant_options
            this.variants = abc || data.variants
        },
        hanldeEdit(data, index) {
            this.dialogFormVisible = true
            this.form.id = parseInt(data.id)
            this.form.variant_name = data.variant_name
            this.form.variant_price = data.variant_price
            this.form.product_collections = this.product_collections.map(item => item.collection_name).join(' ; ')
            this.form.product_tags = this.product_tags.map(item => item.tag_name).join(' ; ')
            this.form.is_active = data.is_active
            this.form.cart_step = data.cart_step
            this.form.variant_stock = data.variant_stock
            this.form.variant_desc = data.variant_desc
            this.form.proxy_time = data.proxy_time
            this.index = index
            this.form.serveId = data.server_group ? data.server_group.id : ""
        },
        handleSubmit() {
            const {
                serveId,
                // aclId,
                is_active,
                cart_step,
                variant_stock,
                variant_desc,
                proxy_time,
                variant_price
            } = this.form
            this.variants[this.index].server_group.name = this.serverList.find(r => r.id == serveId)?.name
            this.variants[this.index].server_group.id = serveId
            // this.variants[this.index].acl_group.name = this.aclList.find(r => r.id == aclId)?.name
            // this.variants[this.index].acl_group.id = aclId
            this.variants[this.index].is_active = is_active
            this.variants[this.index].cart_step = cart_step
            this.variants[this.index].proxy_time = proxy_time
            this.variants[this.index].variant_stock = variant_stock
            this.variants[this.index].variant_desc = variant_desc
            this.isEdit && (this.variants[this.index].variant_price = variant_price)
            this.$refs.formform.resetFields()
            this.dialogFormVisible = false
        },
        handleSubmitAdd() {
            this.isButtonDisabled = true;  // 禁用按钮，防止重复点击
            this.formLoading = true;  // 显示加载动画

            const variants = _.cloneDeep(this.variants);
            variants.forEach(item => {
                item.server_group = item.server_group.id;
                // item.acl_group = item.acl_group.id;
            });

            const param = {
                shopify_product_id: this.shopifyValue,
                variants,
                variant_options: this.variant_options,
                product_collections: this.product_collections,
                product_tags: this.product_tags,
                product_name: this.product_name,
                product_desc: this.product_desc
            };
            this.$message.success("请求中，请稍后...");
            if (this.isEdit) {
                const editParam = {
                    id: this.editId,
                    product_tags: this.product_tags,
                    product_name: this.product_name,
                    product_desc: this.product_desc,
                    shopify_product_id: this.shopifyValue,
                    variants,
                };
                edit(editParam, res => {
                    this.formLoading = false;  // 停止加载动画
                    this.isButtonDisabled = false;  // 启用按钮
                    this.$message.success("编辑成功！");  // 显示成功提示
                }, res => {
                    this.formLoading = false;  // 停止加载动画
                    this.isButtonDisabled = false;  // 启用按钮
                    this.$message.warning(res.msg);  // 显示失败提示
                });
            } else {
                add(param, res => {
                    this.formLoading = false;  // 停止加载动画
                    this.isButtonDisabled = false;  // 启用按钮
                    this.$message.success("添加成功！");  // 显示成功提示
                }, res => {
                    this.formLoading = false;  // 停止加载动画
                    this.isButtonDisabled = false;  // 启用按钮
                    this.$message.warning(res.msg);  // 显示失败提示
                });
            }
        },
        handleEditSelectChange(row_data) {

            this.product_collections = row_data.product_collections
            this.product_name = row_data.product_name
            this.product_desc = row_data.product_desc
            this.product_tags = row_data.product_tags
            this.variant_options = row_data.variant_options
            this.variants = row_data.variants
        },
        async handleProductEdit(row, index) {
            this.isEdit = true
            this.editId = row.id
            this.shopifyValue = +row.shopify_product_id
            this.variantCardShow = true
            this.handleEditSelectChange(row)


        }


    }
}
</script>

<style scoped lang="scss">
.box-card {
    :deep(.el-card) {
        border-radius: 18px;
    }

    :deep(.el-form) {
        font-family: "Pang";
    }

    :deep(thead) {
        font-family: "Pang";
    }

    :deep(.el-form-item__label) {
        font-size: 20px;
        color: #fff;
    }

    :deep(.el-input__inner) {
        background-color: transparent;
        border: 3px solid #fff;
        color: #fff;
    }

    :deep(.el-form-item) {
        margin-bottom: 0;
    }


    .demo-form-inline {
        :deep(.el-button) {
            font-family: "Pang";
            background-color: #d6227a;
            color: #fff;
            border-radius: 10px;
        }
    }

    :deep(.table_header) {
        background-color: #f1408c !important;
        color: #fff;
    }

    :deep(.table_header:first-child) {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }

    :deep(.table_header:nth-child(10)) {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    :deep(.el-pagination.is-background .el-pager li:not(.disabled).active) {
        background-color: #f1408c;
    }


}

.el-row {
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }
}

.el-col {
    border-radius: 4px;
}

.bg-purple-dark {
    background: #99a9bf;
}

.bg-purple {
    background: #d3dce6;
}

.bg-purple-light {
    background: #e5e9f2;
}

.grid-content {
    border-radius: 4px;
    min-height: 36px;
}

.el-table__header th .cell {
    font-size: 12px;
    line-height: 16px;
}

.row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
}

::v-deep .row-red {
  background-color: #d44060 !important;  // 使用 !important 确保样式生效
}
</style>