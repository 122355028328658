<template>
    <div>
        <el-card>
            <h2 class="Tip">You can earn points by inviting new users, and you can earn points when the invited users
                spend
                money.</h2>
        </el-card>
        <el-card class="box-card" shadow="never">
            <el-card style="background-color: #febac3;margin-bottom: 20px;" shadow="never" v-loading="cardLoading">
                <div style="display: flex;margin-bottom: 10px;justify-content: flex-start;align-items: center;">
                    <el-tag type="success" style="margin-right: 5px; width: 100px;text-align: center;">inviteUrl:
                    </el-tag>
                    <el-tag type="success" style="flex-grow: 1;">{{ invite_url }}</el-tag>
                    <el-tag id="btn" data-clipboard-action="copy" :data-clipboard-text="invite_url" type="primary"
                            style="margin-left: 5px;" @click="copy">Copy
                    </el-tag>
                </div>
                <div style="display: flex;margin-bottom: 10px;justify-content: flex-start;align-items: center;">
                    <el-tag type="success" style="margin-right: 5px;width: 100px;text-align: center;">inviteCode:
                    </el-tag>
                    <el-tag type="success" style="flex-grow: 1;">{{ invite_code }}</el-tag>
                    <el-tag id="btn" data-clipboard-action="copy" :data-clipboard-text="invite_code" type="primary"
                            style="margin-left: 5px;" @click="copy">Copy
                    </el-tag>
                </div>
            </el-card>
            <el-table :data="tableData" style="width: 100%" header-cell-class-name="table_header"
                      v-loading="tableLoading">
                <el-table-column prop="id" label="number" width="150" align="center">
                </el-table-column>
                <el-table-column prop="username" label="Username" align="center">
                </el-table-column>
                <el-table-column prop="created_at" label="Registration Time" align="center" show-overflow-tooltip>
                </el-table-column>
                <!--

                <el-table-column prop="acl_value" label="Rule" align="center" width="330">
                  <template v-slot="{ row }">
                    <div style="display: flex;flex-wrap: wrap;width: 100%;justify-content: flex-start;align-items: center;">
                      <el-button type="text" v-for="item in row.aclList" :key="item"
                        style="margin: 0;margin-right: 5px;color: #000;">{{
                          item }}</el-button>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="created_at" label="Create time" align="center" width="100">
                </el-table-column>
                <el-table-column label="Action" align="center" width="130">
                  <template v-slot="{ row, $index }">
                    <el-button type="text" @click="handleEdit(row)">Edit</el-button>
                    <el-button type="text" @click="handleDelete(row, $index)">Delete</el-button>
                  </template>
                </el-table-column> -->
            </el-table>
            <el-pagination background layout="->,prev, pager, next" :total="total" style="margin-top: 20px;"
                           :current-page.sync="currentPage" @current-change="handleCurrentChange">
            </el-pagination>
        </el-card>


    </div>
</template>

<script>
import {getInvite, getInviteData} from "@/api/invite"
import ClipboardJS from 'clipboard'

export default {
    data() {
        return {
            tableLoading: false,
            cardLoading: false,
            tableData: [],
            invite_url: "",
            invite_code: "",
            currentPage: 1,
            total: 0,
        }
    },
    mounted() {
        new this.$wow.WOW().init()
        this.getInviteMethod()
        this.getTableData()
    },
    methods: {
        handleCurrentChange(val) {

            this.getTableData()
        },
        getInviteMethod() {
            this.cardLoading = true
            getInvite(
                (res) => {
                    this.invite_url = res.data.invite_url
                    this.invite_code = res.data.invite_code
                    this.cardLoading = false
                },
                (res) => {
                    this.cardLoading = false
                    this.$message.error(res.msg)
                })
        },
        copy(type) {

            const clipboard = new ClipboardJS('#btn')
            clipboard.on('success', (e) => {

                e.clearSelection()
                // 释放内存
                clipboard.destroy()
                this.$message.success("Successful replication")
            })
            // 复制失败
            clipboard.on('error', (e) => {
                clipboard.destroy()
                this.$message.error("Replication failure")
            })
        },
        getTableData() {
            const offset = (this.currentPage - 1) * 10
            const param = {limit: 10, offset}
            this.tableLoading = true
            getInviteData(param, (res) => {

                this.tableData = res.data.data
                this.total = res.data.total
                this.tableLoading = false
            })
        },
    }
}
</script>

<style scoped lang="scss">
.box-card {
  :deep(.el-card) {
    border-radius: 18px;
  }

  :deep(.el-form) {
    font-family: "Pang";
  }

  :deep(thead) {
    font-family: "Pang";
  }

  :deep(.el-form-item__label) {
    font-size: 20px;
    color: #fff;
  }

  :deep(.el-input__inner) {
    background-color: transparent;
    border: 3px solid #fff;
    color: #fff;
  }

  :deep(.el-form-item) {
    margin-bottom: 0;
  }


  .demo-form-inline {
    :deep(.el-button) {
      font-family: "Pang";
      background-color: #d6227a;
      color: #fff;
      border-radius: 10px;
    }
  }

  :deep(.table_header) {
    background-color: #f1408c !important;
    color: #fff;
  }

  :deep(.table_header:first-child) {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  :deep(.table_header:nth-child(3)) {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  :deep(.el-pagination.is-background .el-pager li:not(.disabled).active) {
    background-color: #f1408c;
  }


}


.Tip {
  text-align: center;
  font-family: "pang";
  font-size: 14px;
}
</style>