import {axios} from '@/utils/request'

export const handle = (flag, msg, sucFn, errorFn) => {
    axios({
        method: flag,
        url: `/api/products/product_tags/${msg.id}/`,
        data: msg
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })

}
export const getList = (msg, sucFn, errorFn) => {
    axios({
        method: 'get',
        url: '/api/products/product_tags/',
        params: msg
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })

}
export const handleData = (sucFn, errorFn) => {
    axios({
        method: 'get',
        url: '/api/products/product_tags/sync_tags/',
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })

}






