<template>
    <div>
        <el-card class="box-card" shadow="never">
            <el-card style="background-color: #febac3;margin-bottom: 20px;" shadow="never">
                <el-form :inline="true" :model="formInline" class="demo-form-inline">
                    <el-form-item>
                        <el-input v-model="formInline.search" @change="onSubmit"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button @click="onSubmit">search</el-button>
                    </el-form-item>
                </el-form>
            </el-card>
            <el-table :data="tableData" style="width: 100%" header-cell-class-name="table_header"
                      v-loading="tableLoading" stripe>
                <el-table-column prop="username" label="Name" align="center" width="130" sortable>
                </el-table-column>
                <el-table-column prop="email" label="Email" align="center" width="130" sortable>
                </el-table-column>
                <el-table-column label="Baned" align="center" sortable>
                    <template v-slot="{ row }">
                        <div>
                            <el-tag v-if="!row.is_active" type="success">YES</el-tag>
                            <el-tag v-else type="danger">NO</el-tag>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="reward_points" label="Coins" align="center" sortable>
                </el-table-column>
                <el-table-column prop="discord_name" label="Discord" align="center" sortable>
                </el-table-column>
                <el-table-column label="Admin" align="center">
                    <template v-slot="{ row }">
                        <div>
                            <el-tag v-if="row.is_superuser" type="success">YES</el-tag>
                            <el-tag v-else type="danger">NO</el-tag>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="level" label="Level" align="center" sortable>
                </el-table-column>
                <el-table-column prop="last_location" label="IP Loc." align="center" sortable>
                    <template v-slot="{ row }">
                        <el-tooltip class="item" effect="dark" :content="row.last_login_ip" placement="top">
                            <a :href="'https://iplocation.io/ip/' + row.last_login_ip"
                               target="_blank">{{ row.last_location }}</a>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column label="Operation" align="center" width="130px" class="sticky-col" fixed>
                    <template v-slot="{ row, $index }">
                        <el-button v-if="!row.is_active" type="text" @click="unBan(row)">UnBan</el-button>
                        <el-button v-else type="text" @click="Ban(row)">Ban</el-button>
                        <el-button type="text" @click="editUser(row)">Edit</el-button>
                        <el-button type="text" @click="deleteUser(row, $index)">Delete</el-button>
                        <el-button type="text"
                                   @click="$router.push({ name: 'Orders', params: { username: row.username } })">Order
                        </el-button>
                    </template>
                </el-table-column>
                <el-table-column prop="created_at" label="Create" align="center" width="120">
                    <template v-slot="{ row }">
                        <el-tooltip class="item" effect="dark" :content="row.created_at" placement="top-start"
                                    :content-style="{ 'max-height': '60px' }">
                            <div class="ellipsis">{{ row.created_at.substr(0, 10) }}</div>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column prop="last_login" label="Last Login" align="center" width="120">
                    <template v-slot="{ row }">
                        <el-tooltip class="item" effect="dark" :content="row.created_at" placement="top-start"
                                    :content-style="{ 'max-height': '60px' }">
                            <div class="ellipsis">{{ row.created_at.substr(0, 10) }}</div>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
            <div>Total Users: {{ total }}</div>
            <el-pagination background layout="->,prev, pager, next" :total="total" style="margin-top: 20px;"
                           :current-page.sync="currentPage" @current-change="handleCurrentChange">
            </el-pagination>

        </el-card>

        <el-dialog title="UserInfo" :visible.sync="dialogFormVisible" center width="45%">
            <div style="width: 75%;margin: 0 auto;">
                <el-form :model="form" label-width="120px" :rules="rules" ref="ruleForm">
                    <el-form-item label="Username :">
                        <el-tag>{{ form.username }}</el-tag>
                    </el-form-item>
                    <el-form-item label="Email :">
                        <el-tag>{{ form.email }}</el-tag>
                    </el-form-item>
                    <el-form-item label="discord name :">
                        <el-tag>{{ form.discord_name }}</el-tag>
                    </el-form-item>
                    <el-form-item label="invite :">
                        <el-tag>{{ form.invite_count }}</el-tag>
                    </el-form-item>
                    <el-form-item label="Level :">
                        <el-tag>{{ form.level }}</el-tag>
                    </el-form-item>
                    <el-form-item label="Administer :">
                        <el-switch v-model="form.is_superuser" active-color="#13ce66" inactive-color="#ff4949">
                        </el-switch>
                    </el-form-item>
                    <el-form-item label="Coins :" prop="reward_points">
                        <el-input-number v-model="form.reward_points" controls-position="right" :min="0"
                                         :max="100000000"
                                         style="width: 50%;"></el-input-number>
                    </el-form-item>
                    <el-form-item label="password :">
                        <el-input v-model="password" style="width:50%" show-password @focus="handlePassword">
                        </el-input>
                        <el-button style="margin-left: 10px;" @click="resetPassword" :loading="resetpasswordLoading">
                            Reset
                        </el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false" :disabled="submitLoading">cancel</el-button>
                <el-button type="primary" @click="dialogOnSubmit" :loading="submitLoading">confirm</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {getUserList, banUser, unbanUser, deleteUserApi, resetPasswordApi, updataUserApi} from "@/api/dashboard/userList"

export default {
    data() {
        return {
            msg: "userList",
            formInline: {
                search: '',
            },
            tableData: [],
            currentPage: 1,
            total: 0,
            tableLoading: false,
            dialogFormVisible: false,
            form: {
                username: "4564564",
                email: "1323123#qwe123",
                discord_name: "asdasdasda"
            },
            password: "",
            rules: {
                reward_points: [
                    {required: true, message: 'Please enter points', trigger: 'blur'},

                ],
            },
            submitLoading: false,
            resetpasswordLoading: false,
        }
    },
    mounted() {
        new this.$wow.WOW().init()
        this.getTableData()
    },
    methods: {
        handlePassword() {
            if (this.password == "qazmkoiuytr") this.password = ""
        },

        onSubmit() {
            this.currentPage = 1
            this.getTableData()
        },
        handleCurrentChange(val) {
            this.getTableData()
        },
        getTableData() {
            const offset = (this.currentPage - 1) * 10
            const param = {search: this.formInline.search, limit: 10, offset}
            this.tableLoading = true
            getUserList(param, (res) => {

                this.tableData = res.data.data
                this.total = res.data.total
                this.tableLoading = false
            })
        },

        // 禁用用户]
        Ban(row) {
            this.$confirm(`UserName: ${row.username}`, 'Ban User ?', {
                confirmButtonText: 'YES',
                cancelButtonText: 'NO',
                type: 'warning'
            }).then(() => {
                banUser({id: row.id}, (res) => {
                    this.getTableData()
                })
            }).catch(() => {

            })
        },
        // 用户解除禁用
        unBan(row) {
            this.$confirm(`UserName: ${row.username}`, 'UnBan User ?', {
                confirmButtonText: 'YES',
                cancelButtonText: 'NO',
                type: 'warning'
            }).then(() => {
                unbanUser({id: row.id}, (res) => {
                    this.getTableData()
                })
            }).catch(() => {

            })
        },
        // 删除用户
        deleteUser(row, index) {
            this.$confirm(`UserName: ${row.username}`, 'Delete User ?', {
                confirmButtonText: 'YES',
                cancelButtonText: 'NO',
                type: 'warning'
            }).then(() => {
                deleteUserApi({id: row.id}, (res) => {
                    this.currentPage = this.currentPage === 1 ? this.currentPage : (this.tableData.length == 1 && index === 0) ? this.currentPage - 1 : this.currentPage
                    this.getTableData()
                })
            }).catch(() => {

            })
        },
        // 修改用户
        editUser(row) {
            this.dialogFormVisible = true
            this.form = row
            this.password = ""
        },
        //修改密码
        resetPassword() {

            if (!this.password) return this.$message.warning("Please enter password")

            this.$confirm(`RESET PASSWORD`, 'Confirm password change', {
                confirmButtonText: 'YES',
                cancelButtonText: 'NO',
                type: 'warning'
            }).then(() => {
                this.resetpasswordLoading = true
                resetPasswordApi({id: this.form.id, password: this.password}, (res) => {
                    this.resetpasswordLoading = false
                    this.$message.success("success")
                })
            }).catch(() => {

            })
        },

        // dialog保存
        dialogOnSubmit() {
            this.$refs['ruleForm'].validate((valid) => {
                if (valid) {
                    this.submitLoading = true
                    updataUserApi(this.form, (res) => {
                        this.submitLoading = false
                        this.dialogFormVisible = false
                        this.getTableData()
                    })
                }
            })

        }

    }
}
</script>

<style scoped lang="scss">
.box-card {
  :deep(.el-card) {
    border-radius: 18px;
  }

  :deep(.el-form) {
    font-family: "Pang";
  }

  :deep(.el-table) {
    font-family: "Pang";
  }

  :deep(.el-form-item__label) {
    font-size: 20px;
    color: #fff;
  }

  :deep(.el-input__inner) {
    background-color: transparent;
    border: 3px solid #fff;
    color: #fff;
  }

  :deep(.el-form-item) {
    margin-bottom: 0;
  }


  .demo-form-inline {
    :deep(.el-button) {
      font-family: "Pang";
      background-color: #d6227a;
      color: #fff;
      border-radius: 10px;
    }
  }

  :deep(.table_header) {
    background-color: #f1408c !important;
    color: #fff;
  }

  :deep(.table_header:first-child) {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  :deep(.table_header:nth-child(12)) {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  :deep(.el-pagination.is-background .el-pager li:not(.disabled).active) {
    background-color: #f1408c;
  }
}
</style>