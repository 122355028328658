<template>
    <div>
        <el-card class="box-card" id="a">
            <div class="cardHeader" style="margin-bottom: 10px;">
                <h2>Account Information:</h2>
            </div>
            <div>
                <el-form>
                    <el-form-item label="User Name :" class="formItem">
                        <el-tag type="success"> {{ $store.state.userInfo.username }}</el-tag>
                    </el-form-item>
                    <el-form-item label="E-mail Address :" class="formItem">
                        <el-tag type="success"> {{ $store.state.userInfo.email }}</el-tag>
                    </el-form-item>
                </el-form>
            </div>
        </el-card>
        <el-card class="box-card" id="b">
            <div class="cardHeader" style="margin-bottom: 10px;">
                <h2>Change your password?</h2>
                <el-button class="cardbutton" @click="showEditPassword = !showEditPassword">{{
                    showEditPassword ? "No" : "Yes"
                    }}
                </el-button>
            </div>
            <div v-show="showEditPassword">
                <el-form :model="editPasswordForm" label-width="180px" ref="editPasswordRef" :rules="rules">
                    <el-form-item label="Old Password" class="formItem" prop="old_password" style="margin: 5px 0 20px;">
                        <el-input show-password v-model="editPasswordForm.old_password"
                                  placeholder=" Please Enter Old Password"></el-input>
                    </el-form-item>
                    <el-form-item label="New Password" class="formItem" prop="new_password" style="margin: 5px 0 20px;">
                        <el-input show-password v-model="editPasswordForm.new_password"
                                  placeholder=" Please Enter New Password"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="handleEditPassword">Edit</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-card>
        <el-card class="box-card" id="c">
            <div class="cardHeader">
                <h2  v-if="discord_id">Already authorized!</h2>
                <h2 v-else>Authorize your Discord Account Here!</h2>
                <el-button v-if="discord_id" class="authcardbutton" @click="$message.warning('Already authorized, cannot reauthorize!')">
                    <el-tooltip content="Already authorized, cannot reauthorize" placement="top" :open-delay="500">
                        <span>{{ $store.state.userInfo.discord_name }}</span>
                    </el-tooltip>
                </el-button>

                <el-button v-else class="cardbutton" @click="submitThirdLogin">Authorize</el-button>

            </div>

        </el-card>
        <el-card class="box-card" id="d">
            <div class="cardHeader">
                <h2>Manage your billings!</h2>
                <el-button class="cardbutton" @click="$router.push({ name: 'Proxies' })">Manage</el-button>
            </div>

        </el-card>
    </div>
</template>

<script>
import {userChangePassword} from "@/api/dashboard/userList"
import {
    thirdLogin
} from "@/api/login"
import {userPointers} from "@/api/dashboard/giftUser";

export default {
    data() {
        return {
            discord_id: null,
            showEditPassword: false,
            editPasswordForm: {
                old_password: "",
                new_password: ""
            },
            rules: {
                old_password: [
                    {required: true, message: 'Please Enter Old Password', trigger: 'blur'},
                ],
                new_password: [
                    {required: true, message: 'Please Enter New Password', trigger: 'blur'},
                ]
            },
            thirdLoginUrl: null,
        }
    },
    created() {
        if (window.location.href.indexOf('discord-bind-success=1') > -1) {
            this.$message.success(`Discord bind successfully, Name: ${this.$store.state.userInfo.discord_name}`);
        }
        if (window.location.href.indexOf('discord-bind-error=1') > -1) {
            this.$message.warning(`Discord bind failed,the discord account has been bound to other account`);
        }

    },
    mounted() {
        this.getThirdLoginUrl()
        this.refreshUserInfo()
        this.discord_id = this.$store.state.userInfo.discord_id
    },
    methods: {
        refreshUserInfo() {
            userPointers(
                (res) => {
                    this.$store.commit("setUserInfo", res.data)
                },
                (err) => {
                    // this.$message.warning(err.msg);
                }
            );
        },
        submitThirdLogin() {
            // this.loading = true;
            if (!this.$store.state.userInfo || this.$store.state.userInfo.discord_id) {
                return
            }
            if (!this.thirdLoginUrl.length) {
                this.$message.warning('Unable to obtain third-party login path!')
                return
            }
            const a = document.createElement("a")
            a.href = this.thirdLoginUrl
            a.target = "_top"
            document.body.appendChild(a)
            a.click()
            a.remove()
        },
        getThirdLoginUrl() {
            const param = {mode: "bind"}
            thirdLogin(
                (res) => {
                    // ;
                    this.thirdLoginUrl = res.data.discord_auth_url
                },
                (err) => {
                    // this.$message.warning(err.msg);
                },
                param
            )

        },
        handleEditPassword() {
            this.$refs["editPasswordRef"].validate((valid) => {
                if (valid) {
                    userChangePassword(this.editPasswordForm, res => {

                    }, res => {

                    })
                } else {
                    return false
                }
            })
        }
    },

}
</script>

<style lang="scss" scoped>
.box-card {
  margin-bottom: 20px;

  .cardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      font-weight: bold;
      font-family: Pang;
      font-style: italic;
      font-size: 26px;
    }

    .cardbutton {
      width: 140px;
      border-radius: 10px;
      text-align: center;
      background: #f34087;
      font-size: 20px;
      color: #fff;
      font-weight: bold;
      transition: all 0.3s;
      font-family: Pang;
    }

    .cardbutton:hover {
      background: #f34087;
      color: #fff;
      box-shadow: 0 0 10px #f34087, 0 0 40px #f34087, 0 0 80px #f34087;
      transition-delay: 0.3s;
    }

    .authcardbutton {
      width: 140px;
      border-radius: 10px;
      text-align: center;
      background: #7289da;
      font-size: 20px;
      color: #fff;
      font-weight: bold;
      transition: all 0.3s;
      font-family: 'Pangolin', cursive;
    }


  }

  .formItem {
    margin: 5px 0px;

    :deep(.el-form-item__label) {
      font-size: 20px;
    }

    :deep(.el-form-item__content) {
      font-size: 20px;
    }
  }
}
</style>