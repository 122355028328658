<template>
    <div style="background: #FDDDDD;">
        <!-- <div class="load">
        <img src="@/assets/images/mentos.gif" alt="">
      </div> -->
        <div class="layout">
            <header-com :index="3" :scrollTop="scrollTop"></header-com>
            <div class="qa_search wow fadeInUph" data-wow-delay="0.2s" data-wow-duration="1s">
                <img src="@/assets/images/qa_icon.png" alt="">
                <input type="text" placeholder="Search for what you need..." v-model="searchValue"
                       @keyup.enter="handleQuestion"/>
            </div>
            <div class="qa_container wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="1s">

                <div class="qa_list_box" v-loading="loading"
                     style="min-height: 300px; display: flex;justify-content: center;align-items: center;"
                     element-loading-background="rgba(0, 0, 0, 0.9)">
                    <div v-if=" questionTableData.length">
                        <div class="qa_list_item" v-for="item in questionTableData" :key="item.id">
                            <h2 class="qa-list-question">Question: {{ item.question }}</h2>
                            <div class="qa_list_title">
                                <span>Answer: {{ item.answer }}</span>
                                <img src="@/assets/images/qa_icon.png" alt="">
                            </div>
                        </div>

                    </div>
                    <div v-else-if="searchValue && questionTableData.length == 0">
                        <div style="font-size: 20px;">Have not found the answer</div>
                    </div>
                    <div v-else-if="questionTableData.length == 0 && searchValue == ''">
                        <div style="font-size: 20px;">Please enter the question</div>
                    </div>
                </div>
                <div class="qa_type_box">
                    <div class="qa_type_item">
                        <div class="img-container">
                            <img src="@/assets/images/qa_icon2.png" alt="">
                        </div>
                        <span class="qa_title_1" @click="toOpen(socialUrl.discord)">Join our Discord</span>
                        <span class="qa_title_2">Join our Discord</span>
                    </div>
                </div>

                <div class="qa_form">
                    <div class="qa_form_title">Submit a ticket here:</div>
                    <div class="form_short_box">
                        <div class="form_short form_input_box">
                            <span>Name</span>
                            <input placeholder="please enter" v-model="form.username"/>
                        </div>
                        <div class="form_short form_input_box">
                            <span>Phone Number</span>
                            <input placeholder="please enter" v-model="form.phone"/>
                        </div>
                        <div class="form_short form_input_box">
                            <span>E-mail</span>
                            <input placeholder="please enter" v-model="form.email"/>
                        </div>
                    </div>
                    <div class="form_textarea">
                        <span>Message:</span>
                        <textarea v-model="form.message"></textarea>
                    </div>
                    <div class="img-code">

                        <div class="img-code-left">
                            <input type="text" v-model="imgCode" class="text" placeholder="Picture content"/>
                            <img :src="captcha_image_base" alt="">
                            <div @click="getCatchCode" style="cursor:pointer">refresh</div>

                        </div>

                        <div class="submit">
                            <div @click="submit">Submit</div>

                        </div>

                    </div>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
// @ is an alias to /src
import headerCom from '@/components/header'
import {catchVerificationCode, creationJoblist} from '@/api/login'
import {getList} from "@/api/dashboard/QA"
import {getSocialUrl} from "@/api";

export default {
    name: 'proxies',
    components: {
        headerCom
    },
    data() {
        return {
            scrollTop: 0,
            captcha_image_base: '',
            imgCode: '',
            captcha_id: '',
            form: {
                username: '',
                email: '',
                phone: '',
                message: '',
            },
            searchValue: '',
            questionTableData: [],
            loading: false,
            socialUrl: {},
        }
    },
    updated() {
    },
    mounted() {
        new this.$wow.WOW().init()
        window.addEventListener('scroll', this.handleScroll)
        this.getCatchCode()
        this.getSocialUrls()
        this.getDefaultQAList()
    },
    methods: {
        getCatchCode() {
            catchVerificationCode(res => {

                this.captcha_image_base = res.data.captcha_image_base
                this.captcha_id = res.data.captcha_id
            }, (err) => {
                this.$message.warning(err.msg)
            })
        },
        toOpen(url) {
            window.open(url, '_blank')
        },
        submit() {
            if (!this.form.username.length || !this.form.email.length || !this.form.phone.length || !this.form.message.length) {
                this.$message.warning('Please fill out the form first!')
                return
            }
            if (!this.imgCode.length) {
                this.$message.warning('Please input the calculation results in the picture first!')
                return
            }
            let params = {
                username: this.form.username,
                email: this.form.email,
                phone: this.form.phone,
                message: this.form.message,
                captcha: Number(this.imgCode),
                captcha_id: this.captcha_id
            }
            creationJoblist(params, (res) => {

                // this.captcha_image_base = res.data.captcha_image_base
                // this.captcha_id = res.data.captcha_id
                this.$message.success(res.msg)
                this.getCatchCode()
            }, (err) => {
                this.$message.warning(err.msg)
                this.getCatchCode()
            })
        },
        handleScroll() {
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop // 滚动条偏移量
            this.scrollTop = scrollTop
        },
        getDefaultQAList() {
            getList({limit: 5, offset: 0}, (res) => {
                this.questionTableData = res.data.data
            })
        }
        ,
        handleQuestion(e) {
            if (!this.searchValue) return this.$message.warning("Please enter a question")

            const param = {search: this.searchValue, limit: 10, offset: 0}
            this.loading = true
            getList(param, (res) => {
                this.questionTableData = res.data.data
                this.loading = false
            })
        },
        getSocialUrls() {
            getSocialUrl(
                res => {
                    this.socialUrl = res.data
                },
                err => {
                    console.log(err)
                }
            )
        },
    },
    watch: {
        searchValue(val) {
            if (val == "") {
                this.questionTableData = []
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.layout {
  background: url(../assets/images/qa_bg.png) no-repeat;
  background-size: 100%;
}

.qa_search {
  height: 88px;
  background: #FFFFFF;
  border-radius: 16px 16px 16px 16px;
  margin: 0 24px;
  margin-top: 10%;
  margin-bottom: 24px;
}

.qa_search {
  display: flex;
  align-items: center;
}

.qa_search img {
  margin-left: 24px;
  margin-right: 24px;
}

.qa_search input {
  border: 0;
  height: 48px;
  flex: 1;
  margin-block: 24px;
  font-size: 32px;
  font-weight: 300;
  color: #404040
}

.qa_container {
  background: rgba(253, 221, 221, 1);
  padding-bottom: 40px;
}

.qa_type_item {
  width: 383px;
  height: 275px;
  background: #F697A7;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

//.qa_type_item:hover {
//	background: rgba(246, 151, 167, 0.4000);
//}

.qa_type_item img {
  width: 130px;
  margin-top: 40px;
}

.qa_type_item:nth-child(2) {
  margin: 0 40px;
}

.qa_type_box {
  display: flex;
  justify-content: center;
  padding: 40px 0;
}

.qa_type_item:hover .qa_title_1 {
  color: rgba(130, 25, 95, 1)
}

.qa_type_item:hover .qa_title_2 {
  color: rgba(130, 25, 95, 1)
}

.qa_title_1 {
  font-size: 22px;
  font-weight: 400;
  color: #FFFFFF;
  padding-top: 40px;
  font-family: 'pang';
}

.qa_title_2 {
  font-size: 14px;
  font-weight: 600;
  color: #FFFFFF;
  padding-top: 8px;
}

.qa_list_box {
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 24px;
  padding-bottom: 16px;
}

.qa_list_item {
  background: #F697A7;
  padding: 12px 40px;
  border-radius: 16px 16px 16px 16px;
  margin-bottom: 24px
}

.qa_list_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.qa_list_title span {
  font-size: 28px;
  font-weight: 400;
  color: #404040;
  font-family: 'Hk';
}

.qa_list_title img {
  width: 24px;
  height: 24px;
}

.qa_list_text {
  font-size: 24px;
  font-weight: 400;
  color: #404040;
  line-height: 55px;
  font-family: 'Hk';
}

.active_qa_list {
  background: rgba(246, 151, 167, 0.88) !important;
  padding-top: 17px;
  padding-bottom: 17px;
}

.qa_form {
  background: #F697A7;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  margin: 0 24px;
  padding: 24px 34px;
}

.qa_form_title {
  font-family: 'Pang';
  font-size: 32px;
  font-weight: 400;
  color: #404040;
  padding-bottom: 30px;
}

.form_short_box {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.form_short {
  width: 48%;
  padding: 15px 0;
  background: #FCBBC0;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  margin-bottom: 24px;
}

.form_input_box {
  display: flex;
  align-items: center;
}

.form_input_box span {
  font-size: 24px;
  font-family: 'hk';
  font-weight: 600;
  color: rgba(64, 64, 64, 0.64);
  padding-left: 24px;
  padding-right: 40px;
}

.form_input_box input {
  margin-right: 24px;
  flex: 1;
  border: 0;
  font-size: 24px;
  font-family: 'hk';
  font-weight: 600;
  color: #404040;
  background: transparent
}

.form_textarea {
  display: flex;
  flex-direction: column;
}

.form_textarea span {
  font-size: 24px;
  font-family: 'hk';
  font-weight: 600;
  color: rgba(64, 64, 64, 0.64);
  padding-bottom: 16px;
}

.form_textarea textarea {
  font-size: 24px;
  font-family: 'hk';
  font-weight: 600;
  color: #404040;
  background: rgba(252, 187, 192, 1);
  padding: 24px;
  flex: 1;
  border: 0;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
}

.img-code {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;

  .img-code-left {
    width: 30%;
    display: flex;
    justify-content: space-between;

    input {
      outline: none;
      background: #F7F7F7;
      border: none !important;
      line-height: 40px;
      text-align: left;
      text-indent: 10px;
      font-weight: 600;
      font-size: 12px;
      //   border: 1px solid ;


    }

    img {
      width: 80px;
      height: 40px;

    }

    div {
      width: 100px;
      line-height: 34px;
      background: #C76092;
      color: #000;
      font-weight: 600;
      font-size: 12px;
      text-align: center;
      border-radius: 10px;


    }

  }

  .submit {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;

    div {
      cursor: pointer;
      text-align: center;
      width: 100px;
      border-radius: 8px;
      font-size: 14px;
      font-weight: 600;
      line-height: 45px;
      background: rgb(252, 187, 196);
      color: #FFFFFF;
    }
  }
}

.img-container {
  position: relative;
  width: 100px;
  height: 100px;
}

.img-container:hover img {
  transform: rotate(360deg);
  transition: transform 1s ease-in-out;
}

.img-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.qa-list-question {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
}


</style>