<template>
    <div>
        <el-card class="box-card" shadow="never">
            <el-card style="background-color: #febac3;margin-bottom: 20px;" shadow="never">
                <el-form :inline="true" :model="formInline" class="demo-form-inline">
                    <el-form-item>
                        <el-input v-model="formInline.search" @change="onSubmit"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button @click="onSubmit">search</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button @click="handleAdd">Add</el-button>
                    </el-form-item>
                </el-form>
            </el-card>
            <el-table :data="tableData" style="width: 100%" header-cell-class-name="table_header" v-loading="tableLoading">
                <el-table-column prop="id" label="ID" align="center" width="80">
                </el-table-column>
                <el-table-column prop="name" label="group name" align="center">
                </el-table-column>
                <el-table-column label="server" align="center">
                    <template v-slot="{ row }">
                        <template>
                            <ul class="server-list">
                                <li v-for="item in row.servers" :key="item.id">
                                    <el-tooltip :content="item.name" placement="top">
                                        <el-tag  type="info" class="truncate-tag">{{ item.name }}</el-tag>
                                    </el-tooltip>
                                </li>
                            </ul>
                        </template>

                    </template>
                </el-table-column>
                <el-table-column prop="description" label="desc" align="center" show-overflow-tooltip>
                </el-table-column>
                <el-table-column label="Action" align="center" width="200">
                    <template v-slot="{ row, $index }">
                        <el-button type="text" @click="handleEdit(row)">Edit</el-button>
                        <el-button type="text" @click="handleDelete(row, $index)">Delete</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination background layout="->,prev, pager, next" :total="total" style="margin-top: 20px;"
                           :current-page.sync="currentPage" @current-change="handleCurrentChange">
            </el-pagination>
        </el-card>

        <el-dialog :visible.sync="dialogFormVisible" center width="35%" @close="refreshTableData">
            <div style="width: 75%;margin: 0 auto;">
                <el-form :model="form" label-width="120px" :rules="rules" ref="ruleForm" v-loading="formLoading">
                    <el-form-item label="server group" prop="name">
                        <el-input v-model="form.name"></el-input>
                    </el-form-item>

                    <el-form-item label="desc" prop="description">
                        <el-input type="textarea" v-model="form.description" autosize></el-input>
                    </el-form-item>
                    <el-form-item label="server" prop="servers">
                        <el-select v-model="form.servers" multiple style="width: 100%">
                            <el-option v-for="item in serversList" :label="item.name" :value="item.id" :key="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button @click="handleSubmit" type="primary" :loading="EditLoading">Save</el-button>
                        <el-button @click="dialogFormVisible = false" type="danger" :disabled="EditLoading">Cancel</el-button>
                    </el-form-item>

                </el-form>
            </div>

        </el-dialog>
    </div>
</template>

<script>
import { getList, handle, add } from "@/api/dashboard/servergroup"
import { getList as getServeList } from "@/api/dashboard/servers"
export default {
    data() {
        var validateServers = (rule, value, callback) => {
            if (value.length == 0) {
                callback(new Error('Please select server'))
            } else {
                callback()
            }
        }
        return {
            formInline: {
                search: '',
            },
            tableData: [
            ],
            currentPage: 1,
            total: 0,
            tableLoading: false,
            dialogFormVisible: false,
            EditLoading: false,
            // viewInfo: {}
            form: {
                name: '',
                description: "",
                servers: []
            },
            rules: {
                name: [
                    { required: true, message: 'Please enter server group', trigger: 'blur' },
                ],
                description: [
                    { required: true, message: 'Please enter description', trigger: 'blur' },
                ],
                servers: [
                    { validator: validateServers, trigger: 'blur' }
                ]
            },
            handleType: "",
            serversList: [],
            formLoading: false
        }
    },
    mounted() {
        new this.$wow.WOW().init()
        getServeList({ limit: 250, offset: 0 }, res => {
            this.serversList = res.data.data
            this.formLoading = false
        }, res => {
            this.formLoading = false
            this.$message.warning(res.msg)
        })
        this.getTableData()
    },

    methods: {
        refreshTableData() {
            this.getTableData()
        },
        handleError(res) {
            this.$message.warning(res.msg)
            this.EditLoading = false
            this.formLoading = false
        },
        apiCall(method, param, successCallback) {
            if (this.handleType === 'edit') {
                handle(method, param, successCallback, this.handleError)
            } else if (this.handleType === 'add') {
                add(param, successCallback, this.handleError)
            }
        },
        validateForm(callback) {
            this.$refs["ruleForm"].validate((valid) => {
                if (valid) {
                    callback()
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },

        onSubmit() {
            this.currentPage = 1
            this.getTableData()
        },
        handleCurrentChange(val) {
            this.getTableData()
        },
        getTableData() {
            const offset = (this.currentPage - 1) * 10
            const param = { search: this.formInline.search, limit: 10, offset }
            this.tableLoading = true
            getList(param, (res) => {
                this.tableData = res.data.data
                this.total = res.data.total
                this.tableLoading = false
            })
        },
        handleEdit(row) {
            this.handleType = "edit"
            this.dialogFormVisible = true
            this.$nextTick(() => {
                this.form = Object.assign({}, row);
                this.form.servers = row.servers.map(item => item.id);
            })
        },
        handleAdd() {
            this.handleType = "add"
            this.dialogFormVisible = true
        },
        handleSubmit() {
            this.$refs["ruleForm"].validate((valid) => {
                if (valid) {
                    this.EditLoading = true
                    if (this.handleType == "edit") {
                        const param = { ...this.form }
                        this.apiCall("put", param, (res) => {
                            this.dialogFormVisible = false
                            this.EditLoading = false
                            this.$message.success("successfully")
                            this.getTableData()
                        }, (res) => {
                            this.$message.warning(res.msg)
                            this.EditLoading = false
                        })
                    } else {
                        const param = { ...this.form }
                        add(param, (res) => {
                            this.dialogFormVisible = false
                            this.EditLoading = false
                            this.$message.success("successfully")
                            this.getTableData()
                        }, (res) => {
                            this.$message.warning(res.msg)
                            this.EditLoading = false
                        })
                    }
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        handleDelete(row, index) {
            this.$confirm(`Confirm deletion`, 'Delete ?', {
                confirmButtonText: 'YES',
                cancelButtonText: 'NO',
                type: 'warning'
            }).then(() => {
                handle(
                    "delete",
                    { id: row.id },
                    (res) => {
                        this.currentPage = this.currentPage === 1 ? this.currentPage : (this.tableData.length == 1 && index === 0) ? this.currentPage - 1 : this.currentPage
                        this.$message.success("successfully")
                        this.getTableData()
                    },
                )
            }).catch(() => {

            })
        }



    }
}
</script>

<style scoped lang="scss">
.box-card {
  :deep(.el-card) {
    border-radius: 18px;
  }

  :deep(.el-form) {
    font-family: "Pang";
  }

  :deep(thead) {
    font-family: "Pang";
  }

  :deep(.el-form-item__label) {
    font-size: 20px;
    color: #fff;
  }

  :deep(.el-input__inner) {
    background-color: transparent;
    border: 3px solid #fff;
    color: #fff;
  }

  :deep(.el-form-item) {
    margin-bottom: 0;
  }


  .demo-form-inline {
    :deep(.el-button) {
      font-family: "Pang";
      background-color: #d6227a;
      color: #fff;
      border-radius: 10px;
    }
  }

  :deep(.table_header) {
    background-color: #f1408c !important;
    color: #fff;
  }

  :deep(.table_header:first-child) {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  :deep(.table_header:nth-child(6)) {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  :deep(.el-pagination.is-background .el-pager li:not(.disabled).active) {
    background-color: #f1408c;
  }

  .truncate-tag {
    max-width: 100%;  /* Or any other width that you find suitable */
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

}
</style>
