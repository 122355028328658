<template>
    <div>
        <!-- <div class="load">
        <img src="@/assets/images/mentos.gif" alt="">
      </div> -->
        <div class="layout">
            <header-com :index="2" :scrollTop="scrollTop"></header-com>
            <div
                    class="fmentols wrap wow fadeInUp"
                    data-wow-delay="0.3s"
                    data-wow-duration="1s"
            >
                <div class="tlt">
                    <img src="@/assets/images/fico1.png" alt=""/>
                    <h2>MENTOS COUPLE</h2>
                </div>
                <div class="fdd"></div>
                <div class="frewards">
                    <div class="rewa">
                        <a
                                @click="
                $router.push({
                  name: $store.state.userInfo.is_superuser
                    ? 'Orders'
                    : 'Proxies',
                })
              "
                        ><img src="@/assets/images/fico2.png" alt=""
                        /></a>
                    </div>
                    <div class="help">
                        <!-- help -->
                        <a
                                @click="
                $router.push({
                  name: $store.state.userInfo.is_superuser
                    ? 'Orders'
                    : 'Proxies',
                })
              "
                        ><img src="@/assets/images/fico3.png" alt=""
                        /></a>
                        <!-- settings -->
                        <a
                                @click="
                $router.push({
                  name: $store.state.userInfo.is_superuser
                    ? 'Orders'
                    : 'Proxies',
                })
              "
                        ><img src="@/assets/images/fico4.png" alt=""
                        /></a>
                    </div>
                    <div class="proxies">
                        <a
                                @click="
                $router.push({
                  name: $store.state.userInfo.is_superuser
                    ? 'Orders'
                    : 'Proxies',
                })
              "
                        ><img src="@/assets/images/fico5.png" alt=""
                        /></a>
                        <a
                                @click="
                $router.push({
                  name: $store.state.userInfo.is_superuser
                    ? 'Orders'
                    : 'Proxies',
                })
              "
                        ><img src="@/assets/images/fico6.png" alt=""
                        /></a>
                    </div>
                </div>
                <div class="helpspan"></div>
            </div>
        </div>
    </div>
</template>

<script>
import headerCom from "@/components/header";
import {
    userPointers,
    pointersLevel,
    pointsChangeDatas,
    rewardData,
} from "@/api/dashboard/giftUser";

export default {
    name: "login",
    components: {
        headerCom,
    },
    data() {
        return {
            scrollTop: 0,
        };
    },
    created() {
        var url = window.location.hash;
        if (url.indexOf("?") != -1) {
            this.getPointers();
        }
    },
    mounted() {
        new this.$wow.WOW().init();
        window.addEventListener("scroll", this.handleScroll);
    },
    methods: {
        getPointers() {
            userPointers(
                (res) => {
                    this.$store.commit("setUserInfo", res.data)
                },
                (err) => {
                    // this.$message.warning(err.msg);
                }
            );
        },
        toUrlQuery(url, index) {
            this.$router.push({path: url, query: {index: index}});
        },
        toUrl(url) {
            this.$router.push({path: url});
        },
        handleScroll() {
            let scrollTop =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop; // 滚动条偏移量
            this.scrollTop = scrollTop;
        },
    },
};
</script>
<style scoped>
.layout {
    background: url(../assets/images/fbj1.jpg) no-repeat center/cover;
}

.fmentols {
    background: url(../assets/images/fimg1.png) no-repeat center/cover;
}
</style>