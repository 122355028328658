<template>
    <div>
        <el-card class="box-card" shadow="never">
            <div class="button" @click="getData">Synchronize data from Shopify</div>
            <el-table :data="tableData" style="width: 100%" header-cell-class-name="table_header"
                      v-loading="tableLoading">
                <el-table-column prop="collection_name" label="Product Type" align="center">
                </el-table-column>
                <el-table-column prop="collection_desc" label="Desc" align="center">
                </el-table-column>
                <el-table-column label="Action" align="center" width="130">
                    <template v-slot="{ row, $index }">
                        <el-button type="text" @click="handleEdit(row, $index)">Edit</el-button>
                        <el-button type="text" @click="handleDelete(row, $index)">Delete</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination background layout="->,prev, pager, next" :total="total" style="margin-top: 20px;"
                           :current-page.sync="currentPage" @current-change="handleCurrentChange">
            </el-pagination>
        </el-card>
        <el-dialog :visible.sync="dialogFormVisible" center width="35%">
            <div style="width: 75%;margin: 0 auto;">
                <el-form :model="form" label-width="120px" :rules="rules" ref="ruleForm">
                    <el-form-item label="Product Type" prop="collection_name" required>
                        <el-input v-model="form.collection_name"></el-input>
                    </el-form-item>
                    <el-form-item label="Type Desc" prop="collection_desc">
                        <el-input type="textarea" v-model="form.collection_desc"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="handleSubmit" type="primary" :loading="EditLoading">Save</el-button>
                <el-button @click="dialogFormVisible = false" type="danger" :disabled="EditLoading">Cancel</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
import {getList, handleData, handle} from "@/api/dashboard/productType"

export default {
    data() {
        return {
            formInline: {
                search: '',
            },
            tableData: [],
            currentPage: 1,
            total: 0,
            tableLoading: true,
            dialogFormVisible: false,
            EditLoading: false,
            // viewInfo: {}
            form: {
                collection_name: '',
                collection_desc: "",
                id: ""
            },
            rules: {
                mount: [
                    {required: true, message: 'Please enter mount', trigger: 'blur'},
                ],
                code: [
                    {required: true, message: 'Please enter code', trigger: 'blur'},
                ],
                point: [
                    {required: true, message: 'Please enter point', trigger: 'blur'},
                ]
            },
            handleType: ""
        }
    },
    mounted() {
        new this.$wow.WOW().init()
        this.getTableData()
    },
    methods: {
        getData() {
            handleData((res) => {
                this.getTableData()
            })
        },
        handlePassword() {
            if (this.password == "qazmkoiuytr") this.password = ""
        },

        onSubmit() {
            this.currentPage = 1
            this.getTableData()
        },
        handleCurrentChange(val) {

            this.getTableData()
        },
        getTableData() {
            const offset = (this.currentPage - 1) * 10
            const param = {search: this.formInline.search, limit: 10, offset}
            this.tableLoading = true
            getList(param, (res) => {

                this.tableData = res.data.data
                this.total = res.data.total
                this.tableLoading = false
            })
        },
        handleEdit(row) {
            this.handleType = "edit"
            this.dialogFormVisible = true
            this.$nextTick(() => {
                this.$refs["ruleForm"].resetFields()
                this.form = row
            })
        },
        handleAdd() {
            this.handleType = "add"
            this.dialogFormVisible = true
            this.$nextTick(() => {
                this.$refs["ruleForm"].resetFields()
            })
        },
        handleSubmit() {
            this.$refs["ruleForm"].validate((valid) => {
                if (valid) {
                    this.EditLoading = true
                    if (this.handleType == "edit") {
                        handle("put", this.form, (res) => {
                            this.dialogFormVisible = false
                            this.EditLoading = false
                            this.$message.success("successfully")
                            this.getTableData()
                        }, (res) => {
                            this.$message.warning(res.msg)
                            this.EditLoading = false
                        })
                    } else {
                        //
                        add(this.form, (res) => {
                            this.dialogFormVisible = false
                            this.EditLoading = false
                            this.$message.success("successfully")
                            this.getTableData()
                        }, (res) => {
                            this.$message.warning(res.msg)
                            this.EditLoading = false
                        })
                    }
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        handleDelete(row, index) {
            this.$confirm(`Confirm deletion`, 'Delete ?', {
                confirmButtonText: 'YES',
                cancelButtonText: 'NO',
                type: 'warning'
            }).then(() => {
                handle(
                    "delete",
                    {id: row.id},
                    (res) => {
                        this.currentPage = this.currentPage === 1 ? this.currentPage : (this.tableData.length == 1 && index === 0) ? this.currentPage - 1 : this.currentPage
                        this.$message.success("successfully")
                        this.getTableData()
                    },
                )
            }).catch(() => {

            })
        }


    }
}
</script>

<style scoped lang="scss">
.box-card {
  margin-top: 20px;

  .button {
    width: 160px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    // border: 1px solid ;
    font-size: 14px;
    font-weight: 600;
    font-family: "Pang";
    background-color: #d6227a;
    color: #fff;
    border-radius: 10px;
    cursor: pointer;
    margin: 5px 10px 15px 0px;
  }

  :deep(.el-card) {
    border-radius: 18px;
  }

  :deep(.el-form) {
    font-family: "Pang";
  }

  :deep(thead) {
    font-family: "Pang";
  }

  :deep(.el-form-item__label) {
    font-size: 20px;
    color: #fff;
  }

  :deep(.el-input__inner) {
    background-color: transparent;
    border: 3px solid #fff;
    color: #fff;
  }

  :deep(.el-form-item) {
    margin-bottom: 0;
  }


  .demo-form-inline {
    :deep(.el-button) {
      font-family: "Pang";
      background-color: #d6227a;
      color: #fff;
      border-radius: 10px;
    }
  }

  :deep(.table_header) {
    background-color: #f1408c !important;
    color: #fff;
  }

  :deep(.table_header:first-child) {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  :deep(.table_header:nth-child(3)) {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  :deep(.el-pagination.is-background .el-pager li:not(.disabled).active) {
    background-color: #f1408c;
  }


}
</style>