import {axios} from '@/utils/request'


export const getList = (msg, sucFn, errorFn) => {
    axios({
        method: 'get',
        url: '/api/rewards/coupon_code/',
        params: msg
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })

}
export const handle = (method, msg, sucFn, errorFn) => {
    axios({
        method,
        url: `/api/rewards/coupon_code/${msg.id}/`,
        data: msg
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })

}
export const add = (msg, sucFn, errorFn) => {
    axios({
        method: "post",
        url: `/api/rewards/coupon_code/`,
        data: msg
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })

}






