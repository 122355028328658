<template>
    <div>
        <el-card class="box-card" shadow="never">
            <div v-if="!editVisible">
                <el-card style="background-color: #febac3;margin-bottom: 20px;" shadow="never">
                    <el-form :inline="true" :model="formInline" class="demo-form-inline">
                        <el-form-item>
                            <el-input v-model="formInline.search" @change="onSubmit"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button @click="onSubmit">search</el-button>
                        </el-form-item>
                    </el-form>
                    <el-form :inline="true" :model="reset_order" class="demo-form-inline">
                        <el-form-item>
                            <el-input v-model="reset_order.order_ids" placeholder="id1,id2,id3"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button @click="oneKeyReset">one key reset proxy</el-button>
                        </el-form-item>
                    </el-form>
                </el-card>
                <el-table :data="tableData" style="width: 100%" header-cell-class-name="table_header"
                          v-loading="tableLoading" :cell-style="tableRowClassName" show-summary
                >
                    <el-table-column prop="id" label="ID" align="center" width="80" sortable>
                    </el-table-column>
                  <el-table-column prop="old_flag" label="Old" align="center" sortable>
                    <template v-slot="{ row }">
                      <el-tag v-if="row.old_flag === 1" type="danger" style="width: 100%">
                        {{ oldMap[row.old_flag] }}
                      </el-tag>
                      <el-tag v-else type="success" style="width: 100%">{{ oldMap[row.old_flag] }}</el-tag>
                    </template>
                  </el-table-column>
                    <el-table-column prop="shopify_order_number" label="Order No." align="center" width="80" sortable>
                        <template v-slot="{ row }">
                            <a :href="'https://mentosproxy-2.myshopify.com/admin/orders/'+row.shopify_order_id"
                               target="_blank">{{ row.shopify_order_number }}</a>
                        </template>

                    </el-table-column>
                    <el-table-column prop="username" label="User" align="center">
                    </el-table-column>
                    <el-table-column prop="product_name" label="Product" align="center" width="180px">
                        <template v-slot="{ row }">
                            <el-tooltip class="item" effect="dark" :content="row.product_name+'->'+row.variant_name"
                                        placement="top-start"
                                        :content-style="{ 'max-height': '60px' }">
                                <div class="ellipsis">
                                    <el-tag type="success">{{ row.product_name }}->{{ row.variant_name }}</el-tag>
                                </div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column prop="pay_amount" label="Bill" align="center" sortable>
                        <template v-slot="{ row }">
                            <span>${{ row.pay_amount }}</span>
                        </template>
                    </el-table-column>

                    <el-table-column prop="proxy_num" label="Proxy" align="center">
                    </el-table-column>
                    <el-table-column prop="delivery_num" label="Delivery" align="center" sortable>
                        <template v-slot="{ row }">
                            <el-tag v-if="row.delivery_num === 0" type="danger">{{ row.delivery_num }}</el-tag>
                            <el-tag v-else type="success">{{ row.delivery_num }}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="pay_status" label="Paid" align="center" sortable>
                        <template v-slot="{ row }">
                            <el-tag v-if="row.pay_status === 0" type="danger" style="width: 100%">
                                {{ payMap[row.pay_status] }}
                            </el-tag>
                            <el-tag v-else type="success" style="width: 100%">{{ payMap[row.pay_status] }}</el-tag>
                        </template>
                    </el-table-column>

                    <el-table-column label="Operation" align="center" width="100px" fixed>
                        <template v-slot="{ row, $index }">
                            <el-button type="text" @click="editOrder(row)">Edit</el-button>
                            <el-button type="text" @click="deleteOrder(row, $index)">Delete</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column prop="created_at" label="Order Date" align="center" width="120">
                        <template v-slot="{ row }">
                            <el-tooltip class="item" effect="dark" :content="row.created_at" placement="top-start"
                                        :content-style="{ 'max-height': '60px' }">
                                <div class="ellipsis">{{ row.created_at.substr(0, 10) }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column prop="expired_at" label="Expire Date" align="center" width="120">
                        <template v-slot="{ row }">
                            <el-tooltip class="item" effect="dark" :content="row.expired_at" placement="top-start"
                                        :content-style="{ 'max-height': '60px' }">
                                <div class="ellipsis">{{ row.expired_at.substr(0, 10) }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column prop="reset_time" label="Reset Time" align="center" width="120">
                        <template v-slot="{ row }">
                            <el-tooltip class="item" effect="dark" :content="row.reset_time" placement="top-start"
                                        :content-style="{ 'max-height': '60px' }">
                                <div class="ellipsis">{{ row.reset_time }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column prop="order_id" label="Trans ID" align="center" width="180" sortable></el-table-column>
                    <el-table-column prop="fail_reason" label="Reason" align="center" width="180" sortable></el-table-column>
                    <el-table-column prop="acl_selected" label="Acls" align="center" width="180" sortable>
                    </el-table-column>

                </el-table>
                <div>Total Orders: {{ total }}</div>
                <el-pagination background layout="->,prev, pager, next" :total="total" style="margin-top: 20px;"
                               :current-page.sync="currentPage" @current-change="handleCurrentChange">
                </el-pagination>
            </div>

            <div v-else v-loading="editDetailLoading" class="orderDetail">
                <el-page-header @back="goBack" content="Order Details">
                </el-page-header>
                <el-form inline size="small" style="margin:20px 0px;">
                    <el-form-item label="Username" style="width: 45%;margin-bottom: 10px;padding-left: 20px;">
                        <el-tag type="success"> {{ editInfo.order.username }}</el-tag>
                    </el-form-item>
                    <el-form-item label="Product Name" style="width: 45%;margin-bottom: 10px;padding-left: 20px;">
                        <el-tag type="success"> {{ editInfo.order.product_name }}</el-tag>
                    </el-form-item>
                    <el-form-item style="width: 45%;margin-bottom: 10px;padding-left: 20px;">
                        <el-button type="success" @click="resetPasswordMethod"
                                   :loading="resetpasswordLoading">Reset Password
                        </el-button>
                    </el-form-item>
                    <el-form-item style="width: 45%;margin-bottom: 10px;">
                        <el-date-picker v-model="form.expired_at" type="datetime"
                                        placeholder="Please select the expire time"
                                        value-format="timestamp">
                        </el-date-picker>
                        <el-button style="margin-left: 10px;" type="primary" @click="editExpiredTime"
                                   :loading="resetExpired_at">Extend Expire Time
                        </el-button>
                    </el-form-item>
                    <el-form-item style="width: 45%;margin-bottom: 10px;padding-left: 20px;">
                        <el-button type="success" @click="resetChangeProxy" :loading="ChangeProxyLoading">Change Proxy
                        </el-button>
                    </el-form-item>
                    <el-form-item style="width: 45%;margin-bottom: 10px;padding-left: 20px;">
                        <el-button type="success" @click="checkProxy">Check Proxy
                        </el-button>
                    </el-form-item>
                    <el-form-item style="width: 45%;margin-bottom: 10px;padding-left: 20px;">
                        <el-button type="success" @click="deveryOrderAction">Delivery
                        </el-button>
                    </el-form-item>
                </el-form>
                <el-button data-clipboard-action="copy"
                           :data-clipboard-text="editInfo.proxy_list.map(item => `${item.ip}:${item.port}:${item.username}:${item.password}`).join('\n')"
                           @click="handleCpoy" id="btn"
                           style="font-family: 'Pang';background-color: #d6227a;color: #fff; border-radius: 10px;">
                    copy
                </el-button>
                <el-table :data="editInfo.proxy_list" style="width: 100%" header-cell-class-name="tableDetail_header"
                          show-summary>
                    <el-table-column type="index" align="center" width="80" label="Index"></el-table-column>
                    <el-table-column prop="ip" label="Ip" align="center">
                    </el-table-column>
                    <el-table-column prop="port" label="Port" align="center">
                    </el-table-column>
                    <el-table-column prop="username" label="Username" align="center">
                    </el-table-column>
                    <el-table-column prop="password" label="Password" align="center">
                    </el-table-column>
                    <el-table-column prop="created_at" label="Create Time" align="center" width="120">
                        <template v-slot="{ row }">
                            <el-tooltip class="item" effect="dark" :content="row.created_at" placement="top-start"
                                        :content-style="{ 'max-height': '60px' }">
                                <div class="ellipsis">{{ row.created_at.substr(0, 10) }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column prop="expired_at" label="Expire Time" align="center" width="120">
                        <template v-slot="{ row }">
                            <el-tooltip class="item" effect="dark" :content="row.expired_at" placement="top-start"
                                        :content-style="{ 'max-height': '60px' }">
                                <div class="ellipsis">{{ row.expired_at.substr(0, 10) }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column prop="updated_at" label="Update Time" align="center" width="120">
                        <template v-slot="{ row }">
                            <el-tooltip class="item" effect="dark" :content="row.updated_at" placement="top-start"
                                        :content-style="{ 'max-height': '60px' }">
                                <div class="ellipsis">{{ timeDifference(row.updated_at) }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column prop="status" label="Status" align="center" width="100" sortable>
                        <template v-slot="{ row }">
                            <el-tag v-if="row.status" type="success" style="width: 100%">Available</el-tag>
                            <el-tag v-else type="danger" style="width: 100%">Unavailable</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="bing_delay" label="bing(ms)" align="center" width="100" sortable>
                  </el-table-column>

                  <el-table-column prop="google_delay" label="google(ms)" align="center" width="100" sortable>
                  </el-table-column>

                  <el-table-column prop="amazon_delay" label="amazon(ms)" align="center" width="100" sortable>
                  </el-table-column>
                </el-table>
            </div>
        </el-card>
    </div>
</template>

<script>
import {
    getOrdersList,
    deleteOrder,
    getOrdersDetail,
    resetPassword,
    editTime,
    changeProxy, oneKeyResetProxy, checkProxyFn,deveryOrder
} from "@/api/dashboard/orders"
import ClipboardJS from "clipboard";

export default {
    data() {
        return {
            payMap: {
                0: 'Unpaid',
                1: 'Paid'
            },
            oldMap: {
                0: 'New',
                1: 'Old'
            },
            formInline: {
                search: ''
            },
            reset_order: {
                "order_ids": "",
            },
            tableData: [],
            currentPage: 1,
            total: 0,
            tableLoading: false,
            editVisible: false,
            form: {
                expired_at: ""
            },
            editInfo: {
                order: {
                    username: "",
                    product_name: ""
                },
                proxy_list: []
            },
            editId: null,
            resetpasswordLoading: false,
            resetExpired_at: false,
            ChangeProxyLoading: false,
        }
    },
    mounted() {
        new this.$wow.WOW().init()
        const {params} = this.$route
        params.username && (this.formInline.search = params.username)
        this.getTableData()
    },
    methods: {
        tableRowClassName({row}) {
            return row.delivery_num === 0 && row.pay_status === 1 ? 'background-color: red; color:white;' : ''
        },
        onSubmit() {
            this.currentPage = 1
            this.getTableData()
        },
        oneKeyReset() {
            this.$confirm('Are you sure to reset these orders?', 'Reset These Orders', {
                confirmButtonText: 'YES',
                cancelButtonText: 'NO',
                type: 'warning'
            }).then(() => {
                oneKeyResetProxy(this.reset_order, (res) => {
                    this.$message.success(res.msg)
                }, (res) => {
                    this.$message.warning(res.msg)
                })

            }).catch(() => {
            });
        },
        timeDifference(previous) {
            // 获取当前时间戳 utc时间
            const currentUTCTime = new Date().getTime();
            previous = new Date(previous + 'Z').getTime();
            const msPerMinute = 60 * 1000;
            const elapsed = currentUTCTime - previous;

            if (elapsed < msPerMinute) {
                return Math.round(elapsed/1000) + ' seconds ago';
            }

            else if (elapsed < msPerMinute * 60) {
                return Math.round(elapsed/msPerMinute) + ' minutes ago';
            }

            else if (elapsed < msPerMinute * 60 * 24) {
                return Math.round(elapsed/(msPerMinute * 60)) + ' hours ago';
            }

            else if (elapsed < msPerMinute * 60 * 24 * 30) {
                return Math.round(elapsed/(msPerMinute * 60 * 24)) + ' days ago';
            }

            else if (elapsed < msPerMinute * 60 * 24 * 30 * 12) {
                return Math.round(elapsed/(msPerMinute * 60 * 24 * 30)) + ' months ago';
            }

            else {
                return Math.round(elapsed/(msPerMinute * 60 * 24 * 30 * 12)) + ' years ago';
            }
        },
        handleCurrentChange(val) {
            this.getTableData()
        },
        getTableData() {
            const offset = (this.currentPage - 1) * 10
            const param = {search: this.formInline.search, limit: 10, offset}
            this.tableLoading = true
            getOrdersList(param, (res) => {
                this.tableData = res.data.data
                this.total = res.data.total
                this.tableLoading = false
            }, (res) => {
                this.tableLoading = false
                this.tableData = []
                this.total = 0
                this.$message.warning(res.msg)
            })
        },


        // 删除用户
        deleteOrder(row, index) {
            this.$confirm(`UserName: ${row.username}`, 'Delete Order ?', {
                confirmButtonText: 'YES',
                cancelButtonText: 'NO',
                type: 'warning'
            }).then(() => {
                deleteOrder({id: row.id}, (res) => {
                    this.currentPage = this.currentPage === 1 ? this.currentPage : (this.tableData.length == 1 && index === 0) ? this.currentPage - 1 : this.currentPage
                    this.getTableData()
                })
            }).catch(() => {

            })
        },
        // 修改用户
        editOrder(row) {
            this.editVisible = !this.editVisible
            this.editDetailLoading = true
            this.editId = row.id
            getOrdersDetail({id: row.id}, (res) => {
                this.editDetailLoading = false
                this.$set(this, 'editInfo', res.data)
            }, (res) => {
                this.editVisible = !this.editVisible
                this.$message.warning(res.msg)
            })
        },
        //修改密码
        resetPassword() {

            if (!this.password) return this.$message.warning("Please enter password")

            this.$confirm(`RESET PASSWORD`, 'Confirm password change', {
                confirmButtonText: 'YES',
                cancelButtonText: 'NO',
                type: 'warning'
            }).then(() => {
                this.resetpasswordLoading = true
                resetPasswordApi({id: this.form.id, password: this.password}, (res) => {
                    this.resetpasswordLoading = false
                    this.$message.success("success")
                })
            }).catch(() => {

            })
        },
        goBack() {
            this.editVisible = !this.editVisible
            this.getTableData()
            this.form.expired_at = ""
            this.$set(this, "editInfo", {
                order: {
                    username: "",
                    product_name: ""
                },
                proxy_list: []
            })
        },
        resetPasswordMethod() {
            this.resetpasswordLoading = !this.resetpasswordLoading
            resetPassword({id: this.editId}, res => {
                this.resetpasswordLoading = !this.resetpasswordLoading
                this.$message.success("Successful operation")
            }, (res) => {
                this.resetpasswordLoading = !this.resetpasswordLoading
                this.$message.error(res.msg)
            })
        },
        editExpiredTime() {
            if (!this.form.expired_at) return this.$message.warning("Please select the expire time")
            this.resetExpired_at = !this.resetExpired_at
            editTime({id: this.editId, expired_at: this.form.expired_at}, res => {
                this.resetExpired_at = !this.resetExpired_at
                this.$message.success("Successful operation")
            }, (res) => {
                this.resetExpired_at = !this.resetExpired_at
                this.$message.error(res.msg)
            })
        },
        resetChangeProxy() {
            this.ChangeProxyLoading = !this.ChangeProxyLoading
            changeProxy({id: this.editId}, res => {
                this.ChangeProxyLoading = !this.ChangeProxyLoading
                this.$message.success(res.msg)
            }, (res) => {
                this.ChangeProxyLoading = !this.ChangeProxyLoading
                this.$message.error(res.msg)
            })
        },
        checkProxy() {
            checkProxyFn({id: this.editId}, res => {
                    this.$message.success(res.msg)
                }, (res) => {
                    this.$message.error(res.msg)
                }
            )
        },
        deveryOrderAction() {
            deveryOrder({id: this.editId}, res => {
                    this.$message.success(res.msg)
                }, (res) => {
                    this.$message.error(res.msg)
                }
            )
        },
        handleCpoy() {
            const clipboard = new ClipboardJS('#btn')
            clipboard.on('success', (e) => {

                e.clearSelection()
                // 释放内存
                clipboard.destroy()
                this.$message.success("Successful replication")
            })
            // 复制失败
            clipboard.on('error', (e) => {
                clipboard.destroy()
                this.$message.error("Replication failure")
            })
        },

    },
    watch: {
        $route: {
            handler() {
                const {params} = this.$route
                params.username && (this.formInline.search = params.username)
                this.getTableData()
            },
            deep: true
        }
    }

}

</script>

<style scoped lang="scss">
.box-card {
  :deep(.el-card) {
    border-radius: 18px;
  }


  :deep(thead) {
    font-family: "Pang";
  }


  :deep(.el-form-item) {
    margin-bottom: 0;
  }


  .demo-form-inline {
    :deep(.el-button) {
      font-family: "Pang";
      background-color: #d6227a;
      color: #fff;
      border-radius: 10px;
    }
  }

  :deep(.table_header) {
    background-color: #f1408c !important;
    color: #fff;
  }

  :deep(.table_header:first-child) {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  :deep(.table_header:nth-child(15)) {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  :deep(.el-pagination.is-background .el-pager li:not(.disabled).active) {
    background-color: #f1408c;
  }

  :deep(.tableDetail_header:nth-child(12)) {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  :deep(.tableDetail_header) {
    background-color: #f1408c !important;
    color: #fff;
  }

  .unpaid {
    color: red;
  }

  :deep(.tableDetail_header:first-child) {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}
</style>