<template>
    <div class="page-login" v-loading="loading">
        <!-- <div class="load">
          <img src="@/assets/images/mentos.gif" alt="">
        </div> -->
        <div class="layout">
            <header-com :index="-1" :scrollTop="scrollTop"></header-com>
            <div class="flogin">
                <div class="wrap">
                    <div class="fpitl flog wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="1s">
                        <h3>Log in！</h3>
                        <i></i>
                    </div>
                    <div v-if="isLogin" class="floginvia wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1s">
                        <div class="ficon animate__animated animate__rubberBand">
                            <img src="@/assets/images/fico19.png" alt=""/>
                        </div>
                        <div class="teitl">
                            <h2 :class="isEmail ? 'on' : ''" @click="onEmail">
                                Login via Email
                            </h2>
                            <h2 :class="!isEmail ? 'on' : ''" @click="onAccount">
                                Login via Discord
                            </h2>
                        </div>
                        <div>
                            <!-- <el-button type="primary">主要按钮</el-button> -->
                        </div>
                        <div v-show="isEmail" class="femail">
                            <div class="femil">
                                <form action="" method="post">
                                    <div class="list">
                                        <ul>
                                            <li>
                                                <label>Email/Name</label>
                                                <el-input v-model="email" placeholder="Enter your email"></el-input>
                                            </li>
                                            <li>
                                                <label>Password</label>
                                                <el-input v-model="password" show-password placeholder="password"
                                                          type="password"></el-input>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="img-code">
                                        <el-input v-model="imgCode" placeholder="Picture content"
                                                  style="width: 150px;"></el-input>
                                        <img :src="captcha_image_base" alt=""/>
                                        <div @click="getCatchCode" style="cursor: pointer">
                                            refresh
                                        </div>
                                    </div>
                                    <div class="forget">
                                        <span @click="forgetPasswordShow">Forget password</span>
                                    </div>
                                    <div @click="submit" class="sub" v-loading="loginLoading">login</div>

                                    <router-link to="/createAccount" class="fcreate"><i>Create an account</i>
                                    </router-link>
                                </form>
                            </div>
                        </div>
                        <div v-show="!isEmail" class="femail">
                            <div class="third-login">
                                <div class="pic animate__animated animate__jello">
                                    <img src="../assets/images/fico20.png" alt="pic">
                                </div>
                                <div class="submit" @click="submitThirdLogin">login</div>
                            </div>
                        </div>
                    </div>

                    <!-- 忘记密码部分 -->
                    <div v-if="forgetFlag" class="floginvia wow fadeInUp forget-bottom" data-wow-delay="0.3s"
                         data-wow-duration="1s">
                        <div class="ficon">
                            <img src="@/assets/images/fico19.png" alt=""/>
                        </div>
                        <div class="teitl">
                            <h2 class="" style="width: 100%; font-size: 22px; color: #404040">
                                Password reset
                            </h2>
                        </div>
                        <div style="text-align: center; font-weight: 600; font-size: 12px">
                            Please enter your email.We' ll send you a code to your email.
                        </div>
                        <div class="femail">
                            <div class="form-box">
                                <el-form ref="form" :model="form" label-width="100px">
                                    <el-form-item label="email">
                                        <el-input v-model="form.email" placeholder="email"></el-input>
                                    </el-form-item>
                                    <el-form-item label="code">
                                        <el-input v-model="form.email_code" placeholder="code"></el-input>
                                    </el-form-item>
                                </el-form>
                                <div class="send-code" @click="getCode">send code</div>
                            </div>
                            <div @click="subForget" class="subForget">Next</div>
                            <div style="padding-top: 40px"></div>
                        </div>
                    </div>

                    <!-- 重置密码部分 -->
                    <div v-if="resetFlag" class="floginvia wow fadeInUp forget-bottom" data-wow-delay="0.3s"
                         data-wow-duration="1s">
                        <div class="ficon">
                            <img src="@/assets/images/fico19.png" alt=""/>
                        </div>
                        <div class="teitl">
                            <h2 class="" style="width: 100%; font-size: 22px; color: #404040">
                                Password reset
                            </h2>
                        </div>
                        <div style="text-align: center; font-weight: 600; font-size: 12px">
                            Please enter your new password to reset it.
                        </div>
                        <div class="femail">
                            <div class="form-box">
                                <el-form ref="form-reset" :model="formReset" label-width="140px">
                                    <el-form-item label="new Password" style="margin-bottom: 50px" :rules="[
{ required: true, message: 'Enter a password', trigger: 'blur' },
{ pattern: /(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^a-zA-Z0-9]).{8,20}/, message: 'Password must contain at least one lowercase letter, one uppercase letter, one number, one special character and length should be 8 to 20', trigger: 'blur' }]"
                                                  required>
                                        <el-input v-model="formReset.password" placeholder="password"
                                                  show-password></el-input>
                                    </el-form-item>
                                    <el-form-item label="re-Password">
                                        <el-input v-model="formReset.newPassword" placeholder="newPassword"
                                                  show-password></el-input>
                                    </el-form-item>
                                </el-form>
                            </div>
                            <div @click="resetPaaword" class="subForget">confirm</div>
                            <div style="padding-top: 40px"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import headerCom from "@/components/header"
import Cookies from "js-cookie"
import {
    login,
    sendCodeForget,
    loginOut,
    verifyMailbox,
    resetPaasord,
    catchVerificationCode,
    thirdLogin
} from "@/api/login"
// import { login } from "../api/login";

export default {
    name: "login",
    components: {
        headerCom,
    },
    data() {
        return {
            scrollTop: 0,
            password: "",
            email: "",
            imgCode: "",
            name: "",
            isEmail: 1,
            isLogin: true,
            resetFlag: false,
            forgetFlag: false,
            email_code_id: null,
            verify_id: null,
            captcha_image_base: "",
            form: {
                email: "",
                email_code: "",
            },
            formReset: {
                password: "",
                newPassword: "",
            },
            thirdLoginUrl: '',
            loading: false,
            loginLoading: false,
        }
    },
    updated() {
    },
    created() {
        this.getThirdLoginUrl()
        this.getCatchCode()
        // 退出登录
        this.layOut()
    },
    mounted() {
        new this.$wow.WOW().init()
        window.addEventListener("scroll", this.handleScroll)

    },
    methods: {
        layOut() {
            let that = this
            loginOut(
                (res) => {
                    that.$store.commit("setUserInfo", null)
                },
                (err) => {
                    this.$message.warning(err.msg)
                }
            )
        },
        submitThirdLogin() {
            this.loading = true
            if (!this.thirdLoginUrl.length) {
                this.$message.warning('Unable to obtain third-party login path!')
                return
            }
            // window.open(this.thirdLoginUrl, "_self")
            const a = document.createElement("a")
            a.href = this.thirdLoginUrl
            a.target = "_top"
            document.body.appendChild(a)
            a.click()
            a.remove()
        },
        getThirdLoginUrl() {
            const param = {mode: "login"}
            thirdLogin(
                (res) => {
                    // ;
                    this.thirdLoginUrl = res.data.discord_auth_url
                },
                (err) => {
                    // this.$message.warning(err.msg);
                },
                param
            )

        },
        reSend() {
            this.getCatchCode()
        },
        resetPaaword() {
            if (
                this.formReset.password.length &&
                this.formReset.password === this.formReset.newPassword
            ) {
                let params = {
                    email: this.form.email,
                    password: this.formReset.password,
                    verify_id: this.verify_id,
                }
                resetPaasord(
                    params,
                    (res) => {
                        this.verify_id = null
                        this.email_code_id = null
                        this.resetFlag = false
                        this.isLogin = true
                        this.forgetFlag = false
                        this.form.email = ""
                        this.form.email_code = ""
                        this.formReset.password = ""
                        this.formReset.newPassword = ""
                    },
                    (err) => {
                        this.$message.warning(err.msg)
                    }
                )
            } else if (
                this.formReset.password.length == 0 ||
                this.formReset.newPassword.length == 0
            ) {
                this.$message.warning("Please enter your password!")
                return
            } else if (this.formReset.password !== this.formReset.newPassword) {
                this.$message.warning("The two passwords are inconsistent!")
                return
            }
        },
        subForget() {
            if (this.form.email.length == 0 || this.form.email_code.length == 0) {
                this.$message.warning("Please enter your email or code!")
                return
            }
            let params = {
                email: this.form.email,
                email_code: this.form.email_code,
                email_code_id: this.email_code_id,
            }
            verifyMailbox(
                params,
                (res) => {

                    this.verify_id = res.data.verify_id
                    this.resetFlag = true
                    this.forgetFlag = false
                },
                (err) => {
                    this.$message.warning(err.msg)
                }
            )
        },
        getCode() {
            let params = {
                email: this.form.email,
                check_user: 1,
                type: "forgot",
            }
            if (!params.email || params.email.length == 0) {
                this.$message.warning("Please enter your email address!")
            }
            sendCodeForget(
                params,
                (res) => {
                    this.email_code_id = res.data.email_code_id
                    this.$message.success("Sending succeeded")
                },
                (err) => {
                    this.$message.warning(err.msg)
                }
            )
        },
        forgetPasswordShow() {
            loginOut(
                (res) => {

                    this.isLogin = false
                    this.forgetFlag = true
                },
                (err) => {
                    this.$message.warning(err.msg)
                }
            )
        },
        // forgetPasswordShow() {

        // },
        toUrl() {
            this.$router.push({path: "/createAccount"})
        },
        handleScroll() {
            let scrollTop =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop // 滚动条偏移量
            this.scrollTop = scrollTop
        },
        onAccount() {
            this.isEmail = false
            this.email = ""
        },
        onEmail() {
            this.isEmail = 1
            this.name = ""
        },
        getCatchCode() {
            catchVerificationCode(
                (res) => {

                    this.captcha_image_base = res.data.captcha_image_base
                    this.captcha_id = res.data.captcha_id
                },
                (err) => {
                    this.$message.warning(err.msg)
                }
            )
        },

        submit() {
            if (this.name.length == 0 && this.email.length == 0) {
                this.$message.warning("Please enter your name or email!")
                return
            }
            if (this.password.length == 0) {
                this.$message.warning("Please enter your password!")
                return
            }
            if (this.imgCode.length == 0) {
                this.$message.warning("Please enter a picture result!")
                return
            }
            this.submitSend()
        },
        submitSend() {
            let params = {
                username: this.name.length ? this.name : this.email,
                password: this.password,
                captcha_id: this.captcha_id,
                captcha: this.imgCode,
            }
            this.loginLoading = true
            login(
                params,
                (res) => {

                    this.loginLoading = false
                    this.$store.commit("setUserInfo", res.data)
                    // this.$message.success("Login succeeded")
                    this.$router.push({path: "/product"})
                    // this.$router.push({path:'/dashboard'})
                },
                (err) => {
                    console.log(err)
                    this.loginLoading = false
                    this.$message.warning(err.msg)
                    this.getCatchCode()
                }
            )
        },
    },
}
</script>
<!-- <style scoped >

</style> -->
<style lang="scss" scoped>
.layout {
  background: url(../assets/images/fbj1.jpg) no-repeat center/cover;
}

.floginvia {
  background: url(../assets/images/fimg5.jpg) no-repeat center/cover;
}

.forget-bottom {
  width: 540px !important;
}

.forget {
  text-align: right;

  span {
    cursor: pointer;
  }
}

.sub {
  text-align: center;
  line-height: 48px;
}

.page-login {
  .femil {
    padding: 20px;

    @include themeify {
      background-color: themed("bg-color");
    }
  }
}

.form-box {
  position: relative;
  display: flex;
  justify-content: space-between;

  .el-form {
    width: 380px;
  }

  .send-code {
    margin-top: 5px;
    margin-left: 20px;
    background: #c76092;
    color: #000;
    height: 30px;
    line-height: 30px;
    border-radius: 8px;
    text-align: center;
    padding: 0px 10px;
    cursor: pointer;
  }
}

.img-code {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;

  input {
    outline: none;
    background: #f7f7f7;
    border: none !important;
    line-height: 40px;
    text-align: left;
    text-indent: 10px;
    font-weight: 600;
    font-size: 12px;
    //   border: 1px solid ;
  }

  img {
    width: 80px;
    height: 40px;
  }

  div {
    width: 100px;
    line-height: 34px;
    background: #c76092;
    color: #000;
    font-weight: 600;
    font-size: 12px;
    text-align: center;
    border-radius: 10px;
  }
}

.subForget {
  margin: 0 auto;
  width: 200px;
  text-align: center;
  background: #c76092;
  color: #000;
  margin-top: 20px;
  height: 50px;
  font-size: 14px;
  line-height: 50px;
  cursor: pointer;
  border-radius: 20px;
}

.fcreate {
  cursor: pointer;
}

.femail {
  .third-login {
    .pic {

      padding: 32px 0 38px;
      margin: 0 auto;
      height: 147px;

      img {
        display: block;
        height: 100%;
        margin: 0 auto;
      }
    }

    .submit {
      margin: 20px auto 8px;
      display: block;
      width: calc(100% - 20px);
      border: none;
      background: #f34087;
      height: 48px;
      text-align: center;
      line-height: 48px;
      font-size: 20px;
      color: #fff;
      border-radius: 24px;
      overflow: hidden;
      cursor: pointer;
      font-family: 'Pang';
      transition: all 0.3s;
    }

  }
}
</style>