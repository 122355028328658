<template>
    <div class="page-login">
        <!-- <div class="load">
        <img src="@/assets/images/mentos.gif" alt="">
        </div> -->
        <div class="layout">
            <header-com :index="-1" :scrollTop="scrollTop"></header-com>
            <div class="flogin">
                <div class="wrap">
                    <div class="fpitl flog wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="1s">
                        <h3>Log in！</h3>

                    </div>
                    <div class="floginvia wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1s">
                        <div class="ficon">
                            <img src="@/assets/images/fico19.png" alt="" />
                        </div>
                        <div class="femail">

                            <div class="form-box">
                                <el-form ref="form" :model="form" label-width="160px">
                                    <el-form-item label="Username" style="padding-top: 20px; padding-bottom: 20px;"
                                        prop="username" :rules="[
                { required: true, message: 'Please input username', trigger: 'blur' },
                { min: 4, max: 10, message: 'Length should be 4 to 10', trigger: 'blur' },
                { pattern: /^[a-zA-Z0-9]{4,16}$/, message: 'Can only contain letters and numbers', trigger: 'blur' }
            ]" required label-tooltip="Can only contain letters and numbers">
                                        <el-input v-model="form.username" placeholder="username"></el-input>
                                    </el-form-item>
                                    <el-form-item label="Email" prop="email" :rules="[
                { required: true, message: 'Please input email', trigger: 'blur' },
                { type: 'email', message: 'Please input correct email format', trigger: 'blur' }
            ]" required>
                                        <el-input v-model="form.email" placeholder="email"></el-input>
                                    </el-form-item>
                                    <el-form-item label="Code" prop="email_code" :rules="[
                { required: true, message: 'Please input code', trigger: 'blur' },
            ]" required>
                                        <el-input v-model="form.email_code" placeholder="Verification code"></el-input>
                                    </el-form-item>
                                    <el-form-item label="Password" prop="password" style="margin-bottom: 50px"
                                        :rules="[
                { required: true, message: 'Enter a password', trigger: 'blur' },
                { pattern: /(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^a-zA-Z0-9]).{8,20}/, message: 'Password must contain at least one lowercase letter, one uppercase letter, one number, one special character and length should be 8 to 20', trigger: 'blur' }]"
                                        required>
                                        <el-input v-model="form.password" show-password></el-input>
                                    </el-form-item>
                                    <el-form-item label="Confirm Password" prop="repassword" :rules="[
                { required: true, message: 'Please input re-password', trigger: 'blur' },
                { validator: checkPassword, trigger: 'blur' }
            ]">
                                        <el-input v-model="form.repassword" show-password></el-input>
                                    </el-form-item>
                                    <el-form-item label="Invite code">
                                        <el-input v-model="form.invite_code"></el-input>
                                    </el-form-item>

                                </el-form>
                                <div class="send-code" @click="getCode" v-if="!countDown">
                                    send code
                                </div>
                                <div class="send-code" v-else>
                                    {{ countDown }}s
                                </div>
                            </div>
                            <div class="operate">
                                <div class="sign_up_btn" @click="onSubmit">sign up</div>
                            </div>
                            <!-- <span class="hint-text">Already have an account?</span>
                            <div @click="toLogin" class="sign_in_btn">sign in</div> -->
                            <div class="femil">
                                <form action="" method="post">
                                    <div class="fimgs">
                                        <img src="@/assets/images/fico20.png" alt="" />
                                    </div>
                                    <input @click="submit()" type="submit" class="sub" value="login" />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import headerCom from "@/components/header"
import { createAccount, sendCode, login } from '@/api/login'

export default {
    name: "login",
    components: {
        headerCom,
    },
    data() {
        return {
            scrollTop: 0,
            form: {
                username: "",
                password: "",
                email: "",
                invite_code: "",
                email_code: '',
                discord_id: null,
                email_code_id: null
            },
            countDown: 0,
            timer: null
        }
    },
    updated() {
    },
    created() {

        if (this.$route.query.invite_code) {
            this.form.invite_code = this.$route.query.invite_code
        }
        if (this.$route.query.discord_id) {
            this.form.discord_id = this.$route.query.discord_id
        }
    },
    mounted() {
        new this.$wow.WOW().init()
        window.addEventListener("scroll", this.handleScroll)
    },
    methods: {
        checkPassword(rule, value, callback) {
            if (value !== this.form.password) {
                callback(new Error('Password not consistent'));
            } else {
                callback();
            }
        },
        onSubmit() {
            let keys = Object.keys(this.form).filter(item => item != 'invite_code' && item != 'discord_id')

            let flag = keys.every(ele => !!this.form[ele])
            if (!flag) {
                this.$message.warning('Please complete the form first!')
                return
            }
            let params = this.form
            createAccount(params, (res) => {
                this.$message.success('Created successfully')
                this.$router.push({ path: '/login' })
            }, (err) => {
                this.$message.warning(err.msg)
                this.countDown = 0
            })
        },
        getCode() {
            //   login({"username": "yang",
            // "password": "123",
            // "code": "1234",
            // "captcha_id":"121313",
            // "captcha":"aadv"},(res) => {
            //     this.$message.success('Login succeeded')
            // this.$router.push({path: '/product'});
            //               }, (err) => { this.$message.warning(err.msg); })
            let params = {
                email: this.form.email,
                type: 'register',
            }
            if (!params.email || params.email.length == 0) {
                this.$message.warning('Please enter your email address!')
            }
            sendCode(params, (res) => {
                this.form.email_code_id = res.data.email_code_id
                this.$message.success('Sending succeeded')
                this.countDown = 600
                this.timer = setInterval(() => {
                    if (this.countDown > 0) {
                        this.countDown--
                    } else {
                        clearInterval(this.timer)
                        this.timer = null
                    }
                }, 1000)
            }, (err) => {
                this.$message.warning(err.msg)
            })

        },

        handleScroll() {
            let scrollTop =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop // 滚动条偏移量
            this.scrollTop = scrollTop
        },
        onAccount() {
            this.isEmail = false
        },
        toLogin() {
            this.$router.push({ path: '/login' })
        }
    },
    beforeDestroy() {
        if (this.timer) {
            clearInterval(this.timer)
        }
    }
}
</script>
<!-- <style scoped >

</style> -->
<style lang="scss" scoped>
.layout {
    background: url(../assets/images/fbj1.jpg) no-repeat center/cover;
}

.floginvia {
    background: url(../assets/images/fimg5.jpg) no-repeat center/cover;
}

.page-login {
    ::v-deep .el-form-item__label {
        text-align: left;
        font-weight: 600;
    }

    ::v-deep .floginvia {
        width: 600px;
    }

    .form-box {
        padding: 20px;

        @include themeify {
            background-color: themed("bg-color");
        }
    }

    .sign_in_btn {
        background: #C76092;
        color: #000;
        height: 40px;
        width: 80px;
        font-size: 14px;
        font-weight: 600;
        line-height: 40px;
        border-radius: 12px;
        text-align: center;
        padding: 0px 20px;
        cursor: pointer;
    }

    .operate {
        display: flex;
        justify-content: space-around;

        .sign_up_btn {
            display: flex;
            justify-content: center;
            /* 水平居中 */
            align-items: center;
            background: #C76092;
            color: #000;
            height: 80px;
            width: 150px;
            font-size: 14px;
            font-weight: 600;
            line-height: 40px;
            border-radius: 12px;
            text-align: center;
            padding: 0px 20px;
            cursor: pointer;
        }
    }

    .form-box {
        position: relative;
        display: flex;
        justify-content: flex-start;

        .el-form {
            width: 400px;

        }

        .send-code {
            margin-top: 100px;
            background: #C76092;
            color: #000;
            height: 30px;
            font-size: 14px;
            line-height: 30px;
            border-radius: 8px;
            text-align: center;
            padding: 0px 10px;
            cursor: pointer;
        }
    }
}
</style>