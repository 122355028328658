<template>
    <div>
        <div class="fbanner ftobor wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1s">
            <h2>Banner scroll discount code</h2>
        </div>
        <div class="fmentos ftobor wow fadeInUp Couple_bg" data-wow-delay="0.3s" data-wow-duration="1s">
            <h2>Mentos Couple</h2>
        </div>
        <div class="fyouruo ftobor wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1s">
            <img src="@/assets/images/fimg6.jpg" alt="">
        </div>
        <div class="fkeepitup ftobor wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1s">
            <div class="tl">Keep it up! Earn xx more points before dd/mm/yyyy to get xx status!</div>
            <div class="list">
                <ul>
                    <div class="jdtiao">
                        <div class="jd on"></div>
                        <div class="jd on"></div>
                        <div class="jd"></div>
                        <div class="jd"></div>
                        <div class="jd"></div>
                    </div>
                    <li class="on">
                        <div class="icon"><img src="@/assets/images/fp1.png" alt=""></div>
                        <div class="text">
                            <div class="ic">
                                <img src="@/assets/images/fn15.png" alt="">
                                <img src="@/assets/images/fn15-1.png" alt="">
                            </div>
                            <h4>Menber</h4>
                        </div>
                    </li>
                    <li class="on">
                        <div class="icon"><img src="@/assets/images/fp2.png" alt=""></div>
                        <div class="text">
                            <div class="ic">
                                <img src="@/assets/images/fn15.png" alt="">
                                <img src="@/assets/images/fn15-1.png" alt="">
                            </div>
                            <h4>Silver:5k</h4>
                        </div>
                    </li>
                    <li>
                        <div class="icon"><img src="@/assets/images/fp3.png" alt=""></div>
                        <div class="text">
                            <div class="ic">
                                <img src="@/assets/images/fn15.png" alt="">
                                <img src="@/assets/images/fn15-1.png" alt="">
                            </div>
                            <h4>Gold:10k</h4>
                        </div>
                    </li>
                    <li>
                        <div class="icon"><img src="@/assets/images/fp4.png" alt=""></div>
                        <div class="text">
                            <div class="ic">
                                <img src="@/assets/images/fn15.png" alt="">
                                <img src="@/assets/images/fn15-1.png" alt="">
                            </div>
                            <h4>Platinum:20k</h4>
                        </div>
                    </li>
                    <li>
                        <div class="icon"><img src="@/assets/images/fp5.png" alt=""></div>
                        <div class="text">
                            <div class="ic">
                                <img src="@/assets/images/fn15.png" alt="">
                                <img src="@/assets/images/fn15-1.png" alt="">
                            </div>
                            <h4>Diamond:50k</h4>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="frecent ftobor wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1s">
            <div class="ftite">
                <h2>Recent Point Activity:</h2>
            </div>
            <table class="fretabl" border="0" cellspacing="1" cellpadding="1" width="100%">
                <tr>
                    <th>Date</th>
                    <th>Points Earned</th>
                    <th>Points Earned</th>
                </tr>
                <tr>
                    <td>23/06/22</td>
                    <td>Browse the UO Site or APP</td>
                    <td>+20 points</td>
                </tr>
                <tr>
                    <td>23/06/22</td>
                    <td>Browse the UO Site or APP</td>
                    <td>+20 points</td>
                </tr>
                <tr>
                    <td>23/06/22</td>
                    <td>Browse the UO Site or APP</td>
                    <td>+20 points</td>
                </tr>
                <tr>
                    <td>23/06/22</td>
                    <td>Browse the UO Site or APP</td>
                    <td>+20 points</td>
                </tr>
            </table>
        </div>
        <div class="fpagels clearfix wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1s">
            <ul>
                <li>
                    <div class="prod"></div>
                </li>
                <li class="on"><a href="#">1</a></li>
                <li><a href="#">2</a></li>
                <li>
                    <div class="next"></div>
                </li>
            </ul>
        </div>
        <div class="ftobor fexchange wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1s">
            <div class="ftite">
                <h2>Exchange List：</h2>
                <h3>Your Points: <i>6000</i></h3>
            </div>
            <div class="list">
                <ul>
                    <li><a href="#">
                        <div class="img">
                            <img src="@/assets/images/fimg1.jpg" alt="">
                        </div>
                        <h4>pts</h4>
                    </a></li>
                    <li><a href="#">
                        <div class="img">
                            <img src="@/assets/images/fimg1.jpg" alt="">
                        </div>
                        <h4>pts</h4>
                    </a></li>
                    <li><a href="#">
                        <div class="img">
                            <img src="@/assets/images/fimg1.jpg" alt="">
                        </div>
                        <h4>pts</h4>
                    </a></li>
                    <li><a href="#">
                        <div class="img">
                            <img src="@/assets/images/fimg1.jpg" alt="">
                        </div>
                        <h4>pts</h4>
                    </a></li>
                </ul>
            </div>
        </div>
        <div class="fpagels clearfix wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1s">
            <ul>
                <li>
                    <div class="prod"></div>
                </li>
                <li class="on"><a href="#">1</a></li>
                <li><a href="#">2</a></li>
                <li>
                    <div class="next"></div>
                </li>
            </ul>
        </div>

    </div>
</template>

<script>
export default {
    name: 'proxies',
    components: {},
    data() {
        return {}
    },
    updated() {
    },
    mounted() {
        new this.$wow.WOW().init()
    },
    methods: {}
}
</script>
<style scoped>
</style>