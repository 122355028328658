import {axios} from '@/utils/request'


export const getList = (msg, sucFn, errorFn) => {
    axios({
        method: 'get',
        url: '/api/servers/servers/',
        params: msg
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })

}
export const handle = (method, msg, sucFn, errorFn) => {
    axios({
        method,
        url: `/api/servers/servers/${msg.id}/`,
        data: msg
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })

}
export const add = (msg, sucFn, errorFn) => {
    axios({
        method: "post",
        url: `/api/servers/servers/`,
        data: msg
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })

}
export const createUser = (msg, sucFn, errorFn) => {
    axios({
        method: "post",
        url: `/api/servers/servers/${msg.id}/create_user_by_prefix/`,
        data: msg
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })

}
export const deleteUser = (msg, sucFn, errorFn) => {
    axios({
        method: "post",
        url: `/api/servers/servers/${msg.id}/delete_user/`,
        data: msg
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })

}
export const deleteAllUser = (msg, sucFn, errorFn) => {
    axios({
        method: "post",
        url: `/api/servers/servers/${msg.id}/delete_all_user/`,
        data: msg
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })

}
export const resetAllProxy = (msg, sucFn, errorFn) => {
    axios({
        method: "post",
        url: `/api/servers/servers/${msg.id}/reset_all_proxy/`,
        data: msg
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })

}
export const flushLog = (msg, sucFn, errorFn) => {
    axios({
        method: "post",
        url: `/api/servers/servers/${msg.id}/flush_access_log/`,
        data: msg
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })

}
export const listAcl = (msg, sucFn, errorFn) => {
    axios({
        method: "post",
        url: `/api/servers/servers/${msg.id}/list_acl/`,
        data: msg
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })

}
export const getServerInfo = (msg, sucFn, errorFn) => {
    axios({
        method: 'get',
        url: `/api/servers/servers/${msg.id}/get_server_info/`,
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })

}
export const handApi = (msg, sucFn, errorFn) => {
    axios({
        method: "post",
        url: `/api/servers/servers/${msg.id}/request_api/`,
        data: msg
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })

}







