<template>
    <div>
        <el-card class="box-card" shadow="never" v-if="!Manage">
            <el-card style="background-color: #febac3;margin-bottom: 20px;" shadow="never">
                <el-form :inline="true" :model="formInline" class="demo-form-inline">
                    <el-form-item>
                        <el-input v-model="formInline.search" @change="onSubmit"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button @click="onSubmit">search</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button @click="handleAdd">Add</el-button>
                    </el-form-item>
                </el-form>
            </el-card>
            <el-table :data="tableData" style="width: 100%" header-cell-class-name="table_header"
                      v-loading="tableLoading">
                <el-table-column prop="id" label="ID" align="center">
                </el-table-column>
                <el-table-column prop="name" label="Name" align="center">
                </el-table-column>
                <el-table-column prop="ip" label="ip" align="center">
                </el-table-column>
                <el-table-column prop="description" label="Desc" align="center" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="server_status" label="Connect" align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-tag :type="scope.row.server_status === 1 ? 'success' : 'danger'">
                            {{ scope.row.server_status === 1 ? 'yes' : 'no' }}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="CIDR" align="center">
                    <template v-slot="{ row }">
                        <div class="cidr-tags">
                            <el-tag v-for="(cidr, index) in row.cidrs.slice(0, 5)" :key="cidr.cidr" type="success">
                                {{ cidr.cidr }}
                            </el-tag>
                            <el-dialog :visible.sync="dialogVisible" title="CIDR列表" width="50%">
                                <div class="cidr-tags">
                                    <el-tag v-for="cidr in moreCidrs" :key="cidr.cidr" type="success">{{
                                        cidr.cidr
                                        }}
                                    </el-tag>
                                </div>
                                <div slot="footer" class="dialog-footer">
                                    <el-button @click="dialogVisible = false">关闭</el-button>
                                </div>
                            </el-dialog>
                            <el-tag v-if="row.cidrs.length > 5" @click="showMore(row.cidrs)" type="warning">更多
                            </el-tag>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column label="ip count" align="center">
                    <template v-slot="{ row }">
                        <div>
                            <span>{{ row.cidrs.map(item => item.ip_count).join(';') }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="Action" align="center" width="200">
                    <template v-slot="{ row, $index }">
                        <el-button type="text" @click="handleManage(row)">Manage</el-button>
                        <el-button type="text" @click="handleEdit(row)">Edit</el-button>
                        <el-button type="text" @click="handleDelete(row, $index)">Delete</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination background layout="->,prev, pager, next" :total="total" style="margin-top: 20px;"
                           :current-page.sync="currentPage" @current-change="handleCurrentChange">
            </el-pagination>
        </el-card>
        <el-card v-else>
            <el-page-header @back="goBack" content="Manage" style="margin-bottom: 20px;">
            </el-page-header>
            <el-form ref="manageform" :model="manageform" label-width="100px" label-position="right">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="server name">
                            {{ manageform.name }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="server ip">
                            {{ manageform.ip }}
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="cidr">
                            <el-tag v-for="item in manageform.cidrs" :key="item.cidr">{{ item.cidr }}</el-tag>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="ip count">
                            <el-tag v-for="item in manageform.cidrs" :key="item.cidr">{{ item.ip_count }}</el-tag>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="description">
                            {{ manageform.description }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item>
                            <el-button type="primary" size="mini" @click="handleQueryServerInfo"
                                       :loading="queryLoading">Query Server
                                Info
                            </el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="all User">
                            <json-viewer :value="user_list" :expand-depth=3 copyable expanded boxed sort></json-viewer>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="server Info">
                            <json-viewer :value="server_info" :expand-depth=3 copyable expanded boxed
                                         sort></json-viewer>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20" style="margin-bottom: 20px;">
                    <el-col :span="6">
                        <el-input v-model="handle.username" placeholder="username"></el-input>
                    </el-col>
                    <el-col :span="6">
                        <el-input v-model="handle.prefix" placeholder="prefix"></el-input>
                    </el-col>
                    <el-col :span="6">
                        <el-select v-model="handle.acl_group" placeholder="acl_group">
                            <el-option v-for="item in aclGroupGetList" :label="item.name" :value="item.id"
                                       :key="`acl-${item.id}`"></el-option>
                        </el-select>
                    </el-col>
                    <el-col :span="6">
                        <div class="grid-content bg-purple">
                            <el-button type="success" @click="handleCreate" :loading="createLoading"
                                       style="width: 100%;">create
                            </el-button>
                        </div>
                    </el-col>
                </el-row>
                <el-row :gutter="20" style="margin-bottom: 20px;">
                    <el-col :span="6">
                        <el-input v-model="username" placeholder="username"></el-input>
                    </el-col>
                    <el-col :span="6">
                        <el-button type="danger" @click="handleDeleteUser" :loading="deleteLoading"
                                   style="width: 100%;">Delete
                            User
                        </el-button>
                    </el-col>
                    <el-col :span="6">
                        <el-button type="danger" @click="handleDeleteAllUser" :loading="deleteAllLoading"
                                   style="width: 100%;">Delete All User
                        </el-button>
                    </el-col>

                    <span style="color: #f00;">此处所有手动操作，不会影响库存，谨慎操作</span>
                </el-row>

                <el-row :gutter="20" style="margin-bottom: 20px;">
                    <el-col :span="6">
                        <el-button type="danger" @click="handle_flush_access_log" style="width: 100%;">
                            Flush Access Log
                        </el-button>
                    </el-col>
                    <el-col :span="6">
                        <el-button type="danger" @click="handleExportAcl" style="width: 100%;">
                            Export Acl list
                        </el-button>
                    </el-col>
                </el-row>
                <el-row :gutter="20" style="margin-bottom: 20px;">
                    <el-col :span="6">
                        <el-select v-model="handle.cidrs" placeholder="cidr whitelist" multiple collapse-tags>
                            <el-option v-for="item in manageform.cidrs" :label="item.name" :value="item.cidr"
                                       :key="item.id"></el-option>
                        </el-select>
                    </el-col>
                    <el-col :span="6">
                        <span style="color: #f00;">先删除整段失效的cidr，再勾选需要保留代理的cidr，不在勾选范围内的代理将全部重置，根据订单重置，与产品绑定无关</span>
                    </el-col>
                    <el-col :span="6">
                        <el-button type="danger" @click="handleResetAllProxy" style="width: 100%;">Reset All Proxy
                        </el-button>
                    </el-col>
                </el-row>
                <el-form-item label="input" prop="json_input">
                    <el-input type="textarea" v-model="form.json_input" autosize placeholder='{
    "prefix": "127.0.0.1/30",
    "user": "kaxynetwork",
    "remove_network_addr": true,
    "remove_broadcast_addr": true
}' ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="form.api_uri" placeholder="/api/create-user-by-prefix"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="handleApiAction" :loading="createLoading">Request Api</el-button>
                </el-form-item>
                <el-form-item label="output">
                    <json-viewer :value="out" :expand-depth=3 copyable boxed sort></json-viewer>
                </el-form-item>
            </el-form>
        </el-card>
        <el-dialog :visible.sync="dialogFormVisible" center width="50%">
            <div style="width: 75%;margin: 0 auto;">
                <el-form :model="form" label-width="120px" :rules="rules" ref="ruleForm">
                    <el-form-item label="Server Name" prop="name">
                        <el-input v-model="form.name"></el-input>
                    </el-form-item>
                    <el-form-item label="Server Ip" prop="ip">
                        <el-input v-model="form.ip"></el-input>
                    </el-form-item>
                    <el-form-item label="Cidrs" prop="cidr">
                        <el-input type="textarea" v-model="form.cidr" autosize placeholder="10.10.10.10/24"></el-input>
                    </el-form-item>
                  <el-form-item label="Password" prop="password">
                    <el-input v-model="form.password"></el-input>
                  </el-form-item>
                  <el-form-item label="Port" prop="port">
                    <el-input v-model="form.port"></el-input>
                  </el-form-item>
                  <div style="display: flex; align-items: center;">
                  <el-form-item label="Run Init" prop="run_init">
                        <el-switch v-model="form.run_init" active-value="1" inactive-value="0"></el-switch>
                    </el-form-item>
                  <el-form-item label="Update Cidr" prop="update_cidr">
                        <el-switch v-model="form.update_cidr" active-value="1" inactive-value="0"></el-switch>
                    </el-form-item>
                  </div>
                    <el-form-item label="Desc" prop="description">
                        <el-input type="textarea" v-model="form.description" autosize placeholder="Nice server"></el-input>
                    </el-form-item>

                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="handleSubmit" type="primary" :loading="EditLoading">Save</el-button>
                <el-button @click="dialogFormVisible = false" type="danger" :disabled="EditLoading">Cancel</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    getList,
    handle,
    add,
    getServerInfo,
    createUser,
    deleteUser,
    deleteAllUser,
    resetAllProxy, flushLog, listAcl, handApi
} from "@/api/dashboard/servers"
import {getList as aclGroupGetList} from "@/api/dashboard/AclGroupManager"
import {startsWith} from "lodash/string";

export default {
    data() {
        return {
            dialogVisible: false,
            formInline: {
                search: '',
            },
            tableData: [],
            currentPage: 1,
            total: 0,
            tableLoading: false,
            dialogFormVisible: false,
            EditLoading: false,
            // viewInfo: {}
            form: {
                name: '',
                ip: "",
                cidr: "",
              password: "",
              port: null,
              run_init: 0,
              update_cidr: 0,
                description: "",
                json_input: "",
                api_uri: "",
            },
            rules: {
                name: [
                    {required: true, message: 'Please enter name', trigger: 'blur'},
                ],
                ip: [
                    {required: true, message: 'Please enter ip', trigger: 'blur'},
                ],
                cidr: [
                    {required: true, message: 'Please enter cidr', trigger: 'blur'},
                ],
                description: [
                    {required: false, message: 'Please enter description', trigger: 'blur'},
                ]
            },
            handleType: "",
            Manage: false,
            manageform: {},
            queryLoading: false,
            server_info: {},
            user_list: {},
            handle: {
                username: "",
                prefix: '',
                acl_group: '',
                cidrs: []
            },
            aclGroupGetList: [],
            createLoading: false,
            out: {},
            username: "",
            deleteLoading: false,
            deleteAllLoading: false,
            moreCidrs: [], // 用于存储当前行的CIDR数据
        }
    },
    mounted() {
        new this.$wow.WOW().init()
        this.getTableData()
        aclGroupGetList({limit: 10000, offset: 0}, res => {

            this.aclGroupGetList = res.data.data
            resolve()
        }, res => {
        })
    },
    methods: {
        formatStatus(row, column, cellValue) {
            return cellValue === 1 ? 'yes' : 'no';
        },
        handlePassword() {
            if (this.password == "qazmkoiuytr") this.password = ""
        },

        onSubmit() {
            this.currentPage = 1
            this.getTableData()
        },
        handleCurrentChange(val) {

            this.getTableData()
        },
        getTableData() {
            const offset = (this.currentPage - 1) * 10
            const param = {search: this.formInline.search, limit: 10, offset}
            this.tableLoading = true
            getList(param, (res) => {

                this.tableData = res.data.data
                this.total = res.data.total
                this.tableLoading = false
            })
        },
        handleEdit(row) {
            this.handleType = "edit"
            this.dialogFormVisible = true
            this.$nextTick(() => {
                this.$refs["ruleForm"].resetFields()
                const {name, ip, description, cidrs, id, password, port} = row
                const cidrsStr = cidrs.map(item => item.cidr).join('\n')
                this.form = {name, ip, description, cidr: cidrsStr, id, password, port}
            })
        },
        handleAdd() {
            this.handleType = "add"
            this.dialogFormVisible = true
            this.$nextTick(() => {
                this.$refs["ruleForm"].resetFields()
                delete this.form.id
            })
        },
        handleSubmit() {
            this.$refs["ruleForm"].validate((valid) => {
                if (valid) {
                    this.EditLoading = true
                    if (this.handleType == "edit") {
                        const param = {...this.form}
                        param.cidrs = param.cidr.trim().split('\n').map(item => ({cidr: item}))
                        handle("put", param, (res) => {
                            this.dialogFormVisible = false
                            this.EditLoading = false
                            this.$message.success("successfully")
                            this.getTableData()
                        }, (res) => {
                            this.$message.warning(res.msg)
                            this.EditLoading = false
                        })
                    } else {
                        const param = {...this.form}
                        param.cidrs = param.cidr.trim().split('\n').map(item => ({cidr: item}))
                        add(param, (res) => {
                            this.dialogFormVisible = false
                            this.EditLoading = false
                            this.$message.success("successfully")
                            this.getTableData()
                        }, (res) => {
                            this.$message.warning(res.msg)
                            this.EditLoading = false
                        })
                    }
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        handleDelete(row, index) {
            this.$confirm(`Confirm deletion`, 'Delete ?', {
                confirmButtonText: 'YES',
                cancelButtonText: 'NO',
                type: 'warning'
            }).then(() => {
                handle(
                    "delete",
                    {id: row.id},
                    (res) => {
                        this.currentPage = this.currentPage === 1 ? this.currentPage : (this.tableData.length == 1 && index === 0) ? this.currentPage - 1 : this.currentPage
                        this.$message.success("successfully")
                        this.getTableData()
                    },
                )
            }).catch(() => {

            })
        },
        handleManage(data) {
            this.Manage = true
            this.manageform = data
            console.log(data)
        },
        goBack() {
            this.Manage = false
            this.user_list = {}
            this.server_info = {}
            this.handle = {
                username: "",
                prefix: '',
                acl_group: '',
                cidrs: []
            }
            this.out = {}
            this.username = ""
        },
        handleQueryServerInfo() {
            this.queryLoading = true
            getServerInfo({id: this.manageform.id}, res => {
                    const {server_info, user_list} = res.data
                    this.server_info = server_info
                    this.user_list = user_list
                    this.queryLoading = false
                },
                res => {
                    this.$message.warning(res.msg)
                    this.queryLoading = false
                }
            )
        },
        handleCreate() {
            this.createLoading = true
            this.out = {}
            const param = Object.assign(this.handle, {id: this.manageform.id})
            if (param.username == "") {
                this.$message.warning("please enter a username")
                this.createLoading = false
                return
            }
            if (param.prefix == "") {
                this.$message.warning("please enter a prefix")
                this.createLoading = false
                return
            }
            if (param.acl_group == "") {
                this.$message.warning("please select a acl_group")
                this.createLoading = false
                return
            }
            createUser(param, res => {
                    this.out = res
                    this.createLoading = false
                }
                , res => {
                    this.out = res
                    this.createLoading = false
                })
        },
        handleDeleteAllUser() {
            this.deleteAllLoading = true
            this.out = {}

            deleteAllUser({id: this.manageform.id},
                res => {
                    this.out = res
                    this.deleteAllLoading = false
                },
                res => {
                    this.out = res
                    this.deleteAllLoading = false
                }
            )
        },
        handleResetAllProxy() {
            this.deleteAllLoading = true
            this.out = {}
            resetAllProxy({id: this.manageform.id, cidrs: this.handle.cidrs},
                res => {
                    this.out = res
                    this.deleteAllLoading = false
                },
                res => {
                    this.out = res
                    this.deleteAllLoading = false
                }
            )
        },
        handle_flush_access_log() {
            this.out = {}
            flushLog({id: this.manageform.id},
                res => {
                    this.out = res
                    this.$message.success("successfully")
                },
                res => {
                    this.out = res
                }
            )
        },
        handleExportAcl() {
            this.out = {}
            listAcl({id: this.manageform.id},
                res => {
                    this.out = res
                    this.$message.success("successfully")
                },
                res => {
                    this.out = res
                }
            )
        },

        handleDeleteUser() {
            this.deleteLoading = true
            this.out = {}
            if (this.username == "") {
                this.$message.warning("please enter a username")
                this.deleteLoading = false
                return
            }
            deleteUser({id: this.manageform.id, username: this.username},
                res => {
                    this.out = res
                    this.deleteLoading = false
                },
                res => {
                    this.out = res
                    this.deleteLoading = false
                })
        },
        showMore(cidrs) {
            this.moreCidrs = cidrs; // 将当前行的CIDR数据赋值给moreCidrs变量
            this.dialogVisible = true;
        },
        handleApiAction() {
            this.createLoading = true
            this.out = {}
            const param = Object.assign({id: this.manageform.id,uri:this.form.api_uri,json_input:this.form.json_input})
            if ( !startsWith(param.uri,"/api")) {
                this.$message.warning("please enter a correct uri")
                this.createLoading = false
                return
            }
            if (param.json_input == "") {
                this.$message.warning("please enter a correct json_input")
                this.createLoading = false
                return
            }
            handApi(param, res => {
                    this.out = res
                    this.createLoading = false
                }
                , res => {
                    this.out = res
                    this.createLoading = false
                })
        },


    }
}
</script>

<style scoped lang="scss">
.box-card {
  :deep(.el-card) {
    border-radius: 18px;
  }

  :deep(.el-form) {
    font-family: "Pang";
  }

  :deep(thead) {
    font-family: "Pang";
  }

  :deep(.el-form-item__label) {
    font-size: 20px;
    color: #fff;
  }

  :deep(.el-input__inner) {
    background-color: transparent;
    border: 3px solid #fff;
    color: #fff;
  }

  :deep(.el-form-item) {
    margin-bottom: 0;
  }


  .demo-form-inline {
    :deep(.el-button) {
      font-family: "Pang";
      background-color: #d6227a;
      color: #fff;
      border-radius: 10px;
    }
  }

  :deep(.table_header) {
    background-color: #f1408c !important;
    color: #fff;
  }

  :deep(.table_header:first-child) {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  :deep(.table_header:nth-child(7)) {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  :deep(.el-pagination.is-background .el-pager li:not(.disabled).active) {
    background-color: #f1408c;
  }


  .cidr-tags {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
  }

  //.cidr-tags .el-tag {
  //  margin-right: 10px;
  //  margin-bottom: 10px;
  //  max-width: 20%;
  //}

  @media (max-width: 768px) {
    .cidr-tags .el-tag {
      max-width: 33.33%;
    }
  }


}
</style>