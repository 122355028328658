import {axios} from '@/utils/request'

// 工单列表创建
export const creationJoblist = (msg, sucFn, errorFn) => {
    axios({
        method: 'post',
        url: '/api/tickets/ticket/',
        data: msg
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })
}

// 注册
export const createAccount = (msg, sucFn, errorFn) => {
    axios({
        method: 'post',
        url: '/api/users/u/',
        data: msg
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })
}

// 登出
export const loginOut = (sucFn, errorFn) => {
    axios({
        method: 'get',
        url: '/api/auth/logout'
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })
}

// 获取三方登录url
export const thirdLogin = (sucFn, errorFn, params) => {
    axios({
        method: 'get',
        url: '/api/auth/discord/login',
        params
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })
}


//
// 校验忘记密码验证码
export const verifyMailbox = (msg, sucFn, errorFn) => {
    axios({
        method: 'post',
        url: '/api/users/forgot_password_verify',
        data: msg
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })
}

// 物理验证码

export const catchVerificationCode = (sucFn, errorFn) => {
    axios({
        method: 'get',
        url: '/api/auth/captcha'
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })
}

// 重置密码
export const resetPaasord = (msg, sucFn, errorFn) => {
    axios({
        method: 'post',
        url: '/api/users/forgot_password',
        data: msg
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })
}


// 发送邮箱验证码
export const sendCode = (msg, sucFn, errorFn) => {
    axios({
        method: 'post',
        url: '/api/users/email_validate',
        data: msg
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })
}

// 发送邮箱验证码
export const sendCodeForget = (msg, sucFn, errorFn) => {
    axios({
        method: 'post',
        url: '/api/users/email_validate',
        data: msg
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })
}


// 登录 
export const login = (msg, sucFn, errorFn) => {
    axios({
        method: 'post',
        url: '/api/auth/login',
        data: msg
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })
}