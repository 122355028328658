var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"layout"},[_c('header-com',{attrs:{"index":2,"scrollTop":_vm.scrollTop}}),_c('div',{staticClass:"fmentols wrap wow fadeInUp",attrs:{"data-wow-delay":"0.3s","data-wow-duration":"1s"}},[_vm._m(0),_c('div',{staticClass:"fdd"}),_c('div',{staticClass:"frewards"},[_c('div',{staticClass:"rewa"},[_c('a',{on:{"click":function($event){return _vm.$router.push({
              name: _vm.$store.state.userInfo.is_superuser
                ? 'Orders'
                : 'Proxies',
            })}}},[_c('img',{attrs:{"src":require("@/assets/images/fico2.png"),"alt":""}})])]),_c('div',{staticClass:"help"},[_c('a',{on:{"click":function($event){return _vm.$router.push({
              name: _vm.$store.state.userInfo.is_superuser
                ? 'Orders'
                : 'Proxies',
            })}}},[_c('img',{attrs:{"src":require("@/assets/images/fico3.png"),"alt":""}})]),_c('a',{on:{"click":function($event){return _vm.$router.push({
              name: _vm.$store.state.userInfo.is_superuser
                ? 'Orders'
                : 'Proxies',
            })}}},[_c('img',{attrs:{"src":require("@/assets/images/fico4.png"),"alt":""}})])]),_c('div',{staticClass:"proxies"},[_c('a',{on:{"click":function($event){return _vm.$router.push({
              name: _vm.$store.state.userInfo.is_superuser
                ? 'Orders'
                : 'Proxies',
            })}}},[_c('img',{attrs:{"src":require("@/assets/images/fico5.png"),"alt":""}})]),_c('a',{on:{"click":function($event){return _vm.$router.push({
              name: _vm.$store.state.userInfo.is_superuser
                ? 'Orders'
                : 'Proxies',
            })}}},[_c('img',{attrs:{"src":require("@/assets/images/fico6.png"),"alt":""}})])])]),_c('div',{staticClass:"helpspan"})])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tlt"},[_c('img',{attrs:{"src":require("@/assets/images/fico1.png"),"alt":""}}),_c('h2',[_vm._v("MENTOS COUPLE")])])
}]

export { render, staticRenderFns }