<template>
    <div>
        <el-card class="box-card" shadow="never">

        </el-card>
    </div>
</template>

<script>

export default {
    data() {
        return {}
    },
    mounted() {
        new this.$wow.WOW().init()
    },

}
</script>

<style scoped>
:deep(.el-card) {
    border-radius: 18px;
}
</style>