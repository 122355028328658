<template>
    <div>
        <form action="" method="post">
            <div class="faccount">
                <ul>
                    <li class="ftobor wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1s">
                        <div class="tlt">
                            <h2>Account Information:</h2>
                            <a href="#">Done</a>
                        </div>
                        <div class="datals">
                            <dl>
                                <dd>
                                    <h3><i>User Name</i> <input type="text" class="text" placeholder="Please Enter">
                                    </h3>
                                    <a href="#">Edit</a>
                                </dd>
                                <dd>
                                    <h3><i>E-mail Address</i> <input type="text" class="text"
                                                                     placeholder="Please Enter"></h3>
                                    <a href="#">Edit</a>
                                </dd>
                            </dl>
                        </div>
                    </li>
                    <li class="ftobor wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1s">
                        <div class="tlt">
                            <h2>Change your password?</h2>
                            <a href="#">Done</a>
                        </div>
                        <div class="datals change">
                            <dl>
                                <dd>
                                    <h3><i>Old Password</i><input type="text" class="text" placeholder="Please Enter">
                                    </h3>
                                    <div class="fk"></div>
                                </dd>
                                <dd>
                                    <h3><i>New Password</i><input type="text" class="text" placeholder="Please Enter">
                                    </h3>
                                    <div class="fk"></div>
                                </dd>
                            </dl>
                        </div>
                    </li>
                    <li class="ftobor wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1s">
                        <div class="tlt">
                            <h2>Authorize your Discord Account Here!</h2>
                            <a href="#">Authorize</a>
                        </div>
                    </li>
                    <li class="ftobor wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1s">
                        <div class="tlt">
                            <h2>Manage your billings!</h2>
                            <a href="#">Manage</a>
                        </div>
                    </li>
                    <li class="ftobor wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1s">
                        <div class="tlt en">
                            <h2>Language Preference</h2>
                            <div class="choose">
                                <select name="" class="lsele">
                                    <option value="Choose">Choose</option>
                                    <option value="Choose1">Choose1</option>
                                    <option value="Choose2">Choose2</option>
                                </select>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </form>


    </div>
</template>

<script>
export default {
    name: 'proxies',
    components: {},
    data() {
        return {}
    },
    updated() {
    },
    mounted() {
        new this.$wow.WOW().init()
    },
    methods: {}
}
</script>
<style scoped>

</style>