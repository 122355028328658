<template>
    <div>
        <el-card class="box-card" shadow="never">
            <el-table :data="tableData" style="width: 100%" header-cell-class-name="table_header"
                      v-loading="tableLoading"
                      @row-click="handleClickRow" :row-class-name="rowHighlight">
                <el-table-column prop="id" label=" Order ID" align="center">
                </el-table-column>
                <el-table-column prop="product_name" label="Product" align="center">
                </el-table-column>
                <el-table-column prop="product_quantity" label="Quantity" align="center">
                </el-table-column>

                <el-table-column prop="pay_amount" label="Amount" align="center">
                    <template v-slot="{ row }">
                        <span :class="{ 'unpaid': row.pay_status === 0 }">{{ '$' + row.pay_amount }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="pay_status" label="Paid" align="center">
                    <template v-slot="{ row }">
                        <span :class="{ 'unpaid': row.pay_status === 0 }">{{ payMap[row.pay_status] }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="created_at" label="Order Date" align="center" width="120">
                    <template v-slot="{ row }">
                        <el-tooltip class="item" effect="dark" :content="row.created_at" placement="top-start"
                                    :content-style="{ 'max-height': '60px' }">
                            <div class="ellipsis">{{ row.created_at.substr(0, 10) }}</div>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column prop="expired_at" label="Expiry Date" align="center" width="120">
                    <template v-slot="{ row }">
                        <el-tooltip class="item" effect="dark" :content="row.expired_at" placement="top-start"
                                    :content-style="{ 'max-height': '60px' }">
                            <div class="ellipsis">{{ row.expired_at.substr(0, 10) }}</div>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column label="Action" align="center">
                    <template v-slot="{ row }">
                        <div>
                            <el-button size="mini" type="danger" :disabled="row.pay_status === 0"
                                       @click.stop="handleRenew(row.id)">renew
                            </el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination background layout="->,prev, pager, next" :total="total" style="margin-top: 20px;"
                           :page-size="5"
                           :current-page.sync="currentPage" @current-change="handleCurrentChange">
            </el-pagination>
        </el-card>
        <div style="margin: 20px 0px;"></div>
        <el-card class="box-card1" shadow="never">
            <div slot="header" class="clearfix">
                <div style="display: flex; justify-content: space-between;align-items: center;">
                    <div style="font-family: 'Pang';font-size: 20px;">Proxies</div>
                    <el-button data-clipboard-action="copy"
                               :data-clipboard-text="detailTableData.map(item => `${item.ip}:${item.port}:${item.username}:${item.password}`).join('\n')"
                               @click="handleCpoy" id="btn"
                               style="font-family: 'Pang';background-color: #d6227a;color: #fff; border-radius: 10px;">
                        Copy
                    </el-button>
                    <el-button type="text" @click="checkProxy"
                               style="font-family: 'Pang';background-color: #d6227a;color: #fff; border-radius: 8px;">
                        Check Proxy
                    </el-button>
                </div>
            </div>
            <el-table :data="detailTableData" style="width: 100%" header-cell-class-name="table_header"
                      v-loading="detailTableLoading">
                <el-table-column prop="ip" label="Ip" align="center">
                </el-table-column>
                <el-table-column prop="port" label="Port" align="center">
                </el-table-column>
                <el-table-column prop="username" label="Username" align="center">
                </el-table-column>
                <el-table-column prop="password" label="Password" align="center">
                </el-table-column>

                <el-table-column prop="created_at" label="Create Time" align="center" width="120">
                    <template v-slot="{ row }">
                        <el-tooltip class="item" effect="dark" :content="row.created_at" placement="top-start"
                                    :content-style="{ 'max-height': '60px' }">
                            <div class="ellipsis">{{ row.created_at.substr(0, 10) }}</div>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column prop="expired_at" label="Expire Time" align="center" width="120">
                    <template v-slot="{ row }">
                        <el-tooltip class="item" effect="dark" :content="row.expired_at" placement="top-start"
                                    :content-style="{ 'max-height': '60px' }">
                            <div class="ellipsis">{{ row.expired_at.substr(0, 10) }}</div>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column prop="updated_at" label="Update Time" align="center" width="120">
                    <template v-slot="{ row }">
                        <el-tooltip class="item" effect="dark" :content="row.updated_at" placement="top-start"
                                    :content-style="{ 'max-height': '60px' }">
                            <div class="ellipsis">{{timeDifference(row.updated_at) }}</div>
                        </el-tooltip>
                    </template>
                </el-table-column>

                <el-table-column prop="status" label="status" align="center" width="100" sortable>
                    <template v-slot="{ row }">
                        <el-tag v-if="row.status" type="success" style="width: 100%">Available</el-tag>
                        <el-tag v-else type="danger" style="width: 100%">Unavailable</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="bing_delay" label="bing(ms)" align="center" width="100" sortable>
                  </el-table-column>

                  <el-table-column prop="google_delay" label="google(ms)" align="center" width="100" sortable>
                  </el-table-column>

                  <el-table-column prop="amazon_delay" label="amazon(ms)" align="center" width="100" sortable>
                  </el-table-column>
                <el-table-column label="action" align="center">
                    <template v-slot="{ row }">
                        <div>
                            <el-button type="text" id="btn" data-clipboard-action="copy"
                                       :data-clipboard-text="`${row.ip}:${row.port}:${row.username}:${row.password}`"
                                       @click="handleCpoy">copy
                            </el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>

        </el-card>
    </div>
</template>

<script>

import {getOrdersList, getOrdersDetail, renew, checkProxyFn} from "@/api/dashboard/orders"
import ClipboardJS from 'clipboard'

export default {

    data() {
        return {
            detailTableData: [],
            tableData: [],
            currentPage: 1,
            total: 0,
            tableLoading: false,
            detailTableLoading: false,
            dialogFormVisible: false,
            EditLoading: false,
            product_type: '',
            payMap: {
                0: 'Unpaid',
                1: 'Paid'
            },
            selectedRowId: null

        }
    },
    mounted() {
    },
    methods: {
        handleCurrentChange(val) {
            this.getTableData()
        },
        getTableData() {
            const offset = (this.currentPage - 1) * 5
            const param = {limit: 5, offset}
            this.product_type && (param.product_type = this.product_type)
            this.tableLoading = true
            this.detailTableData = []
            getOrdersList(param, (res) => {
                this.tableData = res.data.data
                this.total = res.data.total
                this.tableLoading = false
                this.tableData.length && this.handleClickRow(this.tableData[0])
            }, (res) => {
                this.tableLoading = false
                this.tableData = []
                this.total = 0
                this.$message.warning(res.msg)
            })
        },
        timeDifference(previous) {
            // 获取当前时间戳 utc时间
            const currentUTCTime = new Date().getTime();
            previous = new Date(previous + 'Z').getTime();
            const msPerMinute = 60 * 1000;
            const elapsed = currentUTCTime - previous;

            if (elapsed < msPerMinute) {
                return Math.round(elapsed/1000) + ' seconds ago';
            }

            else if (elapsed < msPerMinute * 60) {
                return Math.round(elapsed/msPerMinute) + ' minutes ago';
            }

            else if (elapsed < msPerMinute * 60 * 24) {
                return Math.round(elapsed/(msPerMinute * 60)) + ' hours ago';
            }

            else if (elapsed < msPerMinute * 60 * 24 * 30) {
                return Math.round(elapsed/(msPerMinute * 60 * 24)) + ' days ago';
            }

            else if (elapsed < msPerMinute * 60 * 24 * 30 * 12) {
                return Math.round(elapsed/(msPerMinute * 60 * 24 * 30)) + ' months ago';
            }

            else {
                return Math.round(elapsed/(msPerMinute * 60 * 24 * 30 * 12)) + ' years ago';
            }
        },
        rowHighlight({row, rowIndex}) {
            if(row.id === this.selectedRowId) {
                return 'highlight-row';
            }
            return '';
        },
        handleClickRow(row) {
            const {id} = row
            this.selectedRowId = row.id
            this.detailTableData = []
            this.detailTableLoading = true
            getOrdersDetail({id}, res => {
                this.detailTableLoading = false
                this.detailTableData = res.data.proxy_list
            }, res => {
                this.detailTableLoading = false
                this.detailTableData = []
                this.$message.warning(res.msg)
            })
        },
        checkProxy() {
            //获取当前点击的订单id 传给后台 未点击时默认第一个
            const {id} = this.tableData.find(item => item.id === this.selectedRowId) || this.tableData[0]
            checkProxyFn({id}, res => {
                this.$message.success("Check proxy success,please wait a moment...")
            }, res => {
                this.$message.warning(res.msg)
            })

        },
        handleCpoy() {
            const clipboard = new ClipboardJS('#btn')
            clipboard.on('success', (e) => {

                e.clearSelection()
                // 释放内存
                clipboard.destroy()
                this.$message.success("Successful replication")
            })
            // 复制失败
            clipboard.on('error', (e) => {
                clipboard.destroy()
                this.$message.error("Replication failure")
            })
        },
        handleRenew(id) {

            renew({id}, res => {
                const {checkout_url} = res.data
                const a = document.createElement("a")
                a.href = checkout_url
                a.target = "_blank"
                document.body.appendChild(a)
                a.click()
                a.remove()
            }, res => {

                this.$message.warning(res.msg)
            })
        }
    },
    watch: {
        $route: {
            handler() {
                const {id} = this.$route.params
                this.product_type = id
                this.currentPage = 1
                this.getTableData()
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<style lang="scss" scoped>
.box-card {
  :deep(.el-card) {
    border-radius: 18px;
  }

  :deep(.el-form) {
    font-family: "Pang";
  }

  :deep(thead) {
    font-family: "Pang";
  }

  :deep(.el-form-item__label) {
    font-size: 20px;
    color: #fff;
  }

  :deep(.el-input__inner) {
    background-color: transparent;
    border: 3px solid #fff;
    color: #fff;
  }

  :deep(.el-form-item) {
    margin-bottom: 0;
  }


  .demo-form-inline {
    :deep(.el-button) {
      font-family: "Pang";
      background-color: #d6227a;
      color: #fff;
      border-radius: 10px;
    }
  }

  :deep(.table_header) {
    background-color: #f1408c !important;
    color: #fff;
  }

  :deep(.table_header:first-child) {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  :deep(.table_header:nth-child(8)) {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  :deep(.el-pagination.is-background .el-pager li:not(.disabled).active) {
    background-color: #f1408c;
  }


}

.unpaid {
  color: red;
}

.box-card1 {
  :deep(.el-card) {
    border-radius: 18px;
  }

  :deep(.el-form) {
    font-family: "Pang";
  }

  :deep(thead) {
    font-family: "Pang";
  }

  :deep(.el-form-item__label) {
    font-size: 20px;
    color: #fff;
  }

  :deep(.el-input__inner) {
    background-color: transparent;
    border: 3px solid #fff;
    color: #fff;
  }

  :deep(.el-form-item) {
    margin-bottom: 0;
  }


  .demo-form-inline {
    :deep(.el-button) {
      font-family: "Pang";
      background-color: #d6227a;
      color: #fff;
      border-radius: 10px;
    }
  }

  :deep(.table_header) {
    background-color: #f1408c !important;
    color: #fff;
  }

  :deep(.table_header:first-child) {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  :deep(.table_header:nth-child(12)) {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  :deep(.el-pagination.is-background .el-pager li:not(.disabled).active) {
    background-color: #f1408c;
  }


}
.highlight-row {
    background-color: #792dbb; // 或您喜欢的任何颜色
}
</style>