import {axios} from '@/utils/request'

export const getOrdersList = (msg, sucFn, errorFn) => {
    axios({
        method: 'get',
        url: '/api/orders/order_list/',
        params: msg
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })
}


export const deleteOrder = (msg, sucFn, errorFn) => {
    axios({
        method: 'DELETE',
        url: `/api/orders/order_list/${msg.id}/`,
        data: msg
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })

}
export const getOrdersDetail = (msg, sucFn, errorFn) => {
    axios({
        method: 'get',
        url: `/api/orders/order_list/${msg.id}/get_proxy_detail/`,
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })
}
export const resetPassword = (msg, sucFn, errorFn) => {
    axios({
        method: 'POST',
        url: `/api/orders/order_list/${msg.id}/reset_proxy_password/`,
        data: msg
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })

}
export const checkProxyFn = (msg, sucFn, errorFn) => {
    axios({
        method: 'GET',
        url: `/api/orders/order_list/${msg.id}/check_proxy/`,
        data: msg
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })

}
export const changeProxy = (msg, sucFn, errorFn) => {
    axios({
        method: 'POST',
        url: `/api/orders/order_list/${msg.id}/reset_proxy/`,
        data: msg
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })

}
export const deveryOrder = (msg, sucFn, errorFn) => {
    axios({
        method: 'POST',
        url: `/api/orders/order_list/${msg.id}/devery_order/`,
        data: msg
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })

}
export const editTime = (msg, sucFn, errorFn) => {
    axios({
        method: 'POST',
        url: `/api/orders/order_list/${msg.id}/update_proxy_expired_at/`,
        data: msg
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })

}
export const renew = (msg, sucFn, errorFn) => {
    axios({
        method: 'POST',
        url: `/api/orders/order_list/${msg.id}/order-renew-checkout/`,
        data: msg
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })

}

export const getProxiesItem = (sucFn, errorFn) => {
    axios({
        method: 'get',
        url: `/api/products/product_collections/`,
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })

}


export const oneKeyResetProxy = (msg, sucFn, errorFn) => {
    axios({
        method: 'POST',
        url: `/api/orders/order_list/one_key_reset/`,
        data: msg
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })

}