import {axios} from '@/utils/request'

// 用户列表
export const getSettingMethod = (sucFn, errorFn) => {
    axios({
        method: 'get',
        url: '/api/settings/config',
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })

}
export const setSettings = (data, sucFn, errorFn) => {
    axios({
        method: 'POST',
        url: '/api/settings/config',
        data,
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })

}
export const getLogsMethod = (sucFn, errorFn) => {
    axios({
        method: 'get',
        url: '/api/settings/logs',
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })

}
