<template>
    <div class="header" :class="scrollTop > 0 ? 'on' : ''" style="z-index: 999;">
        <div class="wrap clearfix">
            <a class="logo" @click="toUrl('/')"><img src="@/assets/images/logo.png" alt=""/></a>
            <div class="rts clearfix">
                <div class="naver">
                    <ul class="clearfix">
                        <li :class="index == 0 ? 'on' : ''">
                            <h2><a @click="toUrl('/')">Home</a></h2>
                        </li>
                        <li :class="index == 1 ? 'on' : ''">
                            <h2><a @click="toUrl('/product')">Product</a></h2>
                        </li>
                        <!-- <li :class="index == 1 ? 'on' : ''" @mouseenter="onHover(1)" @mouseleave="onHover(0)">
                          <h2 @click="toUrl('/product')">
                            <a>Product<i></i></a>
                          </h2>
                          <div class="nlis" :class="isHover == 1 ? 'hasHover' : 'noHover'" v-if="showHover">
                            <dl>
                              <dd v-for="(item, index) in labelListData" :key="index" @click="toProduct(index)">
                                <a>{{ item.proxyCate.title }}</a>
                              </dd>
                            </dl>
                          </div>
                        </li> -->
                        <li :class="index == 2 ? 'on' : ''" @click="toUrl('/dashboard')">
                            <h2><a>Dashboard</a></h2>
                        </li>
                        <li :class="index == 3 ? 'on' : ''" @click="toUrl('/support')" @mouseenter="onHover2(1)"
                            @mouseleave="onHover2(0)">
                            <h2>
                                <a>Support<i></i></a>
                            </h2>
                            <div class="nlis" :class="isHover2 == 1 ? 'hasHover' : 'noHover'" v-if="showHover2">
                                <dl>
                                    <dd><a>FAQ</a></dd>
                                    <dd><a>Contact</a></dd>
                                </dl>
                            </div>
                        </li>
                    </ul>
                </div>

                <div class="login_box" v-if="!token">
                    <div class="login" @click="toUrl('/login')">
                        <span>Login</span>
                    </div>
                </div>
                <div v-else class="login_box" @mouseleave="showLoginOut = false">
                    <!--  -->
                    <div class="login-pic">
                        <img src="../assets/images/people.png" @mouseover="showLoginOut = true" alt="">
                    </div>
                    <div class="login-out" v-if="showLoginOut">
                        <div class="login-t">
                            <span>{{ userInfo.username }}</span>
                        </div>
                        <div class="line"></div>
                        <div class="login_out_box" @click="submitLoginOut">Logout</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {productList} from "@/api/index"
import {
    loginOut,
} from "@/api/login"

export default {
    props: {
        index: {
            type: Number,
            required: true,
            default: 0,
        },
        scrollTop: {
            type: Number,
            required: false,
            default: 0,
        },
    },
    data() {
        return {
            userInfo: this.$store.state.userInfo,
            isHover: 0,
            showHover: false,
            isHover2: false,
            showHover2: false,
            labelListData: [],
            token: null,
            showLoginOut: false,
        }
    },
    watch: {
        "$store.state.userInfo"(e) {
            this.userInfo = e
            localStorage.setItem("userInfo", JSON.stringify(e))
            this.$store.commit("setUserInfo", e)
            if (this.$store.state.userInfo && Object.keys(this.$store.state.userInfo).length) {
                this.token = true
            } else {
                this.token = false
            }
        },
    },
    created() {
        if (this.$store.state.userInfo && Object.keys(this.$store.state.userInfo).length) {
            this.token = true
        }
    },
    mounted() {
        this.getLabelList()
    },
    methods: {
        submitLoginOut() {
            loginOut(
                (res) => {
                    this.$router.push({name: "home"})
                    this.$store.commit("setUserInfo", null)
                    this.showLoginOut = false
                    this.token = false
                },
                (err) => {
                    this.$message.warning(err.msg)
                }
            )
        },
        toUrl(url) {
            this.$router.replace({path: url})
        },
        onHover(type) {
            var that = this
            this.isHover = type
            if (type == 1) {
                this.showHover = true
            } else {
                setTimeout(function () {
                    that.showHover = false
                }, 500)
            }
        },
        onHover2(type) {
            var that = this
            that.isHover2 = type
            if (type == 1) {
                that.showHover2 = true
            } else {
                setTimeout(function () {
                    that.showHover2 = false
                }, 500)
            }
        },
        // 获取标签列表
        getLabelList() {
            var that = this
            return
            productList(
                {},
                (res) => {
                    this.labelListData = res.result
                },
                (err) => {
                    console.log(err)
                }
            )
        },
        // 跳转product
        toProduct(index) {
            this.$router.replace({path: "/product", query: {index: index}})
        },
        LogoutFunc() {
            localStorage.setItem("userInfo", JSON.stringify({}))
            this.$store.commit("setUserInfo", {})
            localStorage.setItem("token", "")
            window.location.replace(
                "https://discord.com/api/oauth2/authorize?client_id=983333457579094056&redirect_uri=http%3A%2F%2Fdashboard.mentostelecom.com&response_type=code&scope=identify"
            )
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.login_box {
  .login-pic {
    margin-top: 8px;
    width: 200px;

    img {
      width: 30px;
      height: 30px;
    }

  }

  .login-out {
    width: 140px;
    font-size: 18px;
    color: #fff;
    font-weight: bold;
    border-radius: 10px;
    border-top-left-radius: 0px;
    background: #f34087;

    .login-t {
      text-align: center;
      line-height: 38px;
    }

    .line {
      border: 1px solid #fff;
    }

    .login_out_box {
      text-align: center;
      line-height: 38px;

    }
  }
}

// .header .rts .login span {
//         width: 40px;
//         line-height: 50px;
//         font-size: 30px;
//         font-weight: 600;
//         color: #f38cb5;
//         font-weight: bold;
//         border-radius: 10px;
//         // background: #f34087;
//         text-align: center;
//         display:block;
//         white-space:nowrap;
//         overflow:hidden;
//         text-overflow:ellipsis;
//         cursor: pointer;
//         display: block;
//         transition: all 0.3s;
//     }
//     .header .rts .login span:hover {
//         color: #f80667;
//     }
//     .header .rts .login_box {
//         float: right;
//         cursor: pointer;
//         position: relative;
//     }
//     .header .rts .login {
//         .login-list {

//     }
//     }
</style>
