<template>
    <div>
        <!-- <div class="load">
          <img src="@/assets/images/mentos.gif" alt="">
        </div> -->
        <div class="layout">
            <header-com :index="2" :scrollTop="scrollTop"></header-com>
            <div class="fproxies">
                <div class="wrap clearfix">
                    <div class="fproxielt wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="1s">
                        <a class="log"><img src="@/assets/images/logo.png" alt="" @click="toWebUrl('home')"></a>
                        <div class="list">
                            <!-- 用户 -->
                            <el-menu class="el-menu-vertical-demo" text-color="#404040" unique-opened
                                     v-if="!$store.state.userInfo.is_superuser">
                                <!--  -->
                                <el-menu-item index="0" class="hover" @click="$router.push({ name: 'home' })">
                                    <img src="@/assets/images/fn4.png" alt="">
                                    <img src="@/assets/images/fn4-1.png" alt="" style="display: none;"
                                         class="animate__animated animate__flip">
                                    <span slot="title">Home</span>
                                </el-menu-item>
                                <el-submenu index="1">
                                    <template slot="title">
                                        <div class="hover" @click="$router.push({ name: 'Proxies' })">
                                            <img src="@/assets/images/fn5.png" alt="">
                                            <img src="@/assets/images/fn5-1.png" alt="" style="display: none;"
                                                 class="animate__animated animate__flip">
                                            <span>Proxies</span>
                                        </div>
                                    </template>
                                    <el-menu-item-group>
                                        <el-menu-item class="setFont" :index="`1-${index + 1}`"
                                                      v-for="(item, index) in itemList"
                                                      :key="item.shopify_collection_id"
                                                      @click="$router.push({ path: `/dashboard/index/proxies/${item.id}` })">
                                            {{
                                            item.collection_name
                                            }}
                                        </el-menu-item>
                                    </el-menu-item-group>
                                </el-submenu>
                                <el-submenu index="2">
                                    <template slot="title">
                                        <div class="hover">
                                            <img src="@/assets/images/fn7.png" alt="">
                                            <img src="@/assets/images/fn7-1.png" alt="" style="display: none;"
                                                 class="animate__animated animate__flip">
                                            <span>Rewards</span>
                                        </div>
                                    </template>
                                    <el-menu-item-group>
                                        <el-menu-item class="setFont" index="5-1"
                                                      @click="$router.push({ name: 'promocodeUser' })">promo
                                            code
                                        </el-menu-item>
                                        <el-menu-item class="setFont" index="5-2"
                                                      @click="$router.push({ name: 'giftcardUser' })">gift
                                            card
                                        </el-menu-item>
                                    </el-menu-item-group>
                                </el-submenu>
                                <el-menu-item index="3" class="hover" @click="$router.push({ name: 'invite' })">
                                    <img src="@/assets/images/fn6.png" alt="">
                                    <img src="@/assets/images/fn6-1.png" alt="" style="display: none;"
                                         class="animate__animated animate__flip">
                                    <span slot="title">Invite</span>
                                </el-menu-item>
                                <el-menu-item index="4" class="hover" @click="$router.push({ name: 'userSettings' })">
                                    <img src="@/assets/images/fn8.png" alt="">
                                    <img src="@/assets/images/fn8-1.png" alt="" style="display: none;"
                                         class="animate__animated animate__flip">
                                    <span>Settings</span>
                                </el-menu-item>
                                <el-menu-item index="5" class="hover" @click="$router.push({ name: 'support' })">
                                    <img src="@/assets/images/fn9.png" alt="">
                                    <img src="@/assets/images/fn9-1.png" alt="" style="display: none;"
                                         class="animate__animated animate__flip">
                                    <span slot="title">Help</span>
                                </el-menu-item>
                            </el-menu>
                            <!-- 管理员 -->
                            <el-menu class="el-menu-vertical-demo" text-color="#404040" unique-opened v-else>
                                <!--  -->
                                <el-menu-item index="0" class="hover" @click="$router.push({ name: 'home' })">
                                    <img src="@/assets/images/fn4.png" alt="">
                                    <img src="@/assets/images/fn4-1.png" alt="" style="display:none;"
                                         class="animate__animated animate__flip">
                                    <span slot="title">Home</span>
                                </el-menu-item>
                                <el-submenu index="1">
                                    <template slot="title">
                                        <div class="hover">
                                            <img src="@/assets/images/fn8.png" alt="">
                                            <img src="@/assets/images/fn8-1.png" alt="" style="display: none;"
                                                 class="animate__animated animate__flip">
                                            <span>User Manage</span>
                                        </div>
                                    </template>
                                    <el-menu-item-group>
                                        <el-menu-item class="setFont" index="1-1"
                                                      @click="$router.push({ name: 'userList' })">User
                                            List
                                        </el-menu-item>
                                    </el-menu-item-group>
                                </el-submenu>
                                <el-menu-item index="2" class="hover" @click="$router.push({ name: 'Orders' })">
                                    <img src="@/assets/images/fn9.png" alt="">
                                    <img src="@/assets/images/fn9-1.png" alt="" style="display: none;"
                                         class="animate__animated animate__flip">
                                    <span slot="title">Orders</span>
                                </el-menu-item>
                                <el-submenu index="3">
                                    <template slot="title">
                                        <div class="hover">
                                            <img src="@/assets/images/fn8.png" alt="">
                                            <img src="@/assets/images/fn8-1.png" alt="" style="display: none;"
                                                 class="animate__animated animate__flip">
                                            <span slot="title">Products</span>
                                        </div>
                                    </template>
                                    <el-menu-item-group>
                                        <el-menu-item class="setFont" index="3-1"
                                                      @click="$router.push({ name: 'productManager' })">Product
                                            Manage
                                        </el-menu-item>
                                        <el-menu-item class="setFont" index="3-2"
                                                      @click="$router.push({ name: 'productType' })">Product
                                            Type
                                        </el-menu-item>
                                        <el-menu-item class="setFont" index="3-3"
                                                      @click="$router.push({ name: 'productTag' })">Product
                                            Tag
                                        </el-menu-item>
                                    </el-menu-item-group>
                                </el-submenu>
                                <!-- <el-menu-item index="4" class="hover">
                                  <img src="@/assets/images/fn9.png" alt="">
                                  <img src="@/assets/images/fn9-1.png" alt="" style="display: none;">
                                  <span slot="title">Promo Code</span>
                                </el-menu-item> -->
                                <el-submenu index="4">
                                    <template slot="title">
                                        <div class="hover">
                                            <img src="@/assets/images/fn7.png" alt="">
                                            <img src="@/assets/images/fn7-1.png" alt="" style="display: none;"
                                                 class="animate__animated animate__flip">
                                            <span>Rewards</span>
                                        </div>
                                    </template>
                                    <el-menu-item-group>
                                        <el-menu-item class="setFont" index="5-1"
                                                      @click="$router.push({ name: 'promocode' })">promo
                                            code
                                        </el-menu-item>
                                        <el-menu-item class="setFont" index="5-2"
                                                      @click="$router.push({ name: 'giftcard' })">gift
                                            card
                                        </el-menu-item>
                                        <el-menu-item class="setFont" index="5-3"
                                                      @click="$router.push({ name: 'leveldiscount' })">level
                                            discount
                                        </el-menu-item>
                                    </el-menu-item-group>
                                </el-submenu>
                                <el-submenu index="5">
                                    <template slot="title">
                                        <div class="hover">
                                            <img src="@/assets/images/fn6.png" alt="">
                                            <img src="@/assets/images/fn6-1.png" alt="" style="display: none;"
                                                 class="animate__animated animate__flip">
                                            <span>Servers</span>
                                        </div>
                                    </template>
                                    <el-menu-item-group>
                                        <el-menu-item class="setFont" index="5-2"
                                                      @click="$router.push({ name: 'servers' })">Server
                                            Manage
                                        </el-menu-item>
                                        <el-menu-item class="setFont" index="5-3"
                                                      @click="$router.push({ name: 'servergroup' })">Server Group
                                            Manage
                                        </el-menu-item>
                                        <el-menu-item class="setFont" index="5-4"
                                                      @click="$router.push({ name: 'AclManager' })">Acl
                                            Manage
                                        </el-menu-item>
                                        <el-menu-item class="setFont" index="5-5"
                                                      @click="$router.push({ name: 'AclGroupManager' })">Acl Group
                                            Manage
                                        </el-menu-item>
                                        <el-menu-item class="setFont" index="5-5"
                                                      @click="$router.push({ name: 'CIDRManage' })">CIDR manage
                                        </el-menu-item>
                                    </el-menu-item-group>
                                </el-submenu>
                                <el-menu-item index="5" class="hover" @click="$router.push({ name: 'tickets' })">
                                    <img src="@/assets/images/fn9.png" alt="">
                                    <img src="@/assets/images/fn9-1.png" alt="" style="display: none;"
                                         class="animate__animated animate__flip">
                                    <span slot="title">Tickets</span>
                                </el-menu-item>
                                <el-menu-item index="6" class="hover"
                                              @click="$router.push({ name: 'WebsiteSettings' })">
                                    <img src="@/assets/images/fn9.png" alt="">
                                    <img src="@/assets/images/fn9-1.png" alt="" style="display: none;"
                                         class="animate__animated animate__flip">
                                    <span slot="title">Web Settings</span>
                                </el-menu-item>
                                <el-menu-item index="7" class="hover" @click="$router.push({ name: 'QA' })">
                                    <img src="@/assets/images/fn9.png" alt="">
                                    <img src="@/assets/images/fn9-1.png" alt="" style="display: none;"
                                         class="animate__animated animate__flip">
                                    <span slot="title">Q&A</span>
                                </el-menu-item>
                            </el-menu>
                        </div>
                    </div>
                    <div class="fproxiert wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="1s">
                        <!-- <proxies-Com v-if="tabIndex == 0"></proxies-Com>
                        <rewards-Com v-else-if="tabIndex == 1"></rewards-Com>
                        <settings-Com v-else-if="tabIndex == 2"></settings-Com>
                        <server-Com v-else-if="tabIndex == 3"></server-Com> -->
                        <router-view/>
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
// @ is an alias to /src
import headerCom from '@/components/header'
import proxiesCom from './proxies'
import rewardsCom from './rewards'
import settingsCom from './settings'
import {getProxiesItem} from "@/api/dashboard/orders"

export default {
    name: 'proxies',
    components: {
        headerCom,
        proxiesCom,
        rewardsCom,
        settingsCom,
    },
    data() {
        return {
            tabIndex: 0,//0proxies1rewards2settings
            scrollTop: 0,
            itemList: []
        }
    },
    updated() {
    },
    mounted() {
        this.handleGetProxiesItem()
        new this.$wow.WOW().init()
        if (this.$route.query.index) {
            this.tabIndex = this.$route.query.index
        } else {
            this.tabIndex = 0
        }
        window.addEventListener('scroll', this.handleScroll)
    },
    methods: {
        setIndex(index) {
            this.tabIndex = index
        },
        toWebUrl(name) {
            let routeData = this.$router.resolve({
                name: name
            })
            window.open(routeData.href, '_blank')
        },
        handleScroll() {
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop // 滚动条偏移量
            this.scrollTop = scrollTop
        },

        handleGetProxiesItem() {
            getProxiesItem((res) => {
                this.itemList = res.data.data
            })
        }
    }
}
</script>
<style scoped>
.layout {
    background: url(../../assets/images/fbj1.jpg) no-repeat center/cover;
}

:deep(.el-menu) {
    background-color: transparent;
}

.el-menu-vertical-demo span {
    font-size: 17px;
    display: inline-block;
    margin-left: 10px;
}

:deep(.el-menu-item.is-active) {
    color: #000;
}

.hover:hover > img:nth-child(1) {
    display: none;
}

.hover:hover > img:nth-child(2) {
    display: inline-block !important;
}

.hover:hover span {
    color: #f34087;
}

.setFont {
    font-size: 13px;
}
</style>