import {axios} from '@/utils/request'

// 用户列表
export const getList = (msg, sucFn, errorFn) => {
    axios({
        method: 'get',
        url: '/api/tickets/ticket/',
        params: msg
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })

}
// export const deleteTick = (msg, sucFn, errorFn) => {
//   debugger
//   axios({
//     method: 'delete',
//     url: `/api/tickets/ticket/${meg.id}/`,
//     data: msg
//   })
//     .then(function (res) {
//       if (res.code === 2000) {
//         typeof sucFn === 'function' && sucFn(res)
//       } else {
//         typeof errorFn === 'function' && errorFn(res)
//       }
//     })
//     .catch(function (code, error) {
//       typeof errorFn === 'function' && errorFn(code, error)
//     })

// }

export const deleteTick = (msg, sucFn, errorFn) => {
    axios({
        method: 'DELETE',
        url: `/api/tickets/ticket/${msg.id}/`,
        data: msg
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })

}
