<template>
    <div>
        <el-card class="box-card" shadow="never">
            <el-card style="background-color: #febac3;margin-bottom: 20px;" shadow="never">
                <el-form :inline="true" :model="formInline" class="demo-form-inline">
                    <el-form-item>
                        <el-input v-model="formInline.search" @change="onSubmit"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button @click="onSubmit">search</el-button>
                    </el-form-item>
                    <!-- <el-form-item>
                      <el-button @click="handleAdd">Add</el-button>
                    </el-form-item> -->
                </el-form>
            </el-card>
            <el-table :data="tableData" style="width: 100%" header-cell-class-name="table_header"
                      v-loading="tableLoading">
                <el-table-column prop="id" label="id" align="center">
                </el-table-column>
                <el-table-column prop="level" label="level" align="center">
                </el-table-column>
                <el-table-column prop="discount" label="discount" align="center">
                </el-table-column>
                <el-table-column prop="code" label="code" align="center">
                </el-table-column>
                <el-table-column prop="point" label="point" align="center" width="100">
                </el-table-column>
                <el-table-column label="Action" align="center" width="130">
                    <template v-slot="{ row, $index }">
                        <!-- <el-button type="text" @click="handleDelete(row, $index)">Delete</el-button> -->
                        <el-button type="text" @click="handleEdit(row, $index)">edit</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination background layout="->,prev, pager, next" :total="total" style="margin-top: 20px;"
                           :current-page.sync="currentPage" @current-change="handleCurrentChange">
            </el-pagination>
        </el-card>

        <el-dialog :visible.sync="dialogFormVisible" center width="35%">
            <div style="width: 75%;margin: 0 auto;">
                <el-form :model="form" label-width="120px" :rules="rules" ref="ruleForm">
                    <el-form-item label="LEVEL">
                        <el-input v-model="form.level" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="discount" prop="discount">
                        <el-input v-model="form.discount"></el-input>
                    </el-form-item>
                    <el-form-item label="code" prop="code">
                        <el-input v-model="form.code"></el-input>
                    </el-form-item>
                    <el-form-item label="point" prop="point">
                        <el-input v-model="form.point"></el-input>
                    </el-form-item>

                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="handleSubmit" type="primary" :loading="EditLoading">Save</el-button>
                <el-button @click="dialogFormVisible = false" type="danger" :disabled="EditLoading">Cancel</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {getList, handle, add} from "@/api/dashboard/leveldiscount"

export default {
    data() {
        return {
            formInline: {
                search: '',
            },
            tableData: [],
            currentPage: 1,
            total: 0,
            tableLoading: false,
            dialogFormVisible: false,
            EditLoading: false,
            // viewInfo: {}
            form: {
                discount: '',
                point: "",
                code: "",
            },
            rules: {
                discount: [
                    {required: true, message: 'Please enter discount', trigger: 'blur'},
                ],
                code: [
                    {required: true, message: 'Please enter code', trigger: 'blur'},
                ],
                point: [
                    {required: true, message: 'Please enter point', trigger: 'blur'},
                ]
            },
            handleType: ""
        }
    },
    mounted() {
        new this.$wow.WOW().init()
        this.getTableData()
    },
    methods: {
        handlePassword() {
            if (this.password == "qazmkoiuytr") this.password = ""
        },

        onSubmit() {
            this.currentPage = 1
            this.getTableData()
        },
        handleCurrentChange(val) {
            this.getTableData()
        },
        getTableData() {
            const offset = (this.currentPage - 1) * 10
            const param = {search: this.formInline.search, limit: 10, offset}
            this.tableLoading = true
            getList(param, (res) => {

                this.tableData = res.data.data
                this.total = res.data.total
                this.tableLoading = false
            })
        },
        handleEdit(row) {
            this.handleType = "edit"
            this.dialogFormVisible = true
            this.$nextTick(() => {
                this.$refs["ruleForm"].resetFields()
                this.form = {...row}
            })
        },
        handleAdd() {
            this.handleType = "add"
            this.dialogFormVisible = true
            this.$nextTick(() => {
                this.$refs["ruleForm"].resetFields()
            })
        },
        handleSubmit() {
            this.$refs["ruleForm"].validate((valid) => {
                if (valid) {
                    this.EditLoading = true
                    if (this.handleType == "edit") {
                        handle("put", this.form, (res) => {
                            this.dialogFormVisible = false
                            this.EditLoading = false
                            this.$message.success("successfully")
                            this.getTableData()
                        }, (res) => {
                            this.$message.warning(res.msg)
                            this.EditLoading = false
                        })
                    } else {
                        //
                        add(this.form, (res) => {
                            this.dialogFormVisible = false
                            this.EditLoading = false
                            this.$message.success("successfully")
                            this.getTableData()
                        }, (res) => {
                            this.$message.warning(res.msg)
                            this.EditLoading = false
                        })
                    }
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        handleDelete(row, index) {
            this.$confirm(`Confirm deletion`, 'Delete ?', {
                confirmButtonText: 'YES',
                cancelButtonText: 'NO',
                type: 'warning'
            }).then(() => {
                handle(
                    "delete",
                    {id: row.id},
                    (res) => {
                        this.currentPage = this.currentPage === 1 ? this.currentPage : (this.tableData.length == 1 && index === 0) ? this.currentPage - 1 : this.currentPage
                        this.$message.success("successfully")
                        this.getTableData()
                    },
                )
            }).catch(() => {

            })
        }


    }
}
</script>

<style scoped lang="scss">
.box-card {
  :deep(.el-card) {
    border-radius: 18px;
  }

  :deep(.el-form) {
    font-family: "Pang";
  }

  :deep(thead) {
    font-family: "Pang";
  }

  :deep(.el-form-item__label) {
    font-size: 20px;
    color: #fff;
  }

  :deep(.el-input__inner) {
    background-color: transparent;
    border: 3px solid #fff;
    color: #fff;
  }

  :deep(.el-form-item) {
    margin-bottom: 0;
  }


  .demo-form-inline {
    :deep(.el-button) {
      font-family: "Pang";
      background-color: #d6227a;
      color: #fff;
      border-radius: 10px;
    }
  }

  :deep(.table_header) {
    background-color: #f1408c !important;
    color: #fff;
  }

  :deep(.table_header:first-child) {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  :deep(.table_header:nth-child(6)) {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  :deep(.el-pagination.is-background .el-pager li:not(.disabled).active) {
    background-color: #f1408c;
  }


}
</style>